// New
import { lazy } from 'react';
import { ContentfulNewsSearch } from './contentful/blog';
import { ContentfulCustomSection } from './contentful/custom-section';
import { ContentfulForms } from './forms/contentfulForms/contentfulForms';
// import ContentfulInfoBanner from './info-banner';
// testing if lazy loading works better.
// export const ContentfulNewsSearch = lazy(() => import('./contentful/blog/news-search'));
export const ContentfulInfoBanner = lazy(() => import('./info-banner'));
// Old
export { default as ContentfulAccordion } from './accordion';
export { default as ContentfulBaseContentGrid } from './base-content-grid';
export { default as ContentfulButton } from './button';
export { default as ContentfulCard } from './cards/card';
export { CardGroup as ContentfulCardGroup } from './cards/card-group';
export { IconCard as ContentfulIconCard } from './cards/icon';
export { ImageCard as ContentfulImageCard } from './cards/image';
export { PromotionCard as ContentfulPromotionCard } from './cards/promotion';
export { TextCard as ContentfulTextCard } from './cards/text';
export { default as ContentfulChipList } from './chip-list';
export { default as ContentfulContentSection } from './content-section';
export { default as ContentfulDuplex } from './duplex';
export { default as ContentfulFactBanner } from './fact-banner';
export { default as ContentfulGrid } from './grid';
export { default as ContentfulGridMedia } from './grid-media';
export { default as ContentfulHero } from './hero';
export { default as ContentfulImageBanner } from './image-banner';
export { default as ContentfulList } from './list';
export { default as ContentfulSection } from './section';
export { default as ContentfulTextBlock } from './text-block';
export { default as ContentfulTabs } from './tabs';

// TODO: change to named exports for all components vs. default export
export const ContentfulComponents: Record<string, React.ElementType> = {
	ContentfulInfoBanner,
	ContentfulNewsSearch,
	ContentfulForms,
	ContentfulCustomSection,
};

export default ContentfulComponents;
