import {
	CardContentAlignment,
	DrivenProgressBar,
	DrivenTextCard,
} from '@drivenbrands/driven-components';

type ProgressProps = {
	activeStep: number;
};

// TODO: single point of overall steps. avoid matching everywhere. 

// About you location
// vehicle info
// service needs
// show quote. Schedule is below

const steps: string[] = [
	'About You',
	'Your Vehicle',
	'Service Needed',
	'Your Quote',
];

export const ProgressBar = ({ activeStep }: ProgressProps) => {
	return (
		<DrivenTextCard
			contentAlignment={CardContentAlignment.LEFT}
			cardContentProps={{ component: 'div' }}
			maxWidth='100%'
			minWidth='100%'
			sx={{
				padding: 0,

				'& .MuiBox-root': {
					padding: 0,
				},

				'& .MuiCardContent-root': {
					pt: 0,
					width: '100%',

					'& .MuiBox-root': {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					},
				},
			}}
		>
			<DrivenProgressBar activeStep={activeStep} steps={steps} />
		</DrivenTextCard>
	);
};
