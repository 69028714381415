import {
	format,
	getHours,
	getMinutes,
	getTime,
	getYear,
	isAfter,
	startOfTomorrow
} from 'date-fns';
import { DayOfWeek } from '../services';

type Hours = {
	[key in DayOfWeek]: {
		isOpen: boolean;
		open: string;
		close: string;
	};
};

type StoreStatus = {
	storeHours: string;
	isOpen: boolean;
};

export const getStoreStatus = (hours: Hours): StoreStatus => {
	const storeOpenUntilText = 'Open until';
	const storeOpenTomorrowText = 'Opens tomorrow at';
	const storeClosedText = 'Closed';

	let calculatedStoreHours = '';
	let calculatedIsOpen = true;

	const today = format(new Date(), 'EEEE') as DayOfWeek;
	const currentLocationHours = hours[today];

	if (!currentLocationHours.isOpen) {
		calculatedIsOpen = false;
		calculatedStoreHours = `${storeClosedText} ${today}`;
	} else {
		const closingTime = currentLocationHours.close
			.match(/(\d+):(\d+)\w+/g)
			?.pop();

		if (closingTime) {
			const now = new Date();
			const isAMOrPM = currentLocationHours.close
				.match(/[^\d:]/g)
				?.join('')
				.trim()
				.toLowerCase();
			const [hours2, minutes2] = closingTime.split(':');
			const time1 = getTime(
				new Date(getYear(now), 0, 1, getHours(now), +getMinutes(now))
			);
			const time2 = getTime(
				new Date(
					getYear(now),
					0,
					1,
					isAMOrPM === 'pm' ? +hours2 + 12 : +hours2,
					+minutes2
				)
			);
			const storeOpen = isAfter(time2, time1);
			calculatedIsOpen = storeOpen;
			calculatedStoreHours = `${storeOpenUntilText} ${currentLocationHours.close}`;

			if (!storeOpen) {
				const tomorrowDay = format(startOfTomorrow(), 'EEEE') as DayOfWeek;
				const tomorrow = hours[tomorrowDay];
				calculatedStoreHours = tomorrow.isOpen
					? `${storeOpenTomorrowText} ${tomorrow.open}`
					: `${storeClosedText} ${tomorrowDay}`;
			}
		}
	}

	return { storeHours: calculatedStoreHours, isOpen: calculatedIsOpen };
}
