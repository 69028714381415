import {
	ButtonIconPlacement,
	DrivenButton,
	DrivenConfirmationModal,
	DrivenStack,
	DrivenTypography,
	IconTypes,
} from '@drivenbrands/driven-components';

import { VehicleResponse } from '../../../../services';

type ConfirmationModalProps = {
	getGlassOptions?: boolean;
	loading: boolean;
	open: boolean;
	handleClose: () => void;
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
	vehicleDetails: Partial<VehicleResponse['Vehicle']>;
};

type VehicleListItemProps = {
	title: string;
	value?: string | number;
};

export const VehicleConfirmationModal = ({
	getGlassOptions,
	handleClose,
	loading = false,
	onSubmit,
	open = false,
	vehicleDetails,
}: ConfirmationModalProps) => {
	const { VehYear, VehMake, VehModel, Style, VIN, LicensePlate } =
		vehicleDetails;
	const method = LicensePlate?.length ? 'license plate' : 'VIN';

	const VehicleListItem = ({ title, value }: VehicleListItemProps) => (
		<DrivenTypography display='flex' flexDirection='row' variant='body2Bold'>
			{title}:
			<DrivenTypography variant='body2' sx={{ ml: 1 }}>
				{value}
			</DrivenTypography>
		</DrivenTypography>
	);

	return (
		<DrivenConfirmationModal
			actions={
				<>
					<DrivenButton disabled={loading} onClick={handleClose} variant='text'>
						No, this is incorrect
					</DrivenButton>
					<DrivenButton
						disabled={loading}
						icon={loading ? IconTypes.UPLOAD : undefined}
						iconPlacement={ButtonIconPlacement.LEFT}
						type='submit'
						isLoading={loading}
					>
						Yes, this is correct
					</DrivenButton>
				</>
			}
			handleClose={() => (!loading ? handleClose : null)}
			onSubmit={onSubmit}
			open={open}
			title='Confirm Vehicle'
		>
			{!loading && (
				<DrivenStack spacing={2}>
					<DrivenTypography variant='body2'>
						The {method} provided returned the following vehicle details:
					</DrivenTypography>
					<DrivenStack>
						{VIN && <VehicleListItem title='VIN' value={VIN} />}
						{LicensePlate && (
							<VehicleListItem title='LICENSE PLATE' value={LicensePlate} />
						)}
						<VehicleListItem title='YEAR' value={VehYear} />
						<VehicleListItem title='MAKE' value={VehMake} />
						<VehicleListItem title='MODEL' value={VehModel} />
						<VehicleListItem title='STYLE' value={Style} />
					</DrivenStack>
					<DrivenTypography variant='body2'>
						Does this match your vehicle description?
					</DrivenTypography>
				</DrivenStack>
			)}
			{loading && !getGlassOptions && (
				<DrivenTypography variant='body2'>
					Please wait while we submit your vehicle information.
				</DrivenTypography>
			)}
			{loading && getGlassOptions && (
				<DrivenTypography variant='body2'>
					Retrieving your vehicle's glass options. This may take 10 or more
					seconds.
				</DrivenTypography>
			)}
		</DrivenConfirmationModal>
	);
};
