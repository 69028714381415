import {
	DrivenAccordion,
	DrivenBox,
	DrivenBulletList,
	DrivenCheckbox,
	DrivenContentCard,
	DrivenGrid,
	DrivenIcon,
	DrivenRadioButton,
	DrivenRadioGroup,
	DrivenStack,
	DrivenTypography,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import React, {
	SyntheticEvent,
	useCallback,
	useContext,
	useState,
} from 'react';

import { DamageType, GlassType, OpeningType } from '../../../services';
import {
	BasicSelectionCard,
	BodyText,
	ButtonContext,
	Caption,
	CardContainer,
	FormStateContext,
	IconCardWithAccordion,
	ProgressButtons,
	SelectionButton,
	SelectionCard,
	createBulletedListItems,
	fullWidthSx,
	repairList,
	replaceList,
} from '../utils';

enum RepairReplace {
	REPAIR = 'repair',
	REPLACE = 'replace',
}

/// /////////////////////////////////
// REUSED COMPONENTS
/// /////////////////////////////////
const Heading = (icon: IconTypes, text: string) => {
	return (
		<DrivenStack
			alignItems='center'
			direction='row'
			spacing={2}
			sx={{ color: 'primary.main' }}
		>
			<DrivenIcon icon={icon} size={IconSizes.XL} />
			<DrivenTypography color='black' variant='button'>
				{text}
			</DrivenTypography>
		</DrivenStack>
	);
};

export const DamageAssessmentForm = () => {
	const { handlePrevious } = useContext(ButtonContext);
	const { damageTypes, setDamageTypes, glassOptions, loading } =
		useContext(FormStateContext);

	// ACCORDIONS
	const [repairExpanded, setRepairExpanded] = useState<string[]>([]); // Repair accordion
	const [replaceExpanded, setReplaceExpanded] = useState<string[]>([]); // Replace accordion

	// Car window section
	const [carAccordionExpanded, setCarAccordionExpanded] = useState<string[]>(
		[]
	); // Car window helper accordion

	// CAR WINDOW AND REAR WINDSHIELD CONTENT CARD EXPANSION
	const [carWindowOpen, setCarWindowOpen] = useState<boolean>(false);
	const [rearWindshieldOpen, setRearWindshieldOpen] = useState<boolean>(false);
	const [windshieldSelected, setWindshieldSelected] = useState<RepairReplace>();

	if (!glassOptions || !glassOptions.length) {
		return <></>;
	}

	const windShieldData = glassOptions?.find(
		(option) => option?.OpeningType === OpeningType.WINDSHIELD
	);
	const rearWindShieldData = glassOptions?.find(
		(option) => option?.OpeningType === OpeningType.BACK_WINDOW
	);
	const carWindowData = glassOptions?.find(
		(option) => option?.OpeningType === OpeningType.DOOR
	);

	/// /////////////////////////////////
	// ACCORDION TOGGLE FUNCTIONALITY
	/// /////////////////////////////////
	const handleCarWindowAccordionToggle =
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (carAccordionExpanded.includes(panelTriggered)) {
					setCarAccordionExpanded(
						carAccordionExpanded.filter((panel) => panel !== panelTriggered)
					);
				} else {
					setCarAccordionExpanded([...carAccordionExpanded, panelTriggered]);
				}
			};

	const handleRepairAccordionToggle =
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (repairExpanded.includes(panelTriggered)) {
					setRepairExpanded(
						repairExpanded.filter((panel) => panel !== panelTriggered)
					);
				} else {
					setRepairExpanded([...repairExpanded, panelTriggered]);
				}
			};

	const handleReplaceAccordionToggle =
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (replaceExpanded.includes(panelTriggered)) {
					setReplaceExpanded(
						replaceExpanded.filter((panel) => panel !== panelTriggered)
					);
				} else {
					setReplaceExpanded([...replaceExpanded, panelTriggered]);
				}
			};

	/// /////////////////////////////////
	// SELECT BUTTON FUNCTIONALITY
	/// /////////////////////////////////
	const isWindowSelected = useCallback(
		(openingType: OpeningType, position: string) => {
			return Boolean(
				damageTypes?.some(
					(window) =>
						window.OpeningType === openingType && window.Position === position
				)
			);
		},
		[damageTypes]
	);

	const addSelection = (payload: DamageType) => {
		// console.log('ADD WINDOW >> ', payload)
		setDamageTypes &&
			setDamageTypes(damageTypes ? [...damageTypes, payload] : [payload]);
	};

	const filterSelection = (openingType: OpeningType, position: string) => {
		setDamageTypes &&
			setDamageTypes(
				damageTypes
					? damageTypes?.filter(
						(option) =>
							!option.Size?.match(`${openingType}-${position}`)?.length
					)
					: []
			);
	};

	// HANDLES WINDSHIELD PANEL SELECTIONS
	const handleAdditionalWindshieldOptions = (
		damageType: RepairReplace,
		selection: string
	) => {
		const windshieldPayload: DamageType = {
			OpeningType: OpeningType.WINDSHIELD,
			DamageType: damageType,
			Position: selection,
			Size: `${OpeningType.WINDSHIELD}-${selection}`, // Only for filtering purposes - will be nullified in the form-wrapper
			Edges: 'no',
			Growing: 'no',
		};

		if (isWindowSelected(OpeningType.WINDSHIELD, selection) && damageTypes) {
			filterSelection(OpeningType.WINDSHIELD, selection);
		} else {
			addSelection(windshieldPayload);
		}
	};

	// HANDLES WINDSHIELD SELECTIONS THAT DO NOT HAVE PANELS TO CHOOSE FROM
	const handleWindshieldSelection = (damageType: RepairReplace) => {
		const windshield: DamageType = {
			OpeningType: OpeningType.WINDSHIELD,
			DamageType: damageType,
			Position:
				windShieldData?.Position?.length === 1
					? windShieldData?.Position?.pop()
					: null,
			Size: null,
			Edges: 'no',
			Growing: 'no',
		};

		const windshieldSelection = Number(
			damageTypes?.findIndex(
				(windowOption) => windowOption?.OpeningType === OpeningType.WINDSHIELD
			)
		);
		// WINDSHIELD HAS PANELS TO CHOOSE FROM - handleAdditionalWindshieldOptions HANDLES SELECTIONS
		if (windShieldData?.Position && windShieldData?.Position?.length > 1) {
			// PANEL(S) SELECTED - CANNOT CHOOSE ANOTHER DAMAGE TYPE
			if (windshieldSelection > -1) {

				// PANEL(S) NOT SELECTED - CAN CHOOSE ANOTHER DAMAGE TYPE
			} else {
				setWindshieldSelected(
					windshieldSelected === damageType ? undefined : damageType
				);
			}
			// WINDSHIELD DOES NOT HAVE PANELS TO CHOOSE FROM
		} else if (windshieldSelection > -1 && damageTypes) {
			const filteredGlassOptions = damageTypes?.filter(
				(damage) =>
					damage?.OpeningType !== OpeningType.WINDSHIELD &&
					damage?.DamageType !== damageType
			);
			const filterGlassSelection =
				// IF WINDSHIELD WITH SAME DAMAGE TYPE IS ALREADY SELECTED, REMOVE IT
				damageTypes[windshieldSelection].DamageType === damageType
					? filteredGlassOptions
					: // IF WINDSHIELD WITH DAMAGE TYPE IS NOT ALREADY SELECTED ADD NEW WINDSHIELD SELECTION, REMOVE OLD SELECTION
					[...filteredGlassOptions, windshield];
			setDamageTypes && setDamageTypes(filterGlassSelection);
		} else {
			addSelection(windshield);
		}
	};

	// HANDLES REAR WINDSHIELD SELECTION - INCLUDING PANEL OPTIONS
	const handleRearWindshieldSelection = (selection: string | null) => {
		// console.log("REAR >> ", selection)
		const rearWindshieldData = {
			OpeningType: OpeningType.BACK_WINDOW,
			DamageType: 'replace',
			Position:
				rearWindShieldData?.Position.length === 1
					? rearWindShieldData?.Position.pop()
					: selection,
			Size: selection ? `${OpeningType.BACK_WINDOW}-${selection}` : null, // Only for filtering purposes - will be nullified in the form-wrapper
			Edges: 'no',
			Growing: 'no',
		};

		// NO PANEL OPTIONS, DESELECT REAR WINDSHIELD IF ALREADY SELECTED
		if (
			!selection &&
			damageTypes &&
			Boolean(
				damageTypes?.some(
					(window) => window?.OpeningType === OpeningType.BACK_WINDOW
				)
			)
		) {
			setDamageTypes &&
				setDamageTypes(
					damageTypes.filter(
						(damage) => damage.OpeningType !== OpeningType.BACK_WINDOW
					)
				);
			// IF PANEL OPTION IS ALREADY SELECTED, REMOVE IT
		} else if (
			damageTypes &&
			selection &&
			isWindowSelected(OpeningType.BACK_WINDOW, selection)
		) {
			filterSelection(OpeningType.BACK_WINDOW, selection);
			// ADD REAR WINDSHIELD SELECTION
		} else {
			addSelection(rearWindshieldData);
		}
	};

	const handleCarWindowSelection = (selection: string) => {
		// console.log("OTHER WINDOWS >> ", selection)
		const carWindowData = {
			OpeningType: OpeningType.DOOR,
			DamageType: 'replace',
			Position: selection,
			Size: `${OpeningType.DOOR}-${selection}`, // Only for filtering purposes - will be nullified in the form-wrapper
			Edges: 'no',
			Growing: 'no',
		};
		if (isWindowSelected(OpeningType.DOOR, selection)) {
			filterSelection(OpeningType.DOOR, selection);
		} else {
			addSelection(carWindowData);
		}
	};

	/// /////////////////////////////////
	// RENDERING GLASS OPTIONS
	/// /////////////////////////////////
	// eslint-disable-next-line react/no-unstable-nested-components
	const WindshieldOptions = (windshield?: GlassType) => {
		if (!windshield) {
			return;
		}
		const isSelected = (damageType: RepairReplace) =>
			Boolean(
				damageTypes?.some(
					(window) =>
						window?.OpeningType === OpeningType.WINDSHIELD &&
						window?.DamageType === damageType
				)
			);
		const hasWindShieldOptions = windshield?.Position?.length > 1;
		const replaceSelected = hasWindShieldOptions
			? windshieldSelected === RepairReplace.REPLACE
			: isSelected(RepairReplace.REPLACE);
		const repairSelected = hasWindShieldOptions
			? windshieldSelected === RepairReplace.REPAIR
			: isSelected(RepairReplace.REPAIR);

		const windShieldOptions = (repairReplace: RepairReplace) =>
			hasWindShieldOptions ? (
				<DrivenStack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
					{windShieldData?.Position.map((position) => (
						<BasicSelectionCard
							key={position}
							isSelected={isWindowSelected(OpeningType.WINDSHIELD, position)}
							onSelect={(position) =>
								handleAdditionalWindshieldOptions(repairReplace, position)
							}
							title={`${position.replace('_', ' ').toUpperCase()}`}
						/>
					))}
				</DrivenStack>
			) : undefined;

		// eslint-disable-next-line consistent-return
		return (
			<DrivenGrid
				container
				columnSpacing={{ xs: 0, md: 4 }}
				rowSpacing={{ xs: 4, md: 0 }}
			>
				<DrivenGrid item xs={12} md={6}>
					<IconCardWithAccordion
						accordionContent={[
							{
								summary: 'When should I repair?',
								details: (
									<DrivenBulletList
										items={createBulletedListItems(repairList)}
									/>
								),
							},
						]}
						accordionExpanded={repairExpanded}
						actions={repairSelected && windShieldOptions(RepairReplace.REPAIR)}
						icon={IconTypes.ZIP1}
						isSelected={repairSelected}
						onAccordionToggle={handleRepairAccordionToggle}
						onClick={() => handleWindshieldSelection(RepairReplace.REPAIR)}
						title='Windshield Repair'
					/>
				</DrivenGrid>
				<DrivenGrid item xs={12} md={6}>
					<IconCardWithAccordion
						accordionContent={[
							{
								summary: 'When should I replace?',
								details: (
									<DrivenBulletList
										items={createBulletedListItems(replaceList)}
									/>
								),
							},
						]}
						actions={
							replaceSelected && windShieldOptions(RepairReplace.REPLACE)
						}
						accordionExpanded={replaceExpanded}
						icon={IconTypes.WINDSHIELD_REPLACEMENT}
						isSelected={replaceSelected}
						onAccordionToggle={handleReplaceAccordionToggle}
						onClick={() => handleWindshieldSelection(RepairReplace.REPLACE)}
						title='Windshield Replace'
					/>
				</DrivenGrid>
			</DrivenGrid>
		);
	};

	// eslint-disable-next-line react/no-unstable-nested-components
	const RearGlass = (rearGlass?: GlassType) => {
		if (!rearGlass) {
			return;
		}
		const rearSelected = Boolean(
			damageTypes?.some(
				(window) => window?.OpeningType === OpeningType.BACK_WINDOW
			)
		);
		// eslint-disable-next-line consistent-return
		return rearGlass?.Position?.length > 1 ? (
			<DrivenContentCard
				content={
					<DrivenGrid
						container
						spacing={4}
						justifyContent='center'
						sx={{ mt: 4 }}
					>
						{rearGlass.Position.map((position, index) => {
							return (
								<DrivenGrid
									key={`${position}-${index}`}
									item
									xs={12}
									md={Math.floor(12 / rearGlass.Position.length)}
								>
									<BasicSelectionCard
										isSelected={isWindowSelected(
											OpeningType.BACK_WINDOW,
											position
										)}
										onSelect={handleRearWindshieldSelection}
										title={`${position.replace('_', ' ').toUpperCase()}`}
									/>
								</DrivenGrid>
							);
						})}
					</DrivenGrid>
				}
				expanded={rearWindshieldOpen || rearSelected}
				header={Heading(IconTypes.REAR_WINDSHIELD, 'Rear Windshield')}
				headerSecondary={
					<SelectionButton
						isSelected={rearWindshieldOpen || rearSelected}
						onClick={() =>
							setRearWindshieldOpen(rearSelected ? true : !rearWindshieldOpen)
						}
					/>
				}
			/>
		) : (
			<CardContainer dense>
				<DrivenStack
					display='flex'
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent='space-between'
					spacing={2}
				>
					{Heading(IconTypes.REAR_WINDSHIELD, 'Rear Windshield')}
					<SelectionButton
						isSelected={rearSelected}
						onClick={() => handleRearWindshieldSelection(null)}
					/>
				</DrivenStack>
			</CardContainer>
		);
	};

	// eslint-disable-next-line react/no-unstable-nested-components
	const CarGlass = (carGlass?: GlassType) => {
		if (!carGlass || !carGlass?.Position.length) {
			return;
		}
		const carWindowOptionSelected = Boolean(
			damageTypes?.some((window) => window?.OpeningType === OpeningType.DOOR)
		);
		// eslint-disable-next-line consistent-return
		return (
			<DrivenContentCard
				content={
					<DrivenStack spacing={4} sx={{ mt: 4 }}>
						<DrivenGrid container spacing={4}>
							{carGlass.Position.map((position, index) => {
								return (
									<DrivenGrid
										key={`${position}-${index}`}
										item
										xs={12}
										md={Math.floor(12 / carGlass.Position.length)}
									>
										<SelectionCard
											icon={
												position.toLowerCase().match('vent') ||
													position.toLowerCase().match('quarter')
													? IconTypes.VENT_AND_QUARTER_GLASS
													: IconTypes.FRONT_DOOR_REAR_DOOR_GLASS
											}
											isSelected={isWindowSelected(OpeningType.DOOR, position)}
											onClick={() => handleCarWindowSelection(position)}
											title={`${position.replace('_', ' ').toUpperCase()}`}
										/>
									</DrivenGrid>
								);
							})}
						</DrivenGrid>
						<DrivenAccordion
							handleChange={handleCarWindowAccordionToggle}
							panelsOpen={carAccordionExpanded}
							plain
							records={[
								{
									summary: 'Which damage area applies to me?',
									details: (
										<DrivenBox display='flex'>
											<img
												src='/assets/images/car-window-helper.png'
												alt='Car Window Helper'
												width='100%'
											/>
										</DrivenBox>
									),
								},
							]}
							sx={fullWidthSx}
						/>
					</DrivenStack>
				}
				expanded={carWindowOpen || carWindowOptionSelected}
				header={Heading(IconTypes.FRONT_DOOR_REAR_DOOR_GLASS, 'Car Window')}
				headerSecondary={
					<SelectionButton
						isSelected={carWindowOpen || carWindowOptionSelected}
						onClick={() =>
							setCarWindowOpen(carWindowOptionSelected ? true : !carWindowOpen)
						}
					/>
				}
			/>
		);
	};

	/// /////////////////////////////////
	// OET V2.0
	/// /////////////////////////////////

	const [frontGlass, setFrontGlass] = useState({});
	const [frontGlassDamageType, setFrontGlassDamageType] = useState({});

	const [rearGlass, setRearGlass] = useState(false);
	// const [rearGlassDamageType, setRearGlassDamageType] = useState({})

	const handleFrontWindshield = (e) => {
		const { value } = e.target;
		const previousDamageType = frontGlassDamageType.type as RepairReplace;

		if (value === 'Chip') {
			setFrontGlass({ type: 'chipped' });
		}

		if (value === 'Crack') {
			setFrontGlass({ type: 'cracked' });
		}
		// FIXME: Error on next steps
		// if switching from one to other need to clear any damage types out.
		handleWindshieldSelection(previousDamageType);
		setFrontGlassDamageType(0);
	};

	const handleFrontWindshieldChip = (e) => {
		const { value } = e.target;
		if (value === 'NO') {
			setFrontGlassDamageType({ type: RepairReplace.REPAIR });
			handleWindshieldSelection(RepairReplace.REPAIR);
		} else if (value === 'YES') {
			setFrontGlassDamageType({ type: RepairReplace.REPLACE });
			handleWindshieldSelection(RepairReplace.REPLACE);
		}
	};

	const handleFrontWindshieldCrack = (e) => {
		const { value } = e.target;
		if (value === 'NO') {
			setFrontGlassDamageType({ type: RepairReplace.REPAIR });
			handleWindshieldSelection(RepairReplace.REPAIR);
		} else if (value === 'YES') {
			setFrontGlassDamageType({ type: RepairReplace.REPLACE });
			handleWindshieldSelection(RepairReplace.REPLACE);
		}
	};

	const frontGlassOptions = (windshield?: GlassType) => {
		if (!windshield) {
			return;
		}

		const accordionItem = {
			summary: `Front Windshield`,
			details: (
				<DrivenStack spacing={2} sx={{ marginTop: 1 }} direction='column'>
					{crackOrChipOutside(handleFrontWindshield)}
					{frontGlass.type === 'chipped' && (
						<>
							<BodyText text="Is the chip larger than a golf ball?" />
							{questionYesNo(
								handleFrontWindshieldChip,
								'Is the chip larger than a golf ball?'
							)}
						</>
					)}
					{frontGlass.type === 'cracked' && (
						<>
							<BodyText text="Is the crack longer than a dollar bill?" />
							{questionYesNo(
								handleFrontWindshieldCrack,
								'Is the crack longer than a dollar bill?'
							)}
						</>
					)}
				</DrivenStack>
			),
		};

		return accordionItem;
	};

	const handleRearWindowCheckboxChange = (e) => {
		const value = e.target.checked;
		// console.log('CHECKED REAR >>> ', value)
		// setRearGlass(!rearGlass)

		setRearGlass(value);
		handleRearWindshieldSelection(null);
	};

	const rearGlassOptions = (windshield?: GlassType) => {
		if (!windshield) {
			return;
		}

		const accordionItem = {
			summary: `Rear Windshield`,
			details: (
				<DrivenStack spacing={2} sx={{ marginTop: 1 }} direction='column'>
					<DrivenCheckbox
						// color='secondary'
						label='Replace Rear Windshield?'
						checked={rearGlass}
						onChange={handleRearWindowCheckboxChange}
					/>
				</DrivenStack>
			),
		};

		return accordionItem;
	};

	const otherGlassOptions = (windshield?: GlassType) => {
		if (!windshield) {
			return;
		}

		// console.log('OTHER GLASS >> ', windshield)

		const accordionItem = {
			summary: `Other Windows`,
			details: (
				<DrivenStack spacing={2} sx={{ marginTop: 1 }} direction='column'>
					{windshield.Position.map((position, index) => {
						return (
							<DrivenGrid
								key={`${position}-${index}`}
								item
								xs={12}
								md={Math.floor(12 / windshield.Position.length)}
							>
								{/* // TODO: Change Right/left to driver/passenger  */}
								<DrivenCheckbox
									// color='secondary'
									label={`${position.replace('_', ' ').toUpperCase()}`}
									// checked={rearGlass}
									checked={isWindowSelected(OpeningType.DOOR, position)}
									// value="X"
									// onChange={handleOtherWindowCheckboxChange}
									onClick={() => handleCarWindowSelection(position)}
								/>
							</DrivenGrid>
						);
					})}
				</DrivenStack>
			),
		};

		return accordionItem;
	};

	const glassOptionsAccordion = [
		frontGlassOptions(windShieldData),
		rearGlassOptions(rearWindShieldData),
		otherGlassOptions(carWindowData),
	];

	// handles which windows are open.
	const [glassWindowExpanded, setGlassWindowExpanded] = useState<string[]>([]);

	const handleChange =
		(panelTriggered: string) =>
			(event: SyntheticEvent, isExpanded: boolean) => {
				if (glassWindowExpanded.includes(panelTriggered)) {
					setGlassWindowExpanded(
						glassWindowExpanded.filter((panel) => panel !== panelTriggered)
					);
				} else {
					setGlassWindowExpanded([...glassWindowExpanded, panelTriggered]);
				}
			};

	return (
		<CardContainer>
			<DrivenStack spacing={4}>
				<DrivenTypography component='h1' variant='h5'>
					Your Auto Glass Damages
				</DrivenTypography>
				<BodyText text='Please provide one or more of the following:' />

				<DrivenAccordion
					handleChange={handleChange}
					records={glassOptionsAccordion}
					panelsOpen={glassWindowExpanded}
				/>
				{/* <DrivenStack alignItems='center' spacing={4}> */}
				{/* {WindshieldOptions(windShieldData)} */}
				{/* {RearGlass(rearWindShieldData)} */}
				{/* {CarGlass(carWindowData)} */}
				{/* </DrivenStack> */}

				<Caption text='*Applies to windshield part only. Labor and fees not included. Retail transactions only.' />
				{/* {damageTypes &&
					damageTypes.some(
						(item) =>
							item.OpeningType === OpeningType.WINDSHIELD ||
							item.OpeningType === OpeningType.BACK_WINDOW
					) && (
						<FormInfoBanner
							header='Warning!'
							link='/calibration'
							linkText='Learn more about our calibration service'
							openNewTab
							supportText='One or more options you selected may require a calibration during your visit at an extra cost.'
						/>
					)} */}
				<ProgressButtons
					disableSubmit={!damageTypes?.length}
					handlePrevious={handlePrevious}
					loading={loading}
				/>
			</DrivenStack>
		</CardContainer>
	);
};

const crackOrChipOutside = (changeHandler) => {
	return (
		<DrivenStack spacing={2} sx={{ marginTop: 1 }} direction='row'>
			<DrivenRadioGroup
				row
				label=''
				aria-label='Front Window Damage'
				onChange={changeHandler}
			>
				<DrivenRadioButton label='Chip' value='Chip' />
				<DrivenRadioButton label='Crack' value='Crack' />
			</DrivenRadioGroup>
		</DrivenStack>
	);
};

const questionYesNo = (changeHandler, label: string) => {
	return (
		<DrivenStack spacing={2} sx={{ marginTop: 1 }} direction='row'>
			<DrivenRadioGroup
				row
				label=''
				aria-label={label}
				onChange={changeHandler}
			>
				<DrivenRadioButton label='Yes' value='YES' />
				<DrivenRadioButton label='No' value='NO' />
			</DrivenRadioGroup>
		</DrivenStack>
	);
};
