import {
	DrivenAccordion,
	DrivenBox,
	DrivenContainer,
	DrivenTypography,
	LinkProps,
} from '@drivenbrands/driven-components';
import { SyntheticEvent, useState } from 'react';

import { graphql } from 'gatsby';
import MarkdownContent from './mdx/markdown-content';

type ContentfulAccordionItem = {
	id: string;
	title: string;
	body: {
		childMarkdownRemark: {
			rawMarkdownBody: string;
		};
	};
};

interface AccordionProps {
	__typename: string;
	links?: [LinkProps];
	panelsOpen: [];
	records: ContentfulAccordionItem[];
	id: number;
	title: string;
	event: SyntheticEvent;
	isExpanded: boolean;
}

export default function Accordion({
	records,
	panelsOpen,
	title,
}: AccordionProps) {
	const [expanded, setExpanded] = useState<string[]>(panelsOpen ?? []);

	const accordionItems = records.map((record) => {
		const result = {
			id: record.id ?? '',
			summary: record.title ?? '',
			details: (
				<DrivenTypography pb={2} variant='body1' component='div'>
					<MarkdownContent>
						{record.body?.childMarkdownRemark.rawMarkdownBody}
					</MarkdownContent>
				</DrivenTypography>
			),
		};

		return result;
	});

	const handleChange =
		(panelTriggered: string) =>
		(event: SyntheticEvent, isExpanded: boolean) => {
			if (expanded.includes(panelTriggered)) {
				setExpanded(expanded.filter((panel) => panel !== panelTriggered));
			} else {
				setExpanded([...expanded, panelTriggered]);
			}
		};

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenTypography variant='h3' fontSize='48px'>
				{title}
			</DrivenTypography>
			<DrivenBox
				sx={{
					mb: 3,
				}}
			>
				<DrivenAccordion
					handleChange={handleChange}
					panelsOpen={expanded}
					records={accordionItems}
				/>
			</DrivenBox>
		</DrivenContainer>
	);
}

export const query = graphql`
	fragment AccordionFields on ContentfulAccordion {
		__typename
		id
		name
		records {
			id
			title
			body {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
		}
	}
`;
