import { DrivenThemeProvider } from '@drivenbrands/driven-components';
import { DrivenBrands } from '@drivenbrands/driven-tokens';
import type { GatsbyBrowser } from 'gatsby';
import { withPrefix } from 'gatsby';
import Layout from './src/components/layout';
import { LocaleProvider, LocationProvider } from './src/contexts';
import { saveAdParameters } from './src/components/forms';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
	element,
}) => {
	return (
		// TODO: Move Theme provider to Layout
		<DrivenThemeProvider theme={DrivenBrands.AGN}>
			{/* // TODO: Move Location provider to header */}
			<LocationProvider storedSearchLocation='' searchLocation=''>
				{/* // TODO: Move localeProvider to layout. Update to pull translations from Contentful - carstar example */}
				<LocaleProvider>{element}</LocaleProvider>
			</LocationProvider>
		</DrivenThemeProvider>
	);
};

// This was removing any service workers on the page.  But I don't see any service workers in the code.

// if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
// 	window.navigator.serviceWorker.getRegistrations().then((registrations) => {
// 		registrations.forEach((r) => r.unregister());
// 	});
// }

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
	element,
	props,
}) => {
	return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = () => {
	// Redirects to the homepage if the pathname starts with '/es'
	const enforceRedirect = () => {
		if (window.location.pathname.startsWith('/es')) {
			window.location.replace(withPrefix('/'));
		}
	};

	// Ensures the URL is in lowercase
	const enforceLowerCaseURL = () => {
		if (
			window.location.pathname !==
			withPrefix(window.location.pathname).toLowerCase()
		) {
			window.location.replace(
				withPrefix(window.location.pathname).toLowerCase() +
				window.location.search +
				window.location.hash
			);
		}
	};

	const removeAnchorOnRefresh = () => {
		if (window.location.hash) {
			const urlWithoutHash = window.location.pathname + window.location.search;
			window.history.replaceState(null, '', urlWithoutHash);
		}
	};

	enforceRedirect();
	enforceLowerCaseURL();
	saveAdParameters();
	removeAnchorOnRefresh();
};
