import { DrivenBox, DrivenImageCard } from '@drivenbrands/driven-components';
import { cardContentClasses } from '@mui/material/CardContent';

import { graphql } from 'gatsby';
import { GatsbyLinkBehavior as GatsbyLink } from '../gatsby-link-behavior';

export const ImageCard = ({ page, image, imageAlt, title }: any) => {
	const { slug, name } = page || { slug: '', name: '' }; //* default vales for loading*//
	const { publicUrl } = image || {};

	// FIXME: This uses publicUrl. not the optimized gatsbyImage. Refactor to create card that uses image.
	// Either 1. update DrivenComponents to accept image or string. or 2. Create same layout in this file as DrivenImageCard.  JB:12/23
	return (
		<DrivenBox
			flex={1}
			sx={{
				'>.MuiBox-root': {
					maxWidth: { xs: '100%' },
				},

				[`.${cardContentClasses.root}`]: {
					p: 4,

					'& .MuiTypography-root': {
						textTransform: 'none',
					},
				},

				[`.${cardContentClasses.root} > i`]: {
					fontSize: '32px',
				},
			}}
		>
			<DrivenImageCard
				LinkComponent={GatsbyLink}
				link={slug || ''}
				title={title ?? name}
				image={publicUrl}
				imageAlt={imageAlt}
				typography='subtitle1'
			/>
		</DrivenBox>
	);
};

export const ImageCardQuery = graphql`
	fragment ImageCardFields on ContentfulImageCard {
		__typename
		id
		name
		page {
			slug
			name
		}
		imageAlt
		image {
			description
			gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
			id
			title
		}
		title
	}
`;
