import {
	DrivenIcon,
	DrivenStack,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';

export const LoadingIcon = () => {
	return (
		<DrivenStack alignItems='center' display='flex' direction='column'>
			<DrivenIcon isLoading icon={IconTypes.BADGE_CHECK} size={IconSizes.LG} />
		</DrivenStack>
	);
};
