import {
	DrivenAccordion,
	DrivenBox,
	DrivenButton,
	DrivenContentCard,
	DrivenInputWithButton,
	DrivenStack,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import React, { useContext, useMemo, useState } from 'react';

import { Store } from '../../../services';
import {
	ButtonContext,
	ErrorIcon,
	FormStateContext,
	LoadingIcon,
	ProgressButtons,
	searchOnEnter,
	StoreResultCard,
	StoreSearchContext,
} from '../utils';

type ConfirmStoreProps = {
	updateStore: (store?: Store) => void;
};

export const ConfirmStoreForm = React.memo(({
	updateStore,
}: ConfirmStoreProps) => {
	const { handlePrevious } = useContext(ButtonContext);
	const { customerZip, loading, selectedStore } = useContext(FormStateContext);
	const { searchForStores, storesLoading, storeResults, storeResultsError } = useContext(StoreSearchContext);

	const [expanded, setExpanded] = useState<string[]>([]);
	const [showInput, setShowInput] = useState<boolean>(false);
	const [updatedStore, setUpdatedStore] = useState<Store | undefined>();
	const [updatedZip, setUpdatedZip] = useState<string | undefined>();

	const handleAccordionToggle =
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (expanded.includes(panelTriggered)) {
					setExpanded(expanded.filter((panel) => panel !== panelTriggered));
				} else {
					setExpanded([...expanded, panelTriggered]);
				}
			};

	const handleStoreChange = (zip?: string) => {
		if (!zip || !zip?.trim()?.length) {
			return;
		}
		setUpdatedZip(zip);
		setExpanded([...expanded, 'panel0']);
		// Mainstreet API search by zip code to get stores
		searchForStores && searchForStores(zip);
	};

	const handleStoreSelection = (store: Store) => {
		const storeDetails =
			updatedStore && store.ShopId === updatedStore.ShopId ? undefined : store;
		setUpdatedStore(storeDetails);
		updateStore(storeDetails);
	};

	const filteredResults = useMemo(
		() =>
			storeResults?.filter((store) => store.ShopId !== selectedStore?.ShopId),
		[storeResults]
	);

	const storeLocations = [
		{
			summary: `See other locations near ${updatedZip ?? customerZip}`,
			details: (
				<DrivenStack spacing={2} sx={{ marginTop: 1 }}>
					{filteredResults?.map((store, i) => (
						<StoreResultCard
							key={i}
							isSelected={store.ShopId === updatedStore?.ShopId}
							store={store}
							handleStoreSelection={handleStoreSelection}
						/>
					))}
				</DrivenStack>
			),
		},
	];

	return (
		<DrivenStack spacing={4}>
			{selectedStore && (
				<DrivenBox display='flex'>
					<DrivenTypography variant='body2'>
						Confirm or change the service location you selected below. Please
						note that modifying your location after receiving your quote may
						cause changes in pricing and availability.
					</DrivenTypography>
				</DrivenBox>
			)}
			<DrivenContentCard
				content={
					<DrivenStack spacing={2} sx={{ marginTop: 5 }}>
						{selectedStore ? (
							<DrivenStack spacing={2}>
								<DrivenBox alignItems='center' display='flex' flex={3}>
									<DrivenTypography
										variant='subtitle1'
										sx={{
											alignItems: 'center',
											display: 'flex',

											'&::after': {
												backgroundColor: 'grey.300',
												content: '""',
												display: 'inline-block',
												height: 16,
												width: `1px`,
												marginLeft: 1,
											},
										}}
									>
										{selectedStore.City}
									</DrivenTypography>
									<DrivenTypography variant='body1' sx={{ ml: 1, mr: 2 }}>
										{selectedStore.Distance && selectedStore.Distance > 0
											? selectedStore.Distance.toFixed(2)
											: selectedStore.Distance}{' '}
										mi.
									</DrivenTypography>
								</DrivenBox>
								<DrivenBox>
									<DrivenTypography variant='body1'>
										{selectedStore.Address}
									</DrivenTypography>
									<DrivenTypography variant='body1'>
										{selectedStore.City}, {selectedStore.State}{' '}
										{selectedStore.Zip}
									</DrivenTypography>
								</DrivenBox>
							</DrivenStack>
						) : (
							<DrivenStack>
								<DrivenTypography>
									Enter your zip code in the input above to find stores near
									you, then select a service location below.
								</DrivenTypography>
							</DrivenStack>
						)}
						{storesLoading && <LoadingIcon />}
						{filteredResults && filteredResults.length > 0 && (
							<DrivenAccordion
								handleChange={handleAccordionToggle}
								panelsOpen={expanded}
								records={storeLocations}
								plain
							/>
						)}
					</DrivenStack>
				}
				header={
					<DrivenTypography variant='subtitle1'>
						Service Location
					</DrivenTypography>
				}
				headerSecondary={
					<>
						{!showInput && selectedStore && (
							<DrivenButton fullWidth onClick={() => setShowInput(true)}>
								Change Zip
							</DrivenButton>
						)}
						{(showInput || !selectedStore) && (
							<DrivenInputWithButton
								ariaLabel={selectedStore ? 'Update zip code' : 'Find store'}
								buttonText={selectedStore ? 'Update Zip' : 'Find Store'}
								errorHelperText='We could not locate any Auto Glass Now stores near you. Please try another zip code.'
								errorIcon={<ErrorIcon />}
								hasError={storeResultsError}
								handleSubmit={handleStoreChange}
								onKeyDown={(e) => searchOnEnter(e, handleStoreChange)}
								placeholder={selectedStore ? '' : 'Enter zip code'}
								spacing={0}
							/>
						)}
					</>
				}
			/>
			<ProgressButtons
				disableSubmit={selectedStore ? false : !updatedStore}
				handlePrevious={handlePrevious}
				loading={loading}
			/>
		</DrivenStack>
	);
});
