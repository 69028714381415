import {
	DrivenBox,
	DrivenButton,
	DrivenCheckbox,
	DrivenContainer,
	DrivenDropdown,
	DrivenTextInput,
	DrivenToast,
	DrivenTypography
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { captureCustomDataLayerEvent } from '../../../services';
import MarkdownContent from '../../mdx/markdown-content';
import {
	AlertColor,
	contactRequestFormValues,
	contactRequestProps,
} from './contentfulFormTypes';

export const ContactRequest: React.FC<contactRequestProps> = ({ ...props }) => {
	const serviceInfo = [
		{ boxLabel: 'Windshield', boxValue: 'windshield' },
		{ boxLabel: "Driver's Side", boxValue: 'driversSide' },
		{ boxLabel: "Passenger's Side", boxValue: 'passengersSide' },
		{ boxLabel: 'Rear Glass', boxValue: 'rearGlass' },
		{ boxLabel: 'Sun/Moon Roof', boxValue: 'sunMoonRoof' },
	];

	const defaultFormValues: contactRequestFormValues = {
		firstName: '',
		lastName: '',
		zip: '',
		email: '',
		phone: '',
		vehicleMake: '',
		vehicleModel: '',
		vehicleYear: '',
		vin: '',
		windshield: false,
		windshieldRepairLessThanOneInch: false,
		windshieldReplacement: false,
		driversSide: false,
		driversSideFrontDoor: false,
		driversSideBackDoor: false,
		driversSideQuarterPanel: false,
		passengersSide: false,
		passengersSideFrontDoor: false,
		passengersSideBackDoor: false,
		passengersSideQuarterPanel: false,
		rearGlass: false,
		rearWindshield: false,
		pickupTruckSlidingBackGlass: false,
		sunMoonRoof: false,
		optionalNotes: '',
		subscriptionConsent: false,
	};
	

	const {
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors },
	} = useForm<contactRequestFormValues>({
		defaultValues: defaultFormValues,
	});
	const [showToast, setShowToast] = useState<boolean>(false);
	const [showSeverity, setShowSeverity] = useState<AlertColor>('success');
	const [hasStartedTyping, setHasStartedTyping] = useState(false);
  // Initialize state to track selected items in checkboxes
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>(
    serviceInfo.reduce((acc, element) => {
      acc[element.boxValue] = false;
      return acc;
    }, {} as { [key: string]: boolean })
  );

	  // Function to handle checkbox change
		const handleCheckboxChange = (boxValue: string, checked: boolean) => {
			setSelectedItems({ ...selectedItems, [boxValue]: checked });
		};
	const { headline, headlineLevel, headlineStyle, body, subscriptionConsent } = props;
	const theme = useTheme();
	const inputMaxHeight = '48px';
	const inputMaxWidth = '100%';
	// TODO: LM-11.25.23 - this class name style corrects DrivenTextInput issues with centering text or place holder text in the input field.
	// a ticket should be created for fixing this in DrivenTextInput.
	const textFieldStyles = {
		maxWidth: inputMaxWidth,
		maxHeight: inputMaxHeight,
		overflow: 'hidden',
		'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
			padding: '12px 14px',
		},
	};
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	// const isTablet = useMediaQuery(theme.breakpoints.up('md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	const exit = () => {
		reset(defaultFormValues);
	};

	const onSubmit = async (data: contactRequestFormValues) => {
		// This log is kept just to know that the process started, and payload, remove before prod.
		// console.log('Starting the submission process', data);

		try {
			const apiUrl: string = process.env.GATSBY_BASE_API_URL || '';
			const apiKey: string = process.env.GATSBY_API_KEY || '';

			if (!apiUrl) {
				throw new Error('API URL is missing');
			}
			if (!apiKey) {
				throw new Error('API Key is missing');
			}

			const response = await fetch(`${apiUrl}contact-request`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					apikey: apiKey,
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				setShowSeverity('success');
				setShowToast(true);
				captureCustomDataLayerEvent('quote_form_submit');
				exit();
				const responseData = await response.json();
				// Log success status without exposing sensitive data
				console.log('Form submission was successful.');
			} else {
				setShowSeverity('error');
				setShowToast(true);
				const errorData = await response.text();
				console.error('Failed to submit form:', errorData);
				console.log(
					`Failed to submit form: ${response.statusText} (Status Code: ${response.status})`,
					errorData
				);
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};
// console .log(theme.palette, "theme.palette")
	return (
		<DrivenContainer
			sx={{
				display: 'contents',
			}}
		>
			<DrivenBox
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexDirection: isDesktop ? 'row' : 'column',
					gap: isDesktop ? '24px' : '0px',
					margin: '48px auto 0 auto',
					px: isDesktop ? '0px' : '16px',
				}}
			>
				<DrivenBox
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: isDesktop ? '562px' : '100%',
					}}
				>
					<DrivenTypography
						variant={headlineStyle || 'h2'}
						component={headlineLevel || 'h2'}
					>
						{headline || ''}
					</DrivenTypography>
					<DrivenTypography py={2} variant='body1' component='div'>
						<MarkdownContent>
							{body?.childMarkdownRemark.rawMarkdownBody || ''}
						</MarkdownContent>
					</DrivenTypography>
				</DrivenBox>
				<DrivenBox
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: isDesktop ? '562px' : '100%',
					}}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<DrivenTypography sx={{ my: '8px' }}>
							* Indicates required fields
						</DrivenTypography>
						<DrivenTypography
							sx={{
								mb: '4px',
							}}
							variant='h2'
							component='h2'
						>
							About You
						</DrivenTypography>
						<Controller
							name='firstName'
							control={control}
							rules={{ required: 'First name is required' }}
							render={({ field }) => {
								const handleChange = (
									event: React.ChangeEvent<HTMLInputElement>
								) => {
									if (!hasStartedTyping) {
										captureCustomDataLayerEvent('quote_form_started');
										setHasStartedTyping(true);
									}
				
									if ('target' in event) {
										field.onChange(event);
									}
								};
								return (
									<DrivenTextInput
										label='First Name *'
										hasError={Boolean(errors.firstName)}
										errorHelperText={errors.firstName?.message as string}
										{...field}
										onChange={handleChange}
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='lastName'
							control={control}
							rules={{ required: 'Last name is required' }}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};
								return (
									<DrivenTextInput
										label='Last Name *'
										hasError={Boolean(errors.lastName)}
										errorHelperText={errors.lastName?.message as string}
										{...field}
										onChange={handleChange}
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='zip'
							control={control}
							rules={{
								required: 'Zip Code is required',
								pattern: {
									value: /^\d{5}$/,
									message:
										'Invalid ZIP Code format. Please enter a 5-digit ZIP Code.',
								},
							}}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};
								return (
									<DrivenTextInput
										label='Zip Code *'
										hasError={Boolean(errors.zip)}
										errorHelperText={errors.zip?.message as string}
										{...field}
										onChange={handleChange}
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='email'
							control={control}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: 'Invalid email address', // basic regex for email
								},
							}}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};
								return (
									<DrivenTextInput
										label='Email *'
										hasError={Boolean(errors.email)}
										errorHelperText={errors.email?.message as string}
										{...field}
										onChange={handleChange}
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='phone'
							control={control}
							rules={{
								required: 'Phone number is required',
								pattern: {
									value: /^(\d{3}-\d{3}-\d{4}|\d{10})$/,
									message: 'Invalid phone number',
								},
							}}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};
								return (
									<DrivenTextInput
										label='Phone Number *'
										hasError={Boolean(errors.phone)}
										errorHelperText={errors.phone?.message as string}
										placeholder='888-888-8888'
										sx={{
											...textFieldStyles,
										}}
										{...field}
										onChange={handleChange}
									/>
								);
							}}
						/>
						<DrivenTypography
							sx={{
								margin: '30px 0 5px 0',
							}}
							variant='h2'
							component='h2'
						>
							About Your Vehicle
						</DrivenTypography>
						<Controller
							name='vehicleMake'
							control={control}
							rules={{ required: 'Vehicle make is required' }}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};

								return (
									<DrivenTextInput
										label='Vehicle Make *'
										hasError={Boolean(errors.vehicleMake)}
										errorHelperText={errors.vehicleMake?.message as string}
										{...field}
										onChange={handleChange}
										placeholder='Chevrolet'
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='vehicleModel'
							control={control}
							rules={{ required: 'Vehicle model is required' }}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};

								return (
									<DrivenTextInput
										label='Vehicle Model *'
										hasError={Boolean(errors.vehicleModel)}
										errorHelperText={errors.vehicleModel?.message as string}
										{...field}
										onChange={handleChange}
										placeholder='Tahoe'
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
						<Controller
							name='vehicleYear'
							control={control}
							rules={{ required: 'Vehicle year is required' }}
							render={({ field }) => {
								const currentYear = new Date().getFullYear();
								const years = Array.from(
									{ length: currentYear - 1985 },
									(value, index) => currentYear - index
								);
								const yearOptions = years.map((year) => ({
									option: year.toString(),
									value: year.toString(),
								}));
								// Add option for older or classic vehicles
								yearOptions.push({
									option: 'Older than 1985',
									value: 'Older than 1985',
								});

								return (
									<DrivenDropdown
										label='Vehicle Year *'
										options={yearOptions}
										hasError={Boolean(errors.vehicleYear)}
										errorHelperText={errors.vehicleYear?.message as string}
										onChange={(event, child) =>
											field.onChange(event.target.value as string)
										}
										placeholder='2020'
										value={field.value}
										onBlur={field.onBlur}
										sx={{
											...textFieldStyles,
											'& p': {
												color: theme.palette.text.disabled,
											},
										}}
									/>
								);
							}}
						/>
						<Controller
							name='vin'
							control={control}
							render={({ field }) => {
								const handleChange = (
									event:
										| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
										| React.FormEvent<HTMLDivElement>
								) => {
									if ('target' in event) {
										field.onChange(
											event as React.ChangeEvent<
												HTMLInputElement | HTMLTextAreaElement
											>
										);
									}
								};

								return (
									<DrivenTextInput
										label='VIN'
										{...field}
										onChange={handleChange}
										sx={{
											...textFieldStyles,
										}}
									/>
								);
							}}
						/>
		<DrivenBox>
			<DrivenBox
				sx={{
					marginTop: '10px',
					width: '100%',
				}}
			>
			<DrivenTypography>Window(s) that need service * </DrivenTypography>  
			<DrivenTypography sx={{fontSize: '14px'}}>(select all that apply)</DrivenTypography>
			</DrivenBox>
			{serviceInfo.map((element, index) => {
				// Check if the current item is the last item
				const isLastItem = index === serviceInfo.length - 1;
				// Determine the border color
				const borderColor = selectedItems[element.boxValue] && !isLastItem ? '#FFD000' : theme.palette.grey[300];
				return (
				<DrivenBox
				sx={{
					border: `1px solid ${borderColor}`,
					my: '10px',
				}}
					key={element.boxValue}
				>
					<Controller
						name={element.boxValue as keyof contactRequestFormValues}
						control={control}
						render={({ field: { onChange, value, name, ref } }) => (
							<DrivenBox sx={{ 
								backgroundColor: value && index < serviceInfo.length - 1 ? theme.palette.grey[200] : 'white',
								px: '10px',
								mb: index < serviceInfo.length - 1 && value ? '12px' : '0',
							}}>
								<DrivenCheckbox
									sx={{
										color: 'black',
									}}
									name={name}
									ref={ref}
									checked={!!value}
									label={element.boxLabel}
									onChange={(e) => {
										onChange(e.target.checked);
										handleCheckboxChange(element.boxValue, e.target.checked);
									}}
								/>
							</DrivenBox>
						)}
      />
      {/* Conditionally render dropdowns for Windshield  */} 
      {element.boxValue === 'windshield' && watch('windshield') && (
        <DrivenBox sx={{ px:'10px', display: 'flex', flexDirection: 'column', width: '100%'}}>
					<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="windshieldRepairLessThanOneInch"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
									sx={{
										color: 'black',
									}}
									label="Windshield Repair"
									{...field}
									checked={field.value}
									onChange={(e) => {
										field.onChange(e.target.checked);
									}}
								/>
							)}
						/>
						<StaticImage
								src="./images/crack-less-than-inch.png"
								alt="Small crack in windshield"
								layout='fixed'
								width={75}
								placeholder='blurred'
							/>
					</DrivenBox>
					<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="windshieldReplacement"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
									sx={{
										color: 'black',
									}}
									label="Windshield Replacement"
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							)}
						/>
						<StaticImage
							src="./images/frontWindshield.png" 
							alt="Windshield Replacement"
							layout='fixed'
							width={35}
							placeholder='blurred'
						/>
						</DrivenBox>
        </DrivenBox>
      )}
			{element.boxValue === 'driversSide' && watch('driversSide') && (
        <DrivenBox sx={{display: 'flex', flexDirection: 'column', px:'10px', width: '100%'}}>
					<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="driversSideFrontDoor"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
								sx={{
									color: 'black',
								}}
									label="Front Door"
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							)}
						/>
						<StaticImage
							src="./images/driversside-front-window.png" 
							alt="driver side fron window eplacement"
							layout='fixed'
							width={72}
							placeholder='blurred'
						/>
						</DrivenBox>
						<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="driversSideBackDoor"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
									sx={{
										color: 'black',
									}}
									label="Back Door"
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							)}
						/>
						<StaticImage
							src="./images/driversside-back-window.png" 
							alt="Driver Side Back window replacement"
							layout='fixed'
							width={73}
							placeholder='blurred'
						/>
					</DrivenBox>
					<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="driversSideQuarterPanel"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
									sx={{
										color: 'black',
									}}
									label="Quarter Panel"
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							)}
						/>
						<StaticImage
							src="./images/driversside-quarter-panel.png" 
							alt="drivers Side Quarter Panel"
							layout='fixed'
							width={73}
							placeholder='blurred'
						/>
					</DrivenBox>
        </DrivenBox>
      )}
			{element.boxValue === 'passengersSide' && watch('passengersSide') && (
				<DrivenBox sx={{display: 'flex', flexDirection: 'column', px:'10px', width: '100%'}}>
					<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
						<Controller
							name="passengersSideFrontDoor"
							control={control}
							render={({ field }) => (
								<DrivenCheckbox
									label="Front Door"
									sx={{
										color: 'black',
									}}
									{...field}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							)}
						/>
							<StaticImage
								src="./images/passenger-side-front-window.png" 
								alt="passenger side front window replacement"
								layout='fixed'
								width={73}
								placeholder='blurred'
							/>
						</DrivenBox>
						<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
							<Controller
								name="passengersSideBackDoor"
								control={control}
								render={({ field }) => (
									<DrivenCheckbox
										label="Back Door"
										sx={{
											color: 'black',
										}}
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.target.checked)}
									/>
								)}
							/>
							<StaticImage
								src="./images/passenger-side-rear-window.png" 
								alt="passenger side rear window replacement"
								layout='fixed'
								width={73}
								placeholder='blurred'
							/>
						</DrivenBox>
						<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
							<Controller
								name="passengersSideQuarterPanel"
								control={control}
								render={({ field }) => (
									<DrivenCheckbox
										sx={{
											color: 'black',
										}}
										label="Quarter Panel"
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.target.checked)}
									/>
								)}
							/>
							<StaticImage
								src="./images/passenger-side-quarter-panel.png" 
								alt="passenger side quarter panel window"
								layout='fixed'
								width={73}
								placeholder='blurred'
							/>
						</DrivenBox>
        </DrivenBox>
      )}
				{element.boxValue === 'rearGlass' && watch('rearGlass') && (
					<DrivenBox sx={{display: 'flex', flexDirection: 'column', px:'10px', width: '100%'}}>
						<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
							<Controller
								name="rearWindshield"
								control={control}
								render={({ field }) => (
									<DrivenCheckbox
										label="Rear Windshield"
										sx={{
											color: 'black',
										}}
										{...field}
										checked={field.value}
										onChange={(e) => field.onChange(e.target.checked)}
									/>
								)}
							/>
							<StaticImage
								src="./images/rear-windshield.png" 
								alt="Rear windshield replacement"
								layout='fixed'
								width={35}
								placeholder='blurred'
							/>
							</DrivenBox>
							<DrivenBox sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isMobile ? '15px' : '30px'}}>
								<Controller
									name="pickupTruckSlidingBackGlass"
									control={control}
									render={({ field }) => (
										<DrivenCheckbox
											label="Pick-Up Truck Sliding Back Glass"
											sx={{
												color: 'black',
											}}
											{...field}
											checked={field.value}
											onChange={(e) => field.onChange(e.target.checked)}
										/>
									)}
								/>
								</DrivenBox>
							</DrivenBox>
						)}
					</DrivenBox>
				)})}
			</DrivenBox>
					<Controller
						name='optionalNotes'
						control={control}
						render={({ field }) => {
							const handleChange = (
								event:
									| React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
									| React.FormEvent<HTMLDivElement>
							) => {
								if ('target' in event) {
									field.onChange(
										event as React.ChangeEvent<
											HTMLInputElement | HTMLTextAreaElement
										>
									);
								}
							};

							return (
								<DrivenTextInput
									label='Notes'
									{...field}
									onChange={handleChange}
									multiline
									placeholder='Enter any additional information you think we should know.'
									sx={{
										...textFieldStyles,
									}}
								/>
							);
						}}
					/>
						<DrivenButton
							sx={{
								maxWidth: inputMaxWidth,
								width: '100%',
								height: '48px',
								marginTop: '24px',
								borderRadius: '24px',
							}}
							type='submit'
						>
							Submit
						</DrivenButton>
					</form>
				</DrivenBox>
			</DrivenBox>
			{showToast && (
				<DrivenToast
					open={showToast}
					handleClose={() => setShowToast(false)}
					title=''
					severity={showSeverity}
					toasterProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'center',
						},
					}}
				>
					<DrivenTypography>
						{showSeverity === 'success'
							? 'Success! Our Auto Glass Now experts will be in touch soon.'
							: 'Oops, there was an issue. Please try again.'}
					</DrivenTypography>
				</DrivenToast>
			)}
		</DrivenContainer>
	);
};

export default ContactRequest;
