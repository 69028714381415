export const getMapMarker = (current_brand: string, active: boolean): string => {
  let iconName = '';
  if (active && current_brand === 'Auto Glass Now') {
      iconName = 'agnMapPin.png';
  } else {
      iconName = 'nonAgnMapPin.png';
  }

  return `/assets/images/${iconName}`;
};
