import { graphql, useStaticQuery } from 'gatsby';

import { formatNavigationLinks } from '../utils';

export const useEnglishNavigationQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			primaryNav: contentfulNavigation(
				name: { eq: "Primary Navigation" }
				node_locale: { eq: "en-US" }
			) {
				id
				navigationItems {
					link {
						... on ContentfulLink {
							id
							name
							path
							gtmEventName
							gtmAdditionalFields {
								internal {
									content
								}
							}
						}
						... on ContentfulPage {
							id
							name
							path
							slug
						}
					}
					linkType
					displayText
					alternateDisplayText
					secondaryDisplayText
					submenu {
						... on ContentfulNavLink {
							id
							name
							text
							page {
								path
								slug
							}
						}
					}
				}
				logo {
					gatsbyImageData(
						width: 155
						formats: [AUTO, WEBP, AVIF]
						quality: 100
					)
					title
					description
				}
			}
			utilityNav: contentfulNavigation(
				name: { eq: "Utility Navigation" }
				node_locale: { eq: "en-US" }
			) {
				id
				navigationItems {
					link {
						... on ContentfulLink {
							id
							name
							path
							gtmEventName
							gtmAdditionalFields {
								internal {
									content
								}
							}
						}
						... on ContentfulPage {
							id
							name
							path
							slug
						}
					}
					linkType
					displayText
					submenu {
						... on ContentfulNavLink {
							id
							name
							text
							page {
								path
								slug
							}
						}
					}
				}
			}
			footerNav: contentfulNavigation(
				name: { eq: "Footer Navigation" }
				node_locale: { eq: "en-US" }
			) {
				id
				navigationItems {
					name
					link {
						... on ContentfulLink {
							id
							name
							path
							gtmEventName
							gtmAdditionalFields {
								internal {
									content
								}
							}
						}
						... on ContentfulPage {
							id
							name
							path
							slug
						}
					}
					linkType
					displayText
					submenu {
						... on ContentfulNavLink {
							id
							name
							text
							page {
								path
								slug
							}
						}
						... on ContentfulLink {
							id
							name
							path
							gtmEventName
							gtmAdditionalFields {
								internal {
									content
								}
							}
						}
					}
				}
			}
			legalFooter: contentfulNavigation(
				name: { eq: "Footer Legal" }
				node_locale: { eq: "en-US" }
			) {
				id
				navigationItems {
					name
					displayText
					submenu {
						... on ContentfulNavLink {
							id
							name
							text
							page {
								path
								slug
							}
						}
					}
				}
			}
			socialFooter: contentfulNavigation(
				name: { eq: "Footer Social" }
				node_locale: { eq: "en-US" }
			) {
				id
				navigationItems {
					name
					displayText
					submenu {
						... on ContentfulSocialLink {
							id
							name
							link
							externalLink
							icon
						}
					}
				}
			}
		}
	`);

	return {
		primaryNavigation: {
			items: data.primaryNav.navigationItems,
			logo: data.primaryNav.logo,
		},
		utilityNavigation: formatNavigationLinks(data.utilityNav.navigationItems),
		footerNavigation: formatNavigationLinks(data.footerNav.navigationItems),
		footerLegalNavigation: formatNavigationLinks(
			data.legalFooter.navigationItems
		).pop(),
		footerSocialNavigation: formatNavigationLinks(
			data.socialFooter.navigationItems
		).pop(),
	};
};
