import {
	CustomTypography,
	DrivenBox,
	DrivenContainer,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';

import { graphql } from 'gatsby';
import { ComponentMatch } from '../hooks';
import { PageContextProps } from '../templates/default-page.template';
import { ContentfulButtonProps } from './button';
import MarkdownContent from './mdx/markdown-content';

export type SectionType = {
	__typename: string;
	id: string;
	inline?: 'left aligned' | 'right aligned';
	heading?: {
		text?: string;
		level?: string;
		style?: CustomTypography;
		className?: string;
	};
	body: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	bodyBlocks: Array<any>;
	blocks: Array<any>;
	disclaimer: {
		childMarkdownRemark: {
			rawMarkdownBody?: string;
		};
	};
	actions?: Array<ContentfulButtonProps>;
	contentSectionButton?: any; // FIXME: Only added for detail pages. Button naming issue.  JB:12/23
	backgroundColor?: 'Grey' | 'White';
	pageContext: PageContextProps;
};

export const Section = ({
	backgroundColor,
	blocks,
	body,
	bodyBlocks,
	disclaimer,
	heading,
	inline,
	pageContext,
}: SectionType) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { text, style, level, className, ...restHeadingProps } = heading || {};

	const sectionBackgroundColor = {
		backgroundColor: backgroundColor === 'Grey' ? 'grey.100' : 'transparent',
	};
	return (
		<DrivenBox
			my={0}
			sx={{
				...sectionBackgroundColor,
				py: { xs: backgroundColor === 'Grey' ? 6 : 0 },

				'.MuiGrid-root, .MuiAccordion-root, .MuiContainer-root, .MuiBox-root': {
					...sectionBackgroundColor,
				},
			}}
		>
			<DrivenContainer
				sx={{
					px: { xs: 0 },
				}}
			>
				<DrivenBox
					display='flex'
					flexDirection={
						inline && !isMobile
							? inline === 'right aligned'
								? 'row-reverse'
								: 'row'
							: 'column'
					}
				>
					{text && text.length > 0 && (
						<DrivenBox
							flex={1}
							textAlign={!inline || isMobile ? 'center' : 'left'}
							px={3}
							pt={4}
						>
							<DrivenTypography
								component={level}
								variant={style}
								className={className}
								sx={{
									mb: { xs: 2, md: 4 },
									textTransform: { xs: 'none' },
								}}
								{...restHeadingProps}
							>
								{text}
							</DrivenTypography>
						</DrivenBox>
					)}

					{body && (
						<DrivenBox flex={1} px={3}>
							<MarkdownContent>
								{body.childMarkdownRemark.rawMarkdownBody}
							</MarkdownContent>
							{bodyBlocks && <>{ComponentMatch(bodyBlocks, undefined, pageContext)}</>}
						</DrivenBox>
					)}
				</DrivenBox>
				{blocks && ComponentMatch(blocks, undefined, { pageContext })}
				{disclaimer && (
					<DrivenBox>
						<MarkdownContent>
							{disclaimer.childMarkdownRemark.rawMarkdownBody}
						</MarkdownContent>
					</DrivenBox>
				)}
			</DrivenContainer>
		</DrivenBox>
	);
};
// query sending data for page for newssearch
export default Section;

export const query = graphql`
	fragment SectionFields on ContentfulSection {
		__typename
		id
		heading {
			text
			level
			style
		}
		body {
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
		bodyBlocks {
			...ListFields
		}
		backgroundColor
		inline
		blocks {
			...GridFields

			...TabsFields
			
			...CustomSectionFields

			...ContentSectionFields

			...CardGroupFields

			...AccordionFields

			...ImageBannerFields
			
			...TextBlockFields
			
			... on ContentfulNewsSearch {
				__typename
				id
				contentful_id
				name
			}
		}
		disclaimer {
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
	}
`;
