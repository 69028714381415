import {
	DrivenTypography,
	IconPrefix,
	IconTypes,
} from '@drivenbrands/driven-components';
import * as React from 'react';

export const createBulletedListItems = (list: Array<string>) => {
	return list.map((item) => {
		return {
			icon: IconTypes.CHECK,
			iconPrefix: IconPrefix.fal,
			text: <DrivenTypography variant='body2'>{item}</DrivenTypography>,
		};
	});
};

export const createDropdownOptions = (data: Array<any>) => {
	return data.map((item) => ({ option: item, value: item }));
};

export function formatPhoneNumber(value: string | number) {
	const formattedValue = typeof value === 'number' ? value.toString() : value;

	if (!formattedValue) return formattedValue;

	const phoneNumber = formattedValue.replace(/[^\d]/g, '');

	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return phoneNumber;

	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

