import {
	DrivenBox,
	DrivenContainer,
	DrivenCTABanner,
	DrivenHero,
	DrivenLink,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useTheme } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';
import { LayoutProps } from '../components/layout';
import { SEO } from '../components/seo';

interface Page {
	name: string;
	path: string;
	title: string;
}
interface Store {
	store_name: string;
	path: string;
	store_state?: string;
}

interface StoresByState {
	[key: string]: Store[];
}

interface SitemapProps {
	allContentfulPage: {
		nodes: Page[];
	};
	allStoreLocation: {
		nodes: Store[];
	};
}

const Sitemap: React.FC<LayoutProps> = (props) => {
	const data: SitemapProps = useStaticQuery(graphql`
		query {
			allContentfulPage(filter: { node_locale: { eq: "en-US" } }) {
				nodes {
					name
					path
					title
				}
			}
			allStoreLocation {
				nodes {
					store_name
					path
					store_state
				}
			}
		}
	`);

	const theme = useTheme();

	const { allContentfulPage, allStoreLocation } = data;

	// Group stores by their state
	const storesByState = allStoreLocation.nodes.reduce(
		(acc: StoresByState, store: Store) => {
			if (!store.store_state) {
				return acc;
			}

			if (!acc[store.store_state]) {
				acc[store.store_state] = [];
			}

			acc[store.store_state].push(store);

			return acc;
		},
		{}
	);

	// FIXME: DrivenHero takes optional coverImage prop. If you don't supply media element and no coverImage it will 404 trying to fetch undefined.
	// On DrivenHero the styledPaper is using that coverImage as a background url. It needs to be optional.
	// DrivenHero should also have optional media prop. If you don't supply it, it should not render the media element.

	return (
		<>
			<DrivenHero media={<></>} coverImage='' mediaProps={{}} darkMode>
				<DrivenTypography py={2} component='h1' variant='h2'>
					Sitemap
				</DrivenTypography>
			</DrivenHero>
			<DrivenContainer maxWidth='lg' style={{ margin: '0 auto', padding: '0' }}>
				<DrivenBox component='ul' style={{ paddingBottom: '16px' }}>
					<DrivenTypography py={2} component='h3' variant='h3'>
						Pages
					</DrivenTypography>
					{allContentfulPage.nodes
						.filter((item: Page) => {
							// blog path to be added back after blog implementation - DC 04/06
							if (
								item.path === '/sitemap' ||
								item.path === '/es/sitemap' ||
								item.path === '/404' ||
								item.path === '/blog' ||
								item.path === '/individuallocation' ||
								item.path === '/individual-mobile-location-detail-page' ||
								item.path === '/landing/2023-mothers-day'
							) {
								return false;
							}
							return true;
						})
						.reduce((uniquePages: Page[], item: Page) => {
							// Check if the page already exists
							const existingPage = uniquePages.find(
								(i) => i.name === item.name
							);
							if (!existingPage) {
								// If the item is not found, add it to the uniqueItems array
								uniquePages.push(item);
							}
							return uniquePages;
						}, [])
						.sort((a: any, b: any) => {
							// Sort the items in alphabetical order based on the 'name' property
							if (a.name && b.name) {
								return a.name.localeCompare(b.name);
							}
						})
						.map((item: Page, key: number) => (
							<DrivenLink
								key={`sitemap-page-link-${key}`}
								variant='body1'
								LinkComponent={GatsbyLink}
								href={item.path}
							>
								<DrivenBox key={`sitemap-link-listitem-${key}`} component='li'>
									{item.name?.toUpperCase()}
								</DrivenBox>
							</DrivenLink>
						))}
				</DrivenBox>
				<DrivenBox component='ul'>
					<DrivenTypography py={2} component='h3' variant='h3'>
						Stores
					</DrivenTypography>
					{Object.keys(storesByState)
						.sort()
						.map((state: string, key: number) => (
							<React.Fragment key={`sitemap-state-category-${key}`}>
								<DrivenTypography variant='h6' py={2}>
									{state}
								</DrivenTypography>
								{storesByState[state]
									.sort((a: Store, b: Store) =>
										a.store_name.localeCompare(b.store_name)
									)
									.map((store: Store, storeKey: number) => (
										<DrivenLink
											key={`sitemap-store-link-${storeKey}`}
											variant='body1'
											LinkComponent={GatsbyLink}
											href={store.path}
										>
											<DrivenBox
												key={`sitemap-store-listitem-${storeKey}`}
												component='li'
												py={0}
											>
												{store?.store_name.toUpperCase()}
											</DrivenBox>
										</DrivenLink>
									))}
							</React.Fragment>
						))}
				</DrivenBox>
			</DrivenContainer>
			{/* // FIXME: Same as the layout CTA Banner. */}
			<DrivenCTABanner
				ctaText='FIND A LOCATION'
				ctaProps={{
					href: '/our-locations',
					style: { borderRadius: 30 },
				}}
				header={
					<DrivenTypography variant='h3' sx={{ textTransform: { xs: 'none' } }}>
						Find Your Local Auto Glass Now!
					</DrivenTypography>
				}
				sx={{
					pt: { xs: 0, sm: '100px' },
					pb: { xs: 0, sm: '100px' },
					backgroundColor: 'primary.main',
				}}
			/>
		</>
	);
};

export const Head = () => (
	<SEO
		path='/sitemap'
		title='Sitemap'
		description='Render a list of links to every page on the website.'
		canonical='https://www.autoglassnow.com/sitemap/'
	/>
);

export default Sitemap;
