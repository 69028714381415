import { DrivenBox } from '@drivenbrands/driven-components';

import { graphql } from 'gatsby';
import ContentfulImage from './contentful-image';

interface GridMediaProps {
	image?: {
		description: string;
		title: string;
		publicUrl: string;
	};
	logo?: {
		description: string;
		title: string;
		publicUrl: string;
	};
	mediaHeight?: number;
	children?: React.ReactNode;
}

export default function GridMedia({
	image,
	logo,
	mediaHeight,
}: GridMediaProps) {
	return (
		<DrivenBox
			sx={{
				display: 'flex',
				alignItems: 'center',
				alignSelf: 'center',
				justifyContent: 'center',
				maxHeight: mediaHeight ? `${mediaHeight}px` : 'inherit',

				'& img': {
					maxHeight: mediaHeight ? `${mediaHeight}px` : 'inherit',
				},
			}}
		>
			{image && <ContentfulImage image={image} width='100%' />}
			{logo && (
				<img
					src={logo.publicUrl}
					alt={logo.description}
					style={{ width: '100%', objectFit: 'scale-down' }}
				/>
			)}
		</DrivenBox>
	);
}

export const query = graphql`
	fragment GridMediaFields on ContentfulGridMedia {
		__typename
		id
		name
		image {
			description
			gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
			id
			title
		}
		logo {
			description
			gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
			id
			title
		}
		mediaHeight
	}
`;
