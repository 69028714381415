declare global {
	interface Window {
		dataLayer?: any;
	}
}

export const captureCustomDataLayerEvent = (
	event: string,
	additionalEventProps?: any
) => {

	// console.log("<<<<<<<>>>>>>  DATA EVENT <<<<<<<<>>>>>", event, additionalEventProps)

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ event, ...additionalEventProps });
};
