import { APIRequestHeaders } from '@drivenbrands/gatsby-source-locations/dist/types';
import { transformNewLocationToOld } from '@drivenbrands/gatsby-source-locations/src/location-format';
import { LegacyStoreLocation } from '@drivenbrands/gatsby-source-locations/src/types';
import { Status } from '@googlemaps/react-wrapper';
import {
	DrivenAccordion,
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenGoogleAutocomplete,
	DrivenGrid, DrivenInputWithButton,
	DrivenLink,
	DrivenMap,
	DrivenScrollableList,
	DrivenTypography,
	MapWrapper,
	Marker, SelectedLocation,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import slugify from 'slugify';

import { LocationsBreadcrumbs } from '../components/breadcrumbs/breadcrumbs';
import { LocationsCard } from '../components/cards';
import CTABanner from '../components/cta-banner';
import { formatPhoneNumber } from '../components/forms';
import { GatsbyLinkBehavior as GatsbyLink } from '../components/gatsby-link-behavior';
import { SEO } from '../components/seo';
import { LocaleContext, LocationContext } from '../contexts';
import { captureCustomDataLayerEvent } from '../services';
import {
	getMapMarker,
	groupStoreCitiesByState,
	isMobileKioskOrSatellite,
	moveCitiesByStateToColumns,
	stateNameToAbbreviation,
	searchStoresFromFile,
	mapStoreData,
	SEARCH_RADIUS_IN_MILES,
} from '../utils';
import { PageContextProps, PageHeadProps } from './default-page.template';
import MobileLocations from '../../static/kiosk&satellite-locations.json';

type locationsPageTemplateProps = {
	data: {
		page: {
			blocks: Queries.LocationsPageTemplateQueryQuery['page'];
		};
		ctaBanner: Queries.LocationsPageTemplateQueryQuery['ctaBanner'];
		storeLocations: Queries.LocationsPageTemplateQueryQuery['storeLocations'];
		allContentfulLocationContent: Queries.LocationsPageTemplateQueryQuery['allContentfulLocationContent'];
	};
	pageContext: PageContextProps;
};

export const Head = ({ data, pageContext }: PageHeadProps) => {
	const { searchValue } = pageContext || '';
	const { pagePath } = pageContext || '';

	const pathSegments = pagePath
		.replace(/^\//, '')
		.replace(/\/$/, '')
		.split('/');

	const stateHeading = `Windshield Replacement & Auto Glass Repair in ${searchValue}`;
	const cityHeading = `Auto Glass Now® Locations in ${searchValue}`;

	const stateMeta = `Get windshield repair and replacements at an Auto Glass Now® near you. Find a location in ${searchValue} today!`;
	const cityMeta = `Get a free windshield inspection at your local Auto Glass Now® in ${searchValue}. Visit your local shop for windshield replacement services today!`
	const ogImageUrl = data?.page?.blocks?.find((block) => block.__typename === 'ContentfulHero')?.media?.gatsbyImageData?.images?.fallback?.src;

	const title =
		pathSegments.length === 1 && !searchValue
			? 'Auto Glass Now® Locations'
			: pathSegments.length === 2
				? stateHeading
				: cityHeading;
	const description =
		pathSegments.length === 1 && !searchValue
			? 'Auto Glass Now® Locations'
			: pathSegments.length === 2
				? stateMeta
				: cityMeta;

	return (
		<SEO
			path={pageContext.pagePath}
			title={title ?? data.page.title}
			description={description ?? data.page.description}
			canonical={`https://www.autoglassnow.com${pagePath}/`}
			ogImageUrl={ogImageUrl}
		/>
	);
};

/**
 * Defining a few terms, since their conceptual overlap
 * has a lot of potential for confusion.
 *
 * place:
 * Where on the map to search for AGN stores. Selected via autocomplete.
 *
 * store:
 * AGN store.
 *
 * location:
 * Browser's location path.
 *
 * Variables defined by this Page follow these definitions. Components
 * or properties imported from elsewhere may not be in alignment.
 * Ex: DrivenLocationCard renders a `store` object on this Page.
 */

const CitiesByState = ({ citiesByState, handleClick, isLocaleSpanish }) => {
	const theme = useTheme();

	/**
    Convert the lists of stores for display by state an an accordion.
    On mobile, all the stores go into one accordion.
    On md+, split the stores into two accordions, for display in columns.
	 */

	const numCitiesByStateColumns = useMediaQuery(theme.breakpoints.up('md'))
		? 2
		: 1;

	const citiesByStateColumns = moveCitiesByStateToColumns(
		citiesByState,
		numCitiesByStateColumns
	);

	const cityLink = (city, state) => {
		return `${
			isLocaleSpanish ? '/es' : ''
		}/our-locations/${stateNameToAbbreviation(
			state.state
		).toLowerCase()}/${slugify(city, {
			lower: true,
			strict: true,
		})}/`;
	};

	return (
		<DrivenBox>
			<DrivenTypography
				variant='h2'
				component='h2'
				marginBottom={{ xs: 3, sm: 5 }}
				textAlign='center'
			>
				View By State
			</DrivenTypography>

			<DrivenGrid container spacing={4} mb={{ xs: 4, sm: 6, md: 8, lg: 12 }}>
				{citiesByStateColumns.map((column, index) => (
					<DrivenGrid item xs={12} md={6} key={`agn-Col${index}`}>
						<DrivenAccordion
							handleChange={(event) => event.expanded}
							records={column.map((state) => ({
								summary: state.state,
								details: (
									<DrivenBox
										display='flex'
										flexDirection='column'
										alignItems='flex-start'
										mb={2}
									>
										{state.cities.map((city) => (
											<DrivenLink
												variant='text'
												color='inherit'
												mb={1}
												key={city}
												href={cityLink(city, state)}
											>
												{`${city}`}
											</DrivenLink>
										))}
									</DrivenBox>
								),
							}))}
						/>
					</DrivenGrid>
				))}
			</DrivenGrid>
		</DrivenBox>
	);
};

/**
 * Display instructions for using the search or a message indicating 0 results
 * @param {string} message
 * @returns DrivenBox
 */
const NoSearchResults = ({ message }: { message: string }) => (
	<DrivenBox
		alignSelf='center'
		width='100%'
		mt={6}
		pt='240px'
		sx={{
			backgroundImage: 'url(/assets/images/locations-illustration.png)',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% 0',
		}}
	>
		<DrivenTypography
			variant='h6'
			component='p'
			textAlign='center'
			sx={{ color: 'text.secondary' }}
		>
			{message}
		</DrivenTypography>
	</DrivenBox>
);

const LocationsPageTemplate = ({ data, pageContext, ...props }: locationsPageTemplateProps) => {
	const { storeLocations } = data;
	const { searchLocation, storedSearchLocation,setStoredSearchLocation, setSearchLocation } = useContext(LocationContext);
	const { generateNewUrlWithLocale, locale, isLocaleSpanish } =
		useContext(LocaleContext);

	const normalizedLocationsFromFile: { store_city: string; store_state: string; }[] = [];

	MobileLocations.forEach((store: any) => {
		normalizedLocationsFromFile.push({
			store_city: store.City,
			store_state: store['State / Province'],
		})
	})
	const citiesByState = groupStoreCitiesByState([...storeLocations.nodes, ...normalizedLocationsFromFile]);

	const [mapStatus, setMapStatus] = useState(Status.LOADING);
	const [activeMapMarker, setActiveMapMarker] = useState();
	const [mapCenter, setMapCenter] = useState();
	const [mapMarkers, setMapMarkers] = useState<React.ReactNode[]>([]);
	const [mapSelectedPlace, setMapSelectedPlace] = useState();
	const [geolocation, setGeolocation] = useState(null);
	const [resultFilter, setResultFiler] = useState([]);
	const [storesResultsCards, setStoresResultsCards] = useState<React.ReactNode[]>([]);
	const [scrollItem, setScrollItem] = useState();

	const { ctaBanner } = data;
	const { allContentfulLocationContent } = data;

	// used for city/state pages
	const { searchValue: stateSearchValue } = pageContext || '';
	const [searchValue, setSearchValue] = useState(stateSearchValue || '');
	const [pageSlug, setPageSlug, ] = useState(pageContext.slug || '/our-locations/');

	useEffect(() => {
		if (storedSearchLocation && typeof storedSearchLocation === 'string') {
			setGeolocation(storedSearchLocation);
		} else if (searchLocation) {
			if (searchLocation?.location) {
				// set location using latitude & longitude)
				setGeolocation({
					lat: searchLocation.location.lat(),
					lng: searchLocation.location.lng(),
				});
			} else if (typeof searchLocation === 'string') {
				// set location using the query string
				setGeolocation(searchLocation);
			}
		} else if (searchValue && typeof searchValue === 'string') {
			// set location using the query string
			setGeolocation(searchValue);
		} else if (navigator.geolocation) {
			// get visitor's location
			navigator.geolocation.getCurrentPosition((position) => {
				const { latitude, longitude } = position.coords;
				setGeolocation({ lat: latitude, lng: longitude });
			});
		}
	}, [searchLocation, storedSearchLocation]);

	/**
	 * Update search results with a list of stores
	 * @param {google.maps.places.PlaceGeometry} place
	 */
	const searchStoresByPlace = async (place: { place: string; location: { lat: () => any; lng: () => any; }; }) => {
		const response = await fetch(`${process.env.GATSBY_BASE_API_URL}stores`, {
			method: 'POST',
			headers: {
				apikey: process.env.GATSBY_API_KEY || '',
				Accept: 'application/json',
				'Content-Type': 'application/json',
			} as APIRequestHeaders,
			body: JSON.stringify({
				lat: place.location.lat(),
				lng: place.location.lng(),
				distance: SEARCH_RADIUS_IN_MILES,
			}),
		});

		await response.json().then(({ agn, success }) => {
			if (success && activeMapMarker) {
				setActiveMapMarker();
				setScrollItem();
				setMapMarkers();
				setMapCenter();
				setStoresResultsCards();
			}

			const transformedLocations = agn.map((store) => {
				// match with data from contentful
				return transformNewLocationToOld(store);
			});

			const activeStores = transformedLocations.filter((store) => store.is_active);

			const storesFromFileByCoordinates = searchStoresFromFile({
				lat: place.location.lat(),
					lng: place.location.lng(),
			}, MobileLocations);

			const googleAutoCompleteZipMatch = place.place.match(/\b\d{5}\b/);
			const searchLocationZipCode = searchLocation?.place?.match(/\b\d{5}\b/);
			const zipCode = googleAutoCompleteZipMatch?.[0] || searchLocationZipCode?.[0] || undefined;

			const storesFromFileByZipCode = zipCode ? MobileLocations.filter((location: any) => {
					const locationZipCodes = location['Zip Codes']?.split(',');
					return locationZipCodes && locationZipCodes.includes(zipCode);
			}) : [];

			const fileStores = [
				...new Map(
					[...storesFromFileByCoordinates, ...storesFromFileByZipCode].map(store => [
						store['Franchise ID'],
						store,
					])
				).values(),
			];

			const mappedFileStores = fileStores.map((store) => mapStoreData(store));

			const allStores = [
				...activeStores,
				...mappedFileStores,
			];

			const uniqueStores = [
				...new Map(allStores.map(store => [store.store_id, store])).values(),
			];

			setResultFiler(uniqueStores);
		});
	};

	/**
	 * Navigate to a store
	 * @param {*} store
	 */
	const navigateToStore = async (store) => {
		await navigate(
			generateNewUrlWithLocale(
				`/our-locations/${slugify(store.store_city, {
					lower: true,
					strict: true,
				})}-${store.store_id}/`,
				locale
			),
			{
				state: {
					// Distance field is not available from graphql for some reason
					distance: store.distance,
					searchTerm: location.state?.searchTerm,
					place: mapSelectedPlace
						? {
							lat: mapSelectedPlace?.location?.lat(),
							lng: mapSelectedPlace?.location?.lng(),
						}
						: null,
					category: 'agn',
				},
			}
		);
	};

	/**
	 * Select a store when its marker is clicked and center the map
	 * @param {google.maps.Marker} marker
	 */
	const handleMapMarkerClick = (marker) => {
		setActiveMapMarker(marker);
		if (marker !== undefined) {
			const storeIndex = resultFilter.findIndex((store) => {
				if (store.store_name === marker.title) {
					captureCustomDataLayerEvent('store_location_map_click', {
						store: store.store_id,
					});
					return store;
				}
			});

			setScrollItem(storeIndex);

			const position = marker.getPosition();

			if (position && position.lat() && position.lng()) {
				setMapCenter({ lat: position.lat(), lng: position.lng() });
			}
		} else {
			setScrollItem();
		}
	};

	/**
	 * Generate the list of cards for stores returned by the search API.
	 */
	useEffect(() => {
		if (resultFilter.length) {
			setStoresResultsCards(
				resultFilter.map((store: LegacyStoreLocation, i) => {
					const storeEdit = store; // create a copy of the store object to edit phone
					// TODO: update in components to allow other numbers.
					// TODO: refactor to a display phone number component
					storeEdit.store_phone = formatPhoneNumber(
						store.organic_phone || store.store_phone
					);

					const findStoreContent = allContentfulLocationContent.nodes.find(
						(locationContent) => locationContent.storeId === store.store_id
					);

					storeEdit.mobileRepair = isMobileKioskOrSatellite(storeEdit.location_type);
					if (storeEdit.mobileRepair && !storeEdit.serviceArea) {
						storeEdit.serviceArea = findStoreContent?.serviceArea.childMarkdownRemark.rawMarkdownBody;
					}

					return (
						// TODO: refactor to separate component
						// TODO: add hover to highlight marker on map
						<LocationsCard
							locationActions={
								<DrivenButton
									aria-label='Get a Quote'
									color='primary'
									sx={{ borderRadius: 30, maxWidth: { xs: 358, sm: 325 }} }
									LinkComponent={GatsbyLink}
									onClick={() =>
										captureCustomDataLayerEvent('location_page_get_quote', {
											store_id: storeEdit.store_id,
										})
									}
									href='/online-estimate'
								>
									Get a Quote
								</DrivenButton>
							}
							key={`locations-card-${storeEdit.store_id}`}
							data-store-id={storeEdit.store_id}
							handleStoreSelection={() => {
								captureCustomDataLayerEvent('store_details_click', {
									store_id: storeEdit.store_id,
								});
								navigateToStore(storeEdit);
							}}
							isSelected={scrollItem === i}
							isFirstCard={i === 0}
							store={storeEdit}
						/>
					);
				})
			);
		} else {
			setStoresResultsCards();
		}
	}, [isLocaleSpanish, resultFilter, scrollItem]);

	/**
	 * Generate map markers for each store
	 */
	const theme = useTheme();
	useEffect(() => {
		if (resultFilter.length) {
			setMapMarkers(
				resultFilter.map(
					(
						{ store_id, store_lat, store_long, store_name, current_brand, location_type },
						i
					) => {
						if (isMobileKioskOrSatellite(location_type)) return false;

						const active =
							activeMapMarker && store_name === activeMapMarker.getTitle();

						return (
							<Marker
								active={active}
								color={theme.palette.primary.main}
								key={store_id}
								onClick={handleMapMarkerClick}
								position={{ lat: store_lat, lng: store_long }}
								title={store_name}
								markerUrl={getMapMarker(current_brand, active)}
							/>
						);
					}
				)
			);
		} else {
			setMapMarkers([]);
		}
	}, [activeMapMarker, resultFilter]);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	/**
	 * Event handler for when a place is selected in the autocomplete field.
	 * @param {google.maps.places.PlaceGeometry} place
	 */
	const handlePlaceSelect = (place) => {
		if (place && typeof place !== 'string' && place.location) {
			setMapSelectedPlace(place);
			searchStoresByPlace(place);

			captureCustomDataLayerEvent('location_search');
		}
	};

	return (
		<DrivenBox>
			<DrivenContainer>
				<LocationsBreadcrumbs pageSlug={pageSlug}/>
				<DrivenBox
					sx={{
						my: { xs: 3, md: 5 },
						display: 'grid',
						gap: { xs: 0, sm: 3},
						alignItems: 'start',
						gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
						gridTemplateAreas: {
							xs: `
        				'search'
        				'map'
        				'locations'
      				`,
							md: `
        				'search map'
        				'locations map'
      			`,
						},
					}}
				>
					<DrivenBox sx={{ maxWidth: { sm: '100%', md: '600px' }, gridArea: 'search' }} className='locations-search'>
						<DrivenTypography component='h1' variant='h3' sx={{
							fontSize: { xs: 30 },
							mb: 2,
						}}>
							Find Your Local Auto Glass Now®
						</DrivenTypography>
						<DrivenInputWithButton
							apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
							ariaLabel='Search'
							buttonColor='primary'
							buttonText='Search'
							placeholder='Enter City, State or Zip Code'
							withAutocomplete
							buttonVariant='contained'
							spacing={0}
							label='Locations Hero Input Field Button'
							handleSubmit={(searchTerm: SelectedLocation) => {
								setStoredSearchLocation && setStoredSearchLocation('');
								setSearchLocation && setSearchLocation(searchTerm);
								// captureCustomDataLayerEvent('location_search'); // double fire on locations page.
							}}
							value={storedSearchLocation}
							autocompleteTypes={['(regions)']}
						/>
					</DrivenBox>
					<DrivenBox width='100%' sx={{
						gridArea: 'locations',
					}}>
						<DrivenTypography
							component='p'
							variant='body2'
							sx={{ mt: { xs: 3, sm: 0 }}}
						>
							{resultFilter.length}{' '}
							Result
							{resultFilter.length !== 1 ? 's' : ''}{' '}
							Found
						</DrivenTypography>
						{mapSelectedPlace && resultFilter.length ? (
							<DrivenBox>
								<DrivenBox mx={{ xs: -2, sm: 0, md: 0 }}>
									<DrivenScrollableList
										data-testid='locations-search-results'
										listHeight={isMobile ? '2000px' : '560px'}
										scrollToItem={scrollItem}
									>
										{storesResultsCards}
									</DrivenScrollableList>
								</DrivenBox>
							</DrivenBox>
						) : (
							<NoSearchResults
								message='Locate your nearest AGN by entering your location in the search above'
							/>
						)}
					</DrivenBox>
					<DrivenBox sx={{
						gridArea: 'map',
						mx: { xs: -2, sm: 0 },
						mt: { xs: 3, sm: 0 },
					}}>
						<MapWrapper
							apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
							libraries={['places']}
							setStatus={setMapStatus}
						>
							<DrivenBox display='none'>
								<DrivenGoogleAutocomplete
									handleLocationSelect={handlePlaceSelect}
									placeLookup={geolocation}
								/>
							</DrivenBox>
							<DrivenBox>
								<DrivenBox
									height={{ xs: '33vh', sm: '40vh', md: '80vh' }}
									maxHeight={{ md: resultFilter.length > 1 ? '689px' : '600px' }}
								>
									<DrivenMap
										status={mapStatus}
										center={mapCenter}
										location={mapSelectedPlace}
										onClick={(e) => handleMapMarkerClick(e.target)}
									>
										{mapMarkers}
									</DrivenMap>
								</DrivenBox>
							</DrivenBox>
						</MapWrapper>
					</DrivenBox>
				</DrivenBox>
				<CitiesByState
					citiesByState={citiesByState}
					handleClick={setGeolocation}
					isLocaleSpanish={isLocaleSpanish}
				/>
			</DrivenContainer>
			{ctaBanner && <CTABanner {...ctaBanner} />}
		</DrivenBox>
	);
};

export default LocationsPageTemplate;

export const data = graphql`
	query LocationsPageTemplateQuery($id: String, $node_locale: String) {
		ctaBanner: contentfulCtaBanner(
			contentful_id: { eq: "77ygezZ3jthDun8FZxcVJM" }
			node_locale: { eq: $node_locale }
		) {
			...CTABannerFields
		}

		allContentfulLocationContent(
			filter: { node_locale: { eq: $node_locale } }
		) {
			nodes {
				heading
				mobileRepair
				serviceArea {
					childMarkdownRemark {
						rawMarkdownBody
					}
				}
				id
				storeId
				node_locale
			}
		}

		storeLocations: allStoreLocation {
			nodes {
				id
				store_postcode
				store_phone
				organic_phone
				is_active
				path
				store_address
				store_city
				store_id
				store_lat
				store_long
				store_name
				store_state
				sub_brand
				brand
				current_brand
				historic_brand
				geo_address

				hours {
					Monday {
						close
						isOpen
						open
					}
					Tuesday {
						close
						isOpen
						open
					}
					Wednesday {
						close
						isOpen
						open
					}
					Thursday {
						close
						isOpen
						open
					}
					Friday {
						close
						isOpen
						open
					}
					Saturday {
						close
						isOpen
						open
					}
					Sunday {
						close
						isOpen
						open
					}
				}
			}
		}

		page: contentfulPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
			id
			title
			canonicalUrl
			contentful_id
			description
			name
			slug
			blocks {
				__typename
				...HeroFields
			}
		}
	}
`;
