import {
	DrivenIcon,
	DrivenToast,
	DrivenTypography,
	IconTypes,
} from '@drivenbrands/driven-components';
import { addDays, format } from 'date-fns';
import React, {
	Dispatch,
	FC,
	SetStateAction,
	useContext,
	useState,
} from 'react';

import {
	AvailabilityPayload,
	AvailabilityResponse,
	DayAvailable,
	MAINSTREET_URL,
	QuoteResponse,
	useQuoteService,
} from '../../../../services';
import { booleanToNumber } from '../helpers';
import { FormStateContext } from './use-form-state';

type AppointmentContextType = {
	children?: React.ReactNode;
	appointmentsLoading?: boolean;
	// GETTING SHOP SCHEDULE
	getShopSchedule?: (waitForVehicle: boolean, weekToSearch?: string) => void;
	scheduleError?: boolean;
	setScheduleError?: Dispatch<SetStateAction<boolean>>;
	shopSchedule?: DayAvailable[];
	// KEEPING TRACK OF DATES
	startDate?: string;
	endDate?: string;
	waitForVehicle?: boolean;
};

export const AppointmentContext = React.createContext<AppointmentContextType>({
	appointmentsLoading: false,
	getShopSchedule: undefined,
	scheduleError: false,
	setScheduleError: undefined,
	shopSchedule: [],
});

export const AppointmentProvider: FC<AppointmentContextType> = ({
	children,
}) => {
	const { quote, selectedStore } = useContext(FormStateContext);

	const [appointmentsLoading, setAppointmentsLoading] =
		useState<boolean>(false);
	const [scheduleError, setScheduleError] = useState<boolean>(false);
	const [shopSchedule, setShopSchedule] = useState<DayAvailable[]>([]);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [startDate, setStartDate] = useState<string>();
	const [endDate, setEndDate] = useState<string>();
	const [waitForVehicle, setWaitForVehicle] = useState<boolean>();

	const getShopSchedule = async (
		waitingForVehicle: boolean,
		weekToSearch?: string
	) => {
		setAppointmentsLoading(true);
		setScheduleError(false);
		// Mainstreet API get shop appointments
		await useQuoteService({
			url: `${MAINSTREET_URL}appointment`,
			body: {
				QuotePk: quote?.QuotePk,
				ShopId: selectedStore?.ShopId,
				ShopMobile: booleanToNumber(selectedStore?.Mobile),
				WaitForVehicle: booleanToNumber(waitForVehicle),
				Week: weekToSearch ?? format(addDays(new Date(), 1), 'P'),
			} as AvailabilityPayload,
			onError: (error) => {
				setAppointmentsLoading(false);
				setScheduleError(error);
				setShowToast(true);
			},
			onSuccess: (response: QuoteResponse) => {
				const availability = response as AvailabilityResponse;
				setAppointmentsLoading(false);
				if (availability.Success) {
					setScheduleError(false);
					if (availability.Appointment?.Available) {
						// RESETTING IF USER SELECTS DIFFERENT OPTION
						if (waitForVehicle !== waitingForVehicle) {
							setWaitForVehicle(waitingForVehicle);
							setShopSchedule(availability.Appointment?.Available);
						} else if (endDate?.match(availability.Appointment?.EndWeek)) {
							setShopSchedule(availability.Appointment?.Available);
						} else {
							setShopSchedule([
								...shopSchedule,
								...availability.Appointment?.Available,
							]);
						}
					}
					// ONLY WANT TO SET THIS ONCE
					if (!startDate?.length) {
						setStartDate(availability.Appointment?.StartWeek);
					}
					// NEW END DATE CAN BE SET WHEN DATA COMES IN
					setEndDate(availability.Appointment?.EndWeek);
				} else {
					setScheduleError(true);
					setShowToast(true);
				}
			},
		});
	};

	return (
		<AppointmentContext.Provider
			value={{
				appointmentsLoading,
				scheduleError,
				setScheduleError,
				getShopSchedule,
				shopSchedule,
				startDate,
				endDate,
				waitForVehicle,
			}}
		>
			{showToast && (
				<DrivenToast
					open={showToast}
					handleClose={() => setShowToast(false)}
					icon={<DrivenIcon icon={IconTypes.CIRCLE_X_MARK} />}
					severity='error'
					title='Something went wrong.'
				>
					<DrivenTypography>
						We could not retrieve appointment information for your location.
						Please try again or call the store you selected to book an
						appointment.
					</DrivenTypography>
				</DrivenToast>
			)}
			{children}
		</AppointmentContext.Provider>
	);
};
