import {
	DrivenContainer,
	DrivenGrid,
	DrivenStack,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';

type BannerProps = {
	heading: {
		text: string;
		logo?: {
			description?: string;
			publicUrl: string;
			title?: string;
		};
		level: 'h1' | 'h2' | 'h3';
		style?:
			| 'h1'
			| 'h2'
			| 'h3'
			| 'h4'
			| 'h5'
			| 'h6'
			| 'h1Bold'
			| 'h2Bold'
			| 'Subtitle1'
			| 'Subtitle2'
			| 'Subtitle3';
	};
	bodyText?: string;
	fact1Label: string;
	fact1Value?: string;
	fact2Label?: string;
	fact2Value?: string;
	fact3Label?: string;
	fact3Value?: string;
	fact4Label?: string;
	fact4Value?: string;
	fact5Label?: string;
	fact5Value?: string;
	fact6Label?: string;
	fact6Value?: string;
};

export const FactBanner = ({
	heading,
	bodyText,
	fact1Label,
	fact1Value,
	fact2Label,
	fact2Value,
	fact3Label,
	fact3Value,
	fact4Label,
	fact4Value,
	fact5Label,
	fact5Value,
	fact6Label,
	fact6Value,
}: BannerProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const gridItem = (label?: string, value?: string) => (
		<DrivenGrid item xs={6} md={4} sx={{ mt: isMobile ? 0 : 4 }}>
			<DrivenTypography
				variant='h5'
				color='common.white'
				align='center'
				noWrap={!isMobile}
			>
				{label}
			</DrivenTypography>
			<DrivenTypography variant='body1' color='common.white' align='center'>
				{value}
			</DrivenTypography>
		</DrivenGrid>
	);

	return (
		<DrivenContainer maxWidth='lg' sx={{ alignSelf: 'center', paddingTop: 5 }}>
			<DrivenGrid
				container
				rowSpacing={5}
				sx={{
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					mt: 0,
					mb: 3,
				}}
			>
				<DrivenGrid
					item
					xs={12}
					md={4}
					sx={{
						alignItems: 'stretch',
						bgcolor: 'grey.800',
						padding: 6,
					}}
				>
					<DrivenStack spacing={4}>
						<DrivenTypography align='center'>
							<img
								src={heading.logo?.publicUrl}
								alt={heading.logo?.description}
								width='75%'
							/>
						</DrivenTypography>
						<DrivenTypography
							variant='subtitle1'
							color='common.white'
							align='center'
						>
							{heading.text}
						</DrivenTypography>
						<DrivenTypography
							variant='body1'
							color='common.white'
							align='center'
						>
							{bodyText}
						</DrivenTypography>
					</DrivenStack>
				</DrivenGrid>
				<DrivenGrid
					container
					item
					xs={12}
					md={8}
					rowGap={isMobile ? 5 : 0}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						bgcolor: 'common.black',
						padding: isMobile ? 3 : 6,
						alignItems: 'top',
					}}
				>
					{fact1Label && fact1Value && gridItem(fact1Label, fact1Value)}
					{fact2Label && fact2Value && gridItem(fact2Label, fact2Value)}
					{fact3Label && fact3Value && gridItem(fact3Label, fact3Value)}
					{fact4Label && fact4Value && gridItem(fact4Label, fact4Value)}
					{fact5Label && fact5Value && gridItem(fact5Label, fact5Value)}
					{fact6Label && fact6Value && gridItem(fact6Label, fact6Value)}
				</DrivenGrid>
			</DrivenGrid>
		</DrivenContainer>
	);
};

export default FactBanner;

export const query = graphql`
	fragment FactBannerFields on ContentfulFactBanner {
		__typename
		id
		heading {
			text
			logo {
				id
				description
				gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
				publicUrl
				placeholderUrl
			}
			level
			style
		}
		bodyText: body
		fact1Label
		fact1Value
		fact2Label
		fact2Value
		fact3Label
		fact3Value
		fact4Label
		fact4Value
		fact5Label
		fact5Value
		fact6Label
		fact6Value
	}
`;
