import { isActive } from '@drivenbrands/driven-components';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { forwardRef, useContext } from 'react';

import { LocaleContext } from '../contexts';
import { captureCustomDataLayerEvent } from '../services';

export type CustomReachLinkProps = Omit<GatsbyLinkProps<unknown>, 'ref'> & {
	active?: boolean;
};

export const GatsbyLinkBehavior = forwardRef<
	HTMLAnchorElement,
	Omit<CustomReachLinkProps, 'to'> & { href: CustomReachLinkProps['to'] }
>(({ className = '', href, ...props }, ref) => {
	const { generateNewUrlWithLocale, locale } = useContext(LocaleContext);
	const newHref = generateNewUrlWithLocale(href, locale);

	// Gatsby Link should not be used for external links
	// @source: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#use-link-only-for-internal-links
	if (href && href.indexOf('http' || 'https') !== -1) {
		return (
			<a
				className={className}
				href={href}
				ref={ref}
				rel='noreferrer'
				target='_blank'
				title={`External link to ${href}`}
				// Note: onMouseUp because onClick is blocked by default browser behavior
				onMouseUp={() => {
					// If link is for directions to a store
					if (href.indexOf('https://www.google.com/maps') === 0) {
						captureCustomDataLayerEvent('directions_click');
					}
				}}
				{...props}
			>
				{props.children}
			</a>
		);
	}
	if (href && href.indexOf('tel:') !== -1) {
		return (
			<a
				className={className}
				ref={ref}
				href={href}
				title={`Phone number ${href}`}
				// Note: onMouseUp because onClick is blocked by default browser behavior
				onMouseUp={() => {
					// This seems to work on for links and not buttons.
					captureCustomDataLayerEvent('phone_number_click');
				}}
				{...props}
			>
				{props.children}
			</a>
		);
	}
	if (href && href.indexOf('mailto:') !== -1) {
		return (
			<a
				className={className}
				ref={ref}
				href={href}
				title={`Email ${href}`}
				{...props}
			>
				{props.children}
			</a>
		);
	}
	// ONLY NEEDED BECAUSE GATSBY LINK DOES NOT SUPPORT OPENING IN NEW TABS
	if (props.target && props.target === '_blank') {
		return (
			<a
				className={className}
				href={newHref}
				ref={ref}
				rel='noreferrer'
				target='_blank'
				title={`Link to ${newHref}`}
				{...props}
			>
				{props.children}
			</a>
		);
	}
	return (
		<GatsbyLink
			innerRef={ref}
			to={newHref}
			title={`Link to ${newHref}`}
			getProps={({ isCurrent, isPartiallyCurrent }) =>
				isActive(className, isCurrent, isPartiallyCurrent)
			}
			{...props}
		/>
	);
});
GatsbyLinkBehavior.displayName = 'GatsbyLink';
