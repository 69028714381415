import {
	CardContentAlignment,
	DrivenIconCard,
	DrivenTextCard,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import * as React from 'react';

type BasicSelectCardProps = {
	onSelect: (position: string) => void;
	isSelected: boolean;
	title: string;
};

type SelectionCardProps = {
	icon: IconTypes;
	isSelected: boolean;
	title: string;
	onClick: () => void;
};

export const BasicSelectionCard = ({
	onSelect,
	isSelected,
	title,
}: BasicSelectCardProps) => (
	<DrivenTextCard
		cardHeaderProps={{
			sx: {
				color: isSelected ? 'primary.main' : 'common.black',
			},

			titleTypographyProps: {
				variant: 'subtitle2',
			},
		}}
		clickable
		onCardClick={() => onSelect(title)}
		selected={isSelected}
		title={title}
		variant='outlined'
		sx={{
			'& .MuiButtonBase-root > .MuiBox-root': {
				p: 1,
				pt: 2,
			},
		}}
	/>
);

export const SelectionCard = ({
	icon,
	isSelected,
	title,
	onClick,
}: SelectionCardProps) => {
	return (
		<DrivenIconCard
			cardHeaderProps={{
				sx: {
					color: isSelected ? 'primary.main' : 'common.black',
					mb: 1,

					'& i': {
						color: isSelected ? 'primary.main' : 'grey.500',
					},
				},

				titleTypographyProps: {
					variant: 'subtitle2',
				},
			}}
			contentAlignment={CardContentAlignment.CENTER}
			selected={isSelected}
			icon={icon}
			iconSize={IconSizes.XL}
			maxWidth='100%'
			onClick={onClick}
			title={title}
			variant='outlined'
		/>
	);
};
