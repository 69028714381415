type Store = { [key: string]: string };

type DayHours = {
	open: string;
	close: string;
	isOpen: boolean;
};

export type StoreHours = { [day: string]: DayHours };

export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getStructuredStoreHours(store: Store): StoreHours {
	return DAYS_OF_WEEK.reduce((acc, day) => {
		const open = store[`${day} Open`] || 'Closed';
		const close = store[`${day} Close`] || 'Closed';

		acc[day] = {
			open,
			close,
			isOpen: open !== 'Closed' && close !== 'Closed',
		};

		return acc;
	}, {} as StoreHours);
}
