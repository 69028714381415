import {
	CardContentAlignment,
	DrivenContentCard,
	DrivenIcon,
	DrivenIconCard,
	DrivenLink,
	IconSizes,
	DrivenStack,
	IconTypes,
	DrivenTypography,
} from '@drivenbrands/driven-components';

import { captureCustomDataLayerEvent } from '../../../../services';
import { GatsbyLinkBehavior } from '../../../gatsby-link-behavior';
import { BodyText } from './';

const LINK_TEXT = 'Learn More';
const iconCardStyleProps = {
	sx: {
		color: 'common.black',

		'& i': {
			color: 'primary.main',
		},
	},

	titleTypographyProps: {
		variant: 'subtitle2',
	},
};

type PaymentCardProp = {
	isReviewPage?: boolean;
};

export const PaymentInfoCard = ({ isReviewPage }: PaymentCardProp) => {
	return (
		<DrivenContentCard
			content={
				<DrivenStack spacing={2} sx={{ pt: 2 }}>
					<BodyText text='Payment will be required in store, at the time of your service. We know that auto glass damages are often unexpected so we make it easy to pay for your service:' />
					<DrivenStack
						spacing={3}
						direction={{ sm: 'column', md: 'row' }}
						alignItems={{ xs: 'center', md: 'inherit' }}
						justifyContent={{ xs: 'space-between', md: 'center' }}
					>
						<DrivenIconCard
							actions={
								<DrivenLink
									href='/insurance-claims-management/'
									icon={<DrivenIcon icon={IconTypes.ARROW_FORWARD} />}
									LinkComponent={GatsbyLinkBehavior}
									target='_blank'
								>
									{LINK_TEXT}
								</DrivenLink>
							}
							contentAlignment={CardContentAlignment.CENTER}
							cardContentProps={{ component: 'div', variant: 'body2' }}
							cardHeaderProps={iconCardStyleProps}
							icon={IconTypes.SHIELD_CHECK}
							iconSize={IconSizes.XL}
							title='Insurance Claim'
							variant='outlined'
						>
							<BodyText text="We'll handle making the insurance claim on your behalf, all you are responsible for is paying your deductible." />
						</DrivenIconCard>
						<DrivenIconCard
							actions={
								<DrivenLink
									href='/buy-now-pay-later/'
									icon={<DrivenIcon icon={IconTypes.ARROW_FORWARD} />}
									LinkComponent={GatsbyLinkBehavior}
									target='_blank'
									onClick={() => {
										if (isReviewPage) {
											captureCustomDataLayerEvent(
												'review_buy_now_pay_later_click'
											);
										}
									}}
								>
									{LINK_TEXT}
								</DrivenLink>
							}
							contentAlignment={CardContentAlignment.CENTER}
							cardContentProps={{ component: 'div', variant: 'body2' }}
							cardHeaderProps={iconCardStyleProps}
							icon={IconTypes.ZIP5}
							iconSize={IconSizes.XL}
							title='Buy Now, Pay Later'
							variant='outlined'
						>
							<BodyText text='You can now get the service you need and pay over time with our Buy Now, Pay Later option!' />
						</DrivenIconCard>
					</DrivenStack>
				</DrivenStack>
			}
			header={
				<DrivenTypography variant='subtitle1'>Payment Options</DrivenTypography>
			}
		/>
	);
};
