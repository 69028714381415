import {
	DrivenAccordion,
	DrivenContentCard,
	DrivenLineItems,
	DrivenStack,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import {
	captureCustomDataLayerEvent,
	QuoteDetails
} from '../../../../services';
import { ButtonPosition } from '../../quote-schedule';
import { PromoValidationContext } from '../contexts';
import { Caption } from './text';

type QuotePriceCardProps = {
	isReviewPage?: boolean;
	quote: QuoteDetails;
};

export const QuotePriceCard = ({
	isReviewPage,
	quote,
}: QuotePriceCardProps) => {
	const { promoCode } = useContext(PromoValidationContext);
	const [expanded, setExpanded] = useState<string[]>([]);

	const {
		Carrier,
		Deductible,
		PromoAmount,
		QuoteAmount,
		QuoteNum,
		RecallAmount,
		ShopId,
	} = quote;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const quoteLineItems = [
		RecallAmount
			? {
					title: (
						<DrivenTypography variant='body2'>Calibration</DrivenTypography>
					),
					description: (
						<DrivenTypography variant='body2'>
							As low as ${RecallAmount}
						</DrivenTypography>
					),
					tooltip: 'This amount is based on your vehicle and its features.',
			  }
			: {},
		Deductible && Carrier
			? {
					title: <DrivenTypography variant='body2'>Carrier*</DrivenTypography>,
					description: (
						<DrivenTypography variant='body2'>
							You Pay ${Deductible}
						</DrivenTypography>
					),
					highlightColor: 'success',
			  }
			: {},
		promoCode && PromoAmount > 0
			? {
					title: (
						<DrivenTypography variant='body2'>
							Promo: {promoCode}**
						</DrivenTypography>
					),
					description: (
						<DrivenTypography variant='body2'>-${PromoAmount}</DrivenTypography>
					),
					highlightColor: 'success',
			  }
			: {},
	].filter((item) => item.title);

	const handleAccordionToggle =
		(panelTriggered: string) =>
		(event: React.SyntheticEvent, isExpanded: boolean) => {
			if (expanded.includes(panelTriggered)) {
				setExpanded(expanded.filter((panel) => panel !== panelTriggered));
			} else {
				setExpanded([...expanded, panelTriggered]);
			}
		};

	const triggerAnalyticEvent = useCallback(
		(buttonPosition: ButtonPosition) => {
			captureCustomDataLayerEvent('quote_submit', {
				oet_quote_id: QuoteNum,
				schedule_button_position: buttonPosition,
				store_id: ShopId,
				oet_quote_value: QuoteAmount,
			});
		},
		[quote]
	);
	const roundedAmount = Math.round(QuoteAmount);
	const formatQuote = (QuoteAmount: number) =>
		QuoteAmount > 50
			? `$${QuoteAmount - 50} - $${QuoteAmount}*`
			: `$${QuoteAmount}`;
	return (
		<DrivenContentCard
			content={
				<DrivenStack
					sx={{
						pt: 2,

						'& .MuiList-root': {
							p: 0,

							'& > .MuiListItem-root': {
								my: 0,
								mb: 1,
							},
						},
					}}
					spacing={2}
				>
					{/* <BodyText text='Here’s your personalized quote based on the provided information:' /> */}
					<DrivenTypography style={isMobile ? {} : { display: 'flex' }}>
						<DrivenTypography>
							<DrivenTypography
								style={{ display: isMobile ? 'block' : 'flex' }}
							>
								<DrivenTypography
									style={
										isMobile
											? { textAlign: 'center' }
											: {
													width: '260px',
													paddingRight: '15px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'start',
											  }
									}
									variant='body2'
								>
									Pay as little as
								</DrivenTypography>
								<DrivenTypography
									style={
										isMobile
											? { textAlign: 'center', padding: '8px 0' }
											: { width: 'calc(100% + 80px)' }
									}
									color='success.main'
									variant='h4'
								>
									{formatQuote(roundedAmount)}
								</DrivenTypography>
							</DrivenTypography>
							<DrivenTypography
								style={
									isMobile
										? {
												alignItems: 'center',
												justifyContent: 'center',
												display: 'flex',
												paddingTop: '10px',
										  }
										: { display: 'flex', paddingTop: '10px' }
								}
							>
								<span>Pay as little as&nbsp;</span>
								<p style={{ margin: 0, color: '#457A3B' }}>
									$0 with insurance.**
								</p>
							</DrivenTypography>
							<DrivenTypography
								style={
									isMobile
										? { textAlign: 'center', padding: '10px 0' }
										: {
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												padding: '15px 0',
										  }
								}
								variant='subtitle2'
								color='grey.600'
							>
								Quote Number: {QuoteNum}
							</DrivenTypography>
						</DrivenTypography>
						{/* <DrivenTypography  style={{ textAlign: 'center' }}variant='subtitle2' color='grey.600'>
							Quote Number: {QuoteNum}
						</DrivenTypography> */}
						<DrivenTypography variant='body2'>
							<DrivenTypography>
								• Use any promo codes you have instore for additional discounts.
							</DrivenTypography>
							<DrivenTypography>
								• Wiper blades, calibrations and other services not included in
								estimate.
							</DrivenTypography>
							<DrivenTypography
								style={
									isMobile
										? { textAlign: 'center', padding: '20px 0' }
										: { textAlign: 'start', padding: '20px 0' }
								}
								variant='body1'
							>
								{isMobile ? (
									<a
										href='https://www.autoglassnow.com/buy-now-pay-later/'
										target='_blank'
										rel='noopener noreferrer'
										style={{ color: 'black' }}
									>
										Learn about buy now, <br></br>
										pay later options.
									</a>
								) : (
									<a
										href='https://www.autoglassnow.com/buy-now-pay-later/'
										target='_blank'
										rel='noopener noreferrer'
										style={{ color: 'black' }}
									>
										Learn about buy now, pay later options.
									</a>
								)}
							</DrivenTypography>
						</DrivenTypography>
					</DrivenTypography>
					{quoteLineItems.length > 0 && (
						<DrivenAccordion
							handleChange={handleAccordionToggle}
							panelsOpen={expanded}
							records={[
								{
									summary: 'Quote Breakdown',
									details: (
										<DrivenStack>
											<DrivenLineItems items={quoteLineItems} />
											<DrivenStack spacing={2}>
												{promoCode && PromoAmount > 0 && (
													<Caption text='**Discount applied to windshield replacement part only; labor and fees not included. Valid for retail transactions only. Cannot be combined with insurance.' />
												)}
												{Carrier && Deductible > 0 && (
													<Caption text='**The deductible amount you provide will be confirmed after you schedule your appointment and is not final until you receive a final payment amount at the time of your visit. Cannot be combined with promotions or discounts.' />
												)}
												<Divider
													variant='fullWidth'
													sx={{ color: 'grey.400' }}
												/>
												<DrivenStack
													direction='row'
													justifyContent='space-between'
												>
													<DrivenTypography>Quote Estimate</DrivenTypography>
													<DrivenTypography>
														As low as {formatQuote(roundedAmount)}
													</DrivenTypography>
												</DrivenStack>
											</DrivenStack>
										</DrivenStack>
									),
								},
							]}
							plain
						/>
					)}
					<DrivenTypography variant='caption' style={{ padding: '6px 0' }}>
						*This is an estimate and does not include tax or other fees. This
						estimate is based on the information you provided and is subject to
						change after our auto glass experts assess the damage.
					</DrivenTypography>
					<DrivenTypography
						style={{ margin: '0', paddingTop: '6px' }}
						variant='caption'
					>
						**Deductibles vary by insurance provider. See store for full
						details.
					</DrivenTypography>
					{/* {!isReviewPage && (
						<DrivenButton
							type='submit'
							onClick={() => triggerAnalyticEvent(ButtonPosition.TOP)}
							sx={{ mt: 1 }}
						>
							{BUTTON_TEXT}
						</DrivenButton>
					)} */}
				</DrivenStack>
			}
			header={
				<DrivenTypography component='h1' variant='subtitle1'>
					Here's your Estimate!
				</DrivenTypography>
			}
			// headerSecondary={
			// 	<DrivenTypography variant='subtitle2' color='grey.600'>
			// 		Quote Number: {QuoteNum}
			// 	</DrivenTypography>
			// }
		/>
	);
};
