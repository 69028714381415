import {
	BaseContentGrid,
	DrivenCardCarousel,
	DrivenContainer,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { gridClasses, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, ReactNode } from 'react';

import { graphql } from 'gatsby';
import { ComponentMatch } from '../../hooks';

interface CardGroupProps {
	items: [any];
	displayCountDesktop: number;
	displayCountMobile: number;
	displayCountTablet: number;
	title?: string;
}

export const CardGroup = ({
	items,
	displayCountDesktop = 5,
	displayCountMobile = 2,
	title,
}: CardGroupProps) => {
	const defaultTheme = useTheme();
	const isDesktop = useMediaQuery(defaultTheme.breakpoints.up('md'));
	// TODO: revert once Contentful model changes are complete
	const cardComponents = items?.some((card) => card.__typename)
		? ComponentMatch(items)
		: items;

	if (!items?.length || !cardComponents?.length) {
		return null;
	}

	function* baseGridMaxRowDisplay(cards: Array<ReactNode>, maxDisplay: number) {
		if (cards?.length && maxDisplay) {
			for (let i = 0; i < cards?.length; i += maxDisplay) {
				yield cards.slice(i, i + maxDisplay);
			}
		} else {
			console.warn('Missing cards or maxDisplay values');
		}
	}

	return (
		<DrivenContainer>
			{title && (
				<DrivenTypography variant='h3' my={3}>
					{title}
				</DrivenTypography>
			)}
			{isDesktop || displayCountDesktop === displayCountMobile ? (
				[...baseGridMaxRowDisplay(cardComponents, displayCountDesktop)].map(
					(each, rowIndex: number) => (
						<BaseContentGrid
							key={`card-row-${rowIndex}`}
							sx={{
								mt: { xs: 0 },
								[`> .${gridClasses.item}`]: {
									pt: { xs: 0 },
									flexGrow: 1,
								},
							}}
						>
							{each.map((card: ReactNode, cardIndex: number) => (
								<Fragment key={`card-${rowIndex}-${cardIndex}`}>
									{card}
								</Fragment>
							))}
						</BaseContentGrid>
					)
				)
			) : (
				<DrivenCardCarousel cards={cardComponents} />
			)}
		</DrivenContainer>
	);
};

export const CardGroupQuery = graphql`
	fragment CardGroupFields on ContentfulCardGroup {
		__typename
		id
		name
		displayCountDesktop
		displayCountMobile
		displayCountTablet
		items: iconcards {
			...IconCardFields

			...PromotionCardFields

			...TextCardFields
		}
	}
`;
