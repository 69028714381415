import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useEffect,
	useState
} from 'react';

import {
	AppointmentPayload,
	CustomerPayload,
	DamageType,
	GlassType,
	InsurancePayload,
	QuoteDetails,
	Store,
	StyleItem,
	VehicleResponse
} from '../../../../services';
import { DropdownOption } from '../types';

type FormStateContextType = {
	// GENERAL STATE
	children?: ReactNode;
	LeadId?: number;
	loading?: boolean;
	// STEP 2 STATE - CUSTOMER INFO
	customerInfo?: CustomerPayload;
	setCustomerInfo?: Dispatch<SetStateAction<CustomerPayload | undefined>>;
	customerZip?: string;
	setCustomerZip?: Dispatch<SetStateAction<string>>;
	// STEP 3 STATE - CONFIRM STORE
	selectedStore?: Store;
	setSelectedStore?: Dispatch<SetStateAction<Store | undefined>>;
	// STEP 4 STATE - VEHICLE ENTRY
	licenseError?: string;
	setLicenseError?: Dispatch<SetStateAction<string>>;
	makeOptions?: DropdownOption[];
	setMakeOptions?: Dispatch<SetStateAction<DropdownOption[] | undefined>>;
	modelOptions?: DropdownOption[];
	setModelOptions?: Dispatch<SetStateAction<DropdownOption[] | undefined>>;
	styleOptions?: DropdownOption[];
	setStyleOptions?: Dispatch<SetStateAction<DropdownOption[] | undefined>>;
	styleResponse?: StyleItem[];
	setStyleResponse?: Dispatch<SetStateAction<StyleItem[] | undefined>>;
	vehicleInformation?: Partial<VehicleResponse['Vehicle']>;
	vehicleMake?: string;
	setVehicleMake?: Dispatch<SetStateAction<string>>;
	vehicleModel?: string;
	setVehicleModel?: Dispatch<SetStateAction<string>>;
	vehicleStyle?: string;
	setVehicleStyle?: Dispatch<SetStateAction<string>>;
	vehicleState?: string;
	setVehicleState?: Dispatch<SetStateAction<string | undefined>>;
	vehicleYear?: number;
	setVehicleYear?: Dispatch<SetStateAction<number | undefined>>;
	vinError?: string;
	setVinError?: Dispatch<SetStateAction<string>>;
	vinSubmitted?: boolean; // ALSO USED IN STEP 6
	// STEP 5 STATE - DAMAGE ASSESSMENT
	damageTypes?: DamageType[];
	setDamageTypes?: Dispatch<SetStateAction<DamageType[]>>;
	glassOptions?: GlassType[];
	// STEP 6 STATE - ADDITIONAL QUESTIONS
	additionalQuestions?: string[];
	setAdditionalQuestions?: Dispatch<SetStateAction<string[]>>;
	answers?: string[];
	setAnswers?: Dispatch<SetStateAction<string[] | undefined>>;
	// STEP 7 STATE - INSURANCE
	selectedInsurance?: string;
	setSelectedInsurance?: Dispatch<SetStateAction<string | undefined>>;
	
	insuranceInfo?: InsurancePayload;
	setInsuranceInfo?: Dispatch<SetStateAction<InsurancePayload | undefined>>;
	// STEP 8 STATE - QUOTE DETAILS
	quote?: QuoteDetails;
	// STEP 9 & 10
	appointment?: AppointmentPayload;
};

export const FormStateContext = createContext<FormStateContextType>({
	additionalQuestions: undefined,
	glassOptions: undefined,
	LeadId: undefined,
	licenseError: undefined,
	loading: false,
	insuranceInfo: undefined,
	quote: undefined,
	vehicleInformation: undefined,
	vinError: undefined,
	vinSubmitted: false,
});
export const FormStateProvider: FC<FormStateContextType> = ({
	additionalQuestions,
	answers,
	appointment,
	children,
	customerInfo,
	damageTypes,
	glassOptions,
	insuranceInfo,
	LeadId,
	licenseError,
	loading,
	quote,
	selectedStore,
	vehicleInformation,
	vinError,
	vinSubmitted,
}) => {
	// STEP 2 STATE
	const [customerZip, setCustomerZip] = useState<string>('');
	const [CustomerInfo, setCustomerInfo] = useState<
		CustomerPayload | undefined
	>();
	// STEP 3 STATE
	const [SelectedStore, setSelectedStore] = useState<Store>();
	// STEP 4 STATE - MOSTLY NEEDED FOR MAINTAINING VALUES WHEN GOING TO PAST PAGES
	const [licenseErrors, setLicenseError] = useState<string>(licenseError ?? '');
	const [vehicleMake, setVehicleMake] = useState<string>('');
	const [makeOptions, setMakeOptions] = useState<DropdownOption[]>();
	const [vehicleModel, setVehicleModel] = useState<string>('');
	const [modelOptions, setModelOptions] = useState<DropdownOption[]>();
	const [vehicleStyle, setVehicleStyle] = useState<string>('');
	const [styleOptions, setStyleOptions] = useState<DropdownOption[]>();
	const [styleResponse, setStyleResponse] = useState<StyleItem[]>(); // For matching carId with style selection
	const [vehicleState, setVehicleState] = useState<string>();
	const [vinErrors, setVinError] = useState<string>(vinError ?? '');
	const [vehicleYear, setVehicleYear] = useState<number>();
	// STEP 5 STATE
	const [DamageTypes, setDamageTypes] = useState<DamageType[]>([]);
	// STEP 6 STATE
	const [AdditionalQuestions, setAdditionalQuestions] = useState<string[]>([]);
	const [Answers, setAnswers] = useState<string[] | undefined>([]);
	// STEP 7 STATE
	const [InsuranceInfo, setInsuranceInfo] = useState<InsurancePayload>();	

	const [selectedInsurance, setSelectedInsurance] = useState<string>();

	useEffect(() => {
		setAdditionalQuestions(additionalQuestions ?? []);
	}, [additionalQuestions]);

	useEffect(() => {
		setCustomerInfo(customerInfo);
	}, [customerInfo]);

	useEffect(() => {
		setSelectedStore(selectedStore);
	}, [selectedStore]);

	useEffect(() => {
		setLicenseError(licenseError ?? '');
	}, [licenseError]);

	useEffect(() => {
		setVinError(vinError ?? '');
	}, [vinError]);

	// MANUALLY SETTING HERE BECAUSE THESE ARE DROPDOWN OPTIONS
	// THEY DO NOT PICK UP DEFAULTS SET BY SCHEMA
	useEffect(() => {
		setVehicleMake(vehicleInformation?.VehMake ?? '');
		setVehicleModel(vehicleInformation?.VehModel ?? '');
		setVehicleStyle(vehicleInformation?.Style ?? '');
		setVehicleYear(vehicleInformation?.VehYear);
	}, [vehicleInformation]);

	useEffect(() => {
		setDamageTypes(damageTypes ?? []);
	}, [damageTypes]);

	useEffect(() => {
		setAnswers(answers);
	}, [answers]);

	useEffect(() => {
		setInsuranceInfo(insuranceInfo);
	}, [insuranceInfo]);

	useEffect(() => {
		setSelectedInsurance(selectedInsurance || "");
	}, [selectedInsurance]);

	return (
		<FormStateContext.Provider
			value={{
				// STEP 2 STATE - CUSTOMER INFO
				customerInfo: CustomerInfo,
				setCustomerInfo,
				customerZip,
				setCustomerZip,
				// STEP 3 STATE - CONFIRM STORE
				selectedStore: SelectedStore,
				setSelectedStore,
				// STEP 4 STATE - VEHICLE ENTRY
				licenseError: licenseErrors,
				setLicenseError,
				makeOptions,
				setMakeOptions,
				modelOptions,
				setModelOptions,
				styleOptions,
				setStyleOptions,
				styleResponse,
				setStyleResponse,
				vehicleInformation,
				vehicleMake,
				setVehicleMake,
				vehicleModel,
				setVehicleModel,
				vehicleStyle,
				setVehicleStyle,
				vehicleState,
				setVehicleState,
				vehicleYear,
				setVehicleYear,
				vinError: vinErrors,
				setVinError,
				vinSubmitted, // ALSO USED IN STEP 6
				// STEP 5 STATE - DAMAGE ASSESSMENT
				damageTypes: DamageTypes,
				setDamageTypes,
				glassOptions,
				// STEP 6 STATE - ADDITIONAL QUESTIONS
				additionalQuestions: AdditionalQuestions,
				setAdditionalQuestions,
				answers: Answers,
				setAnswers,
				// STEP 7 STATE - INSURANCE
				insuranceInfo: InsuranceInfo,
				setInsuranceInfo,

				setSelectedInsurance,
				selectedInsurance: selectedInsurance,

				// STEP 8 STATE - QUOTE
				quote,
				// STEP 9 & 10
				appointment,
				// GENERAL STATE
				LeadId,
				loading,
			}}
		>
			{children}
		</FormStateContext.Provider>
	);
};
