import { useEffect, useRef } from "react"
import { DrivenBox, DrivenTypography } from '@drivenbrands/driven-components';

const FORM_CAMPAIGN_ID  = '66977529f65a715952144595';
const ACCOUNT_ID = '621d12170e5439412805707a';
const WIDGET_ID = '668583c0e3d0155e1c3c8453';

interface CustomerTestimonialsProps {
	locationId: string;
}

export const CustomerTestimonials = ({ locationId }: CustomerTestimonialsProps) => {
	const containerRef = useRef(null);

	// This is required to force the script and iframe to render inline.
	// Without the useEffect the script will load at the bottom of the page (under the footer)
	useEffect(() => {
		const scriptId = WIDGET_ID;
		const scriptChild = document.getElementById(scriptId);

		// Condition needed to ensure the script is not created and loaded more than once
		if (containerRef?.current && !scriptChild) {
			const script = document.createElement('script', {});
			script.src = `https://live.chatmeter.com/surveys/widget/script?widgetId=${WIDGET_ID}&accountId=${ACCOUNT_ID}&formCampaignId=${FORM_CAMPAIGN_ID}&locationId=${locationId}`;
			script.id = scriptId;
			containerRef.current.appendChild(script);
		}
	}, [locationId])

	return locationId && containerRef ? (
		<>
			<DrivenBox sx={{ mt: { xs: 6, md: 8 } }}>
				<DrivenTypography
					variant='h2'
					component='h2'
					mb={{ xs: 2, sm: 4 }}
					textAlign='center'
				>
					Auto Glass Now Customer Reviews
				</DrivenTypography>
				<div id="store-testimonials" ref={containerRef} />
			</DrivenBox></>
	) : null
}
