import {
	DrivenBox,
	DrivenContainer,
	DrivenDropdown,
	DrivenGrid,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import { SelectChangeEvent } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LocaleContext } from '../../../contexts';
import { PageContextProps } from '../../../templates/default-page.template';
import BlogCard from './blog-card';
import BlogFeatured from './blog-featured';
import { Pagination } from './pagination';

export type SeoBlog = {
	name?: string;
	title?: string;
	description?: string;
	noindex?: boolean;
	nofollow?: boolean;
	canonicalUrl?: string;
	scriptSrc?: string;
	googleTagManager?: string;
};

export type BlogPost = {
	id: string;
	contentful_id: string;
	node_locale: string;
	title: string;
	slug: string;
	path: string;
	isFeatured: boolean;
	publishDate: string;
	createdAt: string;
	updatedAt: string;
	excerpt: {
		excerpt: string;
		childMarkdownRemark: {
			rawMarkdownBody: string;
			html: string;
		};
	};
	bodyMarkdown?: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody: string;
		};
	};
	featuredImage?: {
		gatsbyImageData: IGatsbyImageData;
		title: string;
		description?: string;
	};
	mainLogo?: string;
	seo?: SeoBlog;
};

type ContentfulNewsSearchProps = {
	id: string;
	contentful_id: string;
	name: string;
	pageContext: PageContextProps;
};
// some of this was based on the Drivenbrands blog. Naming should be updated maybe?
// the news-search model is described as "Grid of all news articles with search features"
// on contentful so thats why I used this to start.

export const ContentfulNewsSearch = ({
	pageContext,
}: ContentfulNewsSearchProps) => {
	// TODO: Filter on tags? JB:7/23 not yet added on the contentful side
	// const [filterOptions, setFilterOptions] = useState('');

	const { currentPage, postsPerPage, totalBlogPages } = pageContext;
	const [sortOrder, setSortOrder] = useState(localStorage.getItem('SORT_ORDER') || 'DESC');
	// TODO: Sort newest/oldest JB:7/23
	const handleSortChange = (event: SelectChangeEvent<unknown>) => {
		setSortOrder(event.target.value as string);
	};
	const { locale } = useContext(LocaleContext);
	// TODO: Filter on locales JB:7/23

	useEffect(() => {
		localStorage.setItem('SORT_ORDER', sortOrder)
	}, [sortOrder])

	const data = useStaticQuery(graphql`
		query {
			featured: allContentfulBlogPost(
				filter: { isFeatured: { eq: true }, node_locale: { eq: "en-US" } }
				limit: 1
			) {
				nodes {
					id
					contentful_id
					node_locale
					title
					slug
					path
					isFeatured
					publishDate(formatString: "MMMM DD, YYYY")
					excerpt {
						excerpt
						childMarkdownRemark {
							rawMarkdownBody
							html
						}
					}
					featuredImage {
						gatsbyImageData(layout: FULL_WIDTH, quality: 90)
						title
						description
					}
				}
			}

			allContentfulBlogPost(filter: { node_locale: { eq: "en-US" } }, sort: { publishDate: DESC }) {
				nodes {
					id
					contentful_id
					node_locale
					title
					slug
					path
					isFeatured
					publishDate(formatString: "MMMM DD, YYYY")
					excerpt {
						excerpt
						childMarkdownRemark {
							rawMarkdownBody
							html
						}
					}
					featuredImage {
						gatsbyImageData(placeholder: BLURRED, quality: 90, width: 600)

						title
						description
					}
				}
			}
		}
	`);

	const featured: BlogPost = data.featured?.nodes[0];

	const sortedPosts = useMemo(() => {
		if (sortOrder === 'ASC') {
			return [...data.allContentfulBlogPost.nodes].sort((a, b) => {
				const dateA = new Date(a.publishDate).getTime();
				const dateB = new Date(b.publishDate).getTime();
				return dateA - dateB;
			});
		}
		return data.allContentfulBlogPost.nodes;
	}, [sortOrder, data.allContentfulBlogPost.nodes]);

	const currentPageOrDefault = currentPage || 1;
	const postsPerPageOrDefault = postsPerPage || 10;
	const startIndex = (currentPageOrDefault - 1) * postsPerPageOrDefault;
	const endIndex = Math.min(
		startIndex + postsPerPageOrDefault,
		sortedPosts.length
	);
	const postsToShow = sortedPosts.slice(startIndex, endIndex);

	return (
		<DrivenContainer>
			<DrivenBox>
				{/* featured post. This is not final layout. update from figma */}
				{featured && <BlogFeatured {...featured} />}

				<DrivenBox
					display='grid'
					gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}
					columnGap={3}
					rowGap={2}
					mb={3}
				>
					{/* LM: 1/5/24 temp turned off per biz request until we have enough content that it makes sense to use*}
					{/* <DrivenBox>
						<DrivenDropdown
							label='Filter'
							options={[
								{
									value: 'all',
									option: 'All',
									icon: IconTypes.FILTER,
									iconSize: IconSizes.MD,
								},
								{
									value: 'tag1',
									option: 'Tag 1',
									icon: IconTypes.FILTER,
									iconSize: IconSizes.MD,
								},
								{
									value: 'tag2',
									option: 'Tag 2',
									icon: IconTypes.FILTER,
									iconSize: IconSizes.MD,
								},
							]}
							value='all'
							// onChange={(event) => handleFilterChange(event)}
							sx={{
								// Match search input height to the select dropdown
								'*': {
									boxSizing: 'border-box',
								},
							}}
						/>
					</DrivenBox> */}
					<DrivenBox>
						<DrivenDropdown
							label='Sort'
							options={[
								{
									value: 'DESC',
									option: 'Newest',
									icon: IconTypes.ARROW_DOWN_UP,
									iconSize: IconSizes.MD,
								},
								{
									value: 'ASC',
									option: 'Oldest',
									icon: IconTypes.ARROW_DOWN_UP,
									iconSize: IconSizes.MD,
								},
							]}
							value={sortOrder}
							onChange={(event) => handleSortChange(event)}
							sx={{
								// Match search input height to the select dropdown
								'*': {
									boxSizing: 'border-box',
								},
							}}
						/>
					</DrivenBox>
				</DrivenBox>

				<DrivenGrid
					columns={1}
					display={{ md: 'flex' }}
					container
					direction='column'
					alignItems={{ md: 'stretch' }}
				>
					{postsToShow.map((post: BlogPost, index: number) => (
						<BlogCard
							index={index}
							blogPost={post}
							key={`latest-articles-grid-${post.id}`}
						/>
					))}
				</DrivenGrid>
			</DrivenBox>
			<DrivenBox sx={{ display: 'flex', justifyContent: 'end' }}>
				<Pagination
					currentPage={currentPage || 1}
					totalBlogPages={totalBlogPages || 1}
				/>
			</DrivenBox>
		</DrivenContainer>
	);
};

export default ContentfulNewsSearch;
