import {
	CustomTypography,
	NavigationItem,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { captureCustomDataLayerEvent } from '../services';

// TODO: Cleanup and refactor to match the contentful model
interface GtmFields {
	gtmEventName?: string;
	gtmAdditionalFields?: {
		internal: {
			content: string;
		};
	};
}

interface PageLink extends GtmFields {
	name: string;
	page: {
		path: string;
		slug: string;
	};
	text: string;
}

interface SocialLink extends GtmFields {
	name: string;
	link: string;
	externalLink: boolean;
	icon: string;
}

type ContentfulNavigationItems = {
	displayText: string;
	alternateDisplayText?: string;
	secondaryDisplayText?: string;
	link?: {
		name: string;
		path: string;
	} & GtmFields; // Incorporate GtmFields here
	linkType?: 'Link' | 'Button' | 'customButton';
	submenu?: (PageLink | SocialLink | GtmFields)[];
};

type ButtonTypography = {
	buttonTypography: CustomTypography;
};

function isPageLink(
	toBeDetermined: PageLink | SocialLink
): toBeDetermined is PageLink {
	if ((toBeDetermined as PageLink).page) {
		return true;
	}
	return false;
}
const formatMenuLinks = (
	menuItem: PageLink | SocialLink,
	optionalParameter?: any
) => {
	let submenuItemCallback;
	if ('gtmEventName' in menuItem) {
		submenuItemCallback = {
			callback: () => {
				captureCustomDataLayerEvent(menuItem.gtmEventName || '', {
					...JSON.parse(menuItem.gtmAdditionalFields?.internal.content || '{}'),
					...optionalParameter,
				});
			},
		};
	}
	// note, additional logic has been added here and in contentful to allow menu items
	// accept Links with a gtm event.
	if ('page' in menuItem) {
		return {
			text: menuItem.text,
			href: menuItem.page.path ?? '',
			...(submenuItemCallback && { callback: submenuItemCallback.callback }),
		};
	}
	return {
		text: menuItem.icon ? menuItem.icon : menuItem.name,
		href: menuItem.link ?? menuItem.path ?? '',
		external: menuItem.externalLink,
		...(submenuItemCallback && { callback: submenuItemCallback.callback }),
	};
};

export const formatNavigationLinks = (
	navigationItems: ContentfulNavigationItems[],
	storeId?: string
): NavigationItem[] => {
	const optionalParameter = storeId ? { store_id: storeId } : {};
	// TODO - deal with type issue
	return navigationItems.map((item) => {
		const buttonTypography: ButtonTypography | undefined =
			item.linkType === 'Button' || item.linkType === 'customButton'
				? { buttonTypography: 'smallButton1' }
				: undefined;

		const theme = useTheme();
		const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
		// FIXME: if item has gtmEventName, but no gtmAdditionalFields, this will throw an error. Make optional
		// Create single gtm object to use in links and buttons.
		const callback =
			item.link && item.link.gtmEventName
				? {
						callback: () => {
							captureCustomDataLayerEvent(item.link?.gtmEventName ?? '', {
								...JSON.parse(item.link?.gtmAdditionalFields?.internal.content),
								...optionalParameter,
							});
						},
				  }
				: undefined;
		const submenu = item.submenu
			? {
					submenu: item.submenu?.map((menuItem) => formatMenuLinks(menuItem)),
			  }
			: undefined;

		const isButton =
			item.linkType === 'Button' || item.linkType === 'customButton';

		const type = item.linkType === 'Button' ? { type: 'button' } : undefined;
		const customButton =
			item.linkType === 'customButton'
				? {
						type: 'customButton',
						customAttributesDesktop: {
							alternateDisplayText: item.alternateDisplayText,
							alternateDisplayTextColor: 'white',
							secondaryTextColor: 'secondary.main',
							secondaryText: item.secondaryDisplayText,
							buttonClass: 'text',
							backgroundColor: 'black',
							hoverColor: 'black',
						},
				  }
				: undefined;

		// A slightly one off solution for switching the hamburger menu text on tablet and mobile
		// to use the alternateDisplayText on those devices.
		const responsiveDisplayText = isTablet
			? item.alternateDisplayText
			: item.displayText;

		return {
			...customButton,
			...buttonTypography,
			href: item.link?.path ?? '',
			...submenu,
			text: isButton ? responsiveDisplayText?.toUpperCase() : item.displayText, // Transform the text to uppercase if it's a button - global change for nav buttons
			...type,
			...callback,
		};
	});
};
