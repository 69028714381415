import {
	DrivenBox,
	DrivenButton,
	DrivenIcon,
	DrivenTypography,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { captureCustomDataLayerEvent } from '../../../services/data-layer-event';
import ContentfulImage from '../../contentful-image';
import { GatsbyLinkBehavior } from '../../gatsby-link-behavior';
import { BlogPost } from './news-search';

export interface BlogCardProps {
	index: number;
	blogPost: BlogPost;
	dataLayerEventName?: string;
}

export function BlogCard({
	index,
	blogPost,
	dataLayerEventName,
}: BlogCardProps) {
	const theme = useTheme();
	const { title, publishDate, featuredImage, excerpt, path } = blogPost;
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<DrivenBox
			component='article'
			display='flex'
			flexDirection={{
				xs: 'column',
				md: index % 2 === 0 ? 'row' : 'row-reverse',
			}}
			width='100%'
			marginX={0}
			marginY={3}
			padding={0}
			paddingBottom={1}
			paddingRight={10}
			sx={{
				a: {
					textDecoration: 'none',
					color: theme.palette.common.black,
				},
			}}
		>
			<DrivenBox
				width={{ xs: '100%', md: '50%' }}
				display='flex'
				sx={{
					marginRight: index % 2 === 0 && !isTablet ? '30px' : '0px',
					marginLeft: index % 2 !== 0 && !isTablet ? '30px' : '0px',
					height: 'auto',
				}}
			>
				{featuredImage && (
					<ContentfulImage
						image={{
							gatsbyImageData: featuredImage.gatsbyImageData,
							description: featuredImage.description || title,
							title,
						}}
						imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
						loading='lazy'
					/>
				)}
			</DrivenBox>
			<DrivenBox
				display='flex'
				flexDirection='column'
				justifyContent={{ xs: 'flex-start', sm: 'center' }}
				mt={{ xs: 1, sm: 0 }}
				width={{ xs: '100%', md: '50%' }}
			>
				<DrivenTypography variant='body2' component='p' mb={0.5}>
					<time dateTime={publishDate}>{publishDate}</time>
				</DrivenTypography>
				<DrivenTypography variant='h3' component='h3'>
					{title}
				</DrivenTypography>
				<DrivenTypography variant='body1' component='p' my={2}>
					{excerpt.excerpt}
				</DrivenTypography>

				<DrivenButton
					aria-label={title}
					color='primary'
					sx={{
						borderRadius: 24,
						width: { xs: '100%', md: 187 },
						height: '48px',
					}}
					LinkComponent={GatsbyLinkBehavior}
					onClick={() =>
						captureCustomDataLayerEvent('blog_article_click', {
							article_title: title,
							article_url: path,
						})
					}
					href={path}
				>
					<DrivenBox
						display='flex'
						alignItems='center'
						justifyContent='space-between'
					>
						<DrivenTypography>READ MORE </DrivenTypography>
						<DrivenBox mr={1} />
						<DrivenIcon icon={IconTypes.ARROW_FORWARD} size={IconSizes.MD} />
					</DrivenBox>
				</DrivenButton>
			</DrivenBox>
		</DrivenBox>
	);
}
export default BlogCard;
