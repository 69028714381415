import {
	DrivenBox,
	DrivenIcon,
	DrivenLink,
	DrivenTextCard,
} from '@drivenbrands/driven-components';

import { graphql } from 'gatsby';
import { GatsbyLinkBehavior as GatsbyLink } from '../gatsby-link-behavior';
import MarkdownContent from '../mdx/markdown-content';

export const TextCard = ({
	contentAlignment,
	title,
	isDarkMode,
	body,
	borderBottom,
	textCardLink,
}: any) => {
	return (
		<DrivenTextCard
			actions={
				textCardLink && (
					<DrivenBox
						sx={{
							'& .MuiBox-root': {
								backgroundColor: isDarkMode ? 'inherit' : 'common.white',
							},
						}}
					>
						<DrivenLink
							fontWeight={700}
							LinkComponent={GatsbyLink}
							href={textCardLink.page.slug}
							icon={<DrivenIcon icon={textCardLink.icon} />}
						>
							{textCardLink.text}
						</DrivenLink>
					</DrivenBox>
				)
			}
			borderBottom={borderBottom}
			cardContentProps={{
				component: 'div',
				variant: 'body2',
			}}
			cardHeaderProps={{
				variant: 'h6',
				sx: {
					textAlign: 'center',

					'& .MuiTypography-root': {
						textTransform: 'none',
					},
				},
			}}
			contentAlignment={contentAlignment}
			title={title}
			maxWidth='100%'
			sx={{
				backgroundColor: isDarkMode ? 'inherit' : 'common.white',
				'& > .MuiBox-root:first-of-type': {
					backgroundColor: 'inherit',
					height: '100%',
				},
				// BORDER STYLES
				'& > .MuiBox-root:last-of-type': {
					backgroundColor: 'primary.main',
					bottom: 0,
					position: 'absolute',
				},
			}}
		>
			<MarkdownContent>
				{body?.childMarkdownRemark.rawMarkdownBody}
			</MarkdownContent>
		</DrivenTextCard>
	);
};

export const TextCardQuery = graphql`
	fragment TextCardFields on ContentfulTextCard {
		__typename
		id
		body {
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
		borderBottom
		contentAlignment
		isDarkMode
		name
		title
		textCardLink {
			text
			icon
			page {
				slug
			}
		}
	}
`;
