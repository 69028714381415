/**
 * Split an array into two, with any remainder in the first array
 * Ex: [['a', 'b', 'c'], ['d', 'e']]
 * @param {array} arr
 * @returns
 */

const splitArray = (arr) => [
	arr.slice(0, Math.ceil(arr.length / 2)),
	arr.slice(Math.ceil(arr.length / 2), arr.length),
];

//
export const moveCitiesByStateToColumns = (citiesByState, numColumns) => {
	// Split states into two columns for rendering into two separate accordions
	const states = Object.keys(citiesByState).sort();
	const columns = numColumns === 2 ? splitArray(states) : [states];

	return columns.map((column) =>
		// Return an array of objects with state, stores as keys.
		column.map((state) => ({
			state,
			cities: citiesByState[state].sort(),
		}))
	);
};

/**
 * Reorganize flat list of stores into an object with an array of cities for each state
 * @param {array} stores
 * @returns object
 */
// TODO: add types for stores when ready.
export const groupStoreCitiesByState = (stores: Array<any> = []) =>
	stores.reduce((states, store) => {
		const { store_state, store_city } = store;

		if (store_state in states) {
			if (states[store_state].includes(store_city)) {
				// City exists in state, so nothing to add
				return states;
			}

			// Add the city to the state's array of cities
			return {
				...states,
				[store_state]: [...states[store_state], store_city],
			};
		}

		// Add the state and its first city
		return {
			...states,
			[store_state]: [store_city],
		};
	}, {});

/**
 * Convert a state name to its 2-letter postal abbreviation
 * Adapted from https://gist.github.com/calebgrove/c285a9510948b633aa47?permalink_comment_id=3653829#gistcomment-3653829
 * @param {string} name
 * @returns
 */
export const stateNameToAbbreviation = (name: string) => {
	if (!name) {
		return '';
	}
	const states = {
		arizona: 'AZ',
		alabama: 'AL',
		alaska: 'AK',
		arkansas: 'AR',
		california: 'CA',
		colorado: 'CO',
		connecticut: 'CT',
		'district of columbia': 'DC',
		delaware: 'DE',
		florida: 'FL',
		georgia: 'GA',
		hawaii: 'HI',
		idaho: 'ID',
		illinois: 'IL',
		indiana: 'IN',
		iowa: 'IA',
		kansas: 'KS',
		kentucky: 'KY',
		louisiana: 'LA',
		maine: 'ME',
		maryland: 'MD',
		massachusetts: 'MA',
		michigan: 'MI',
		minnesota: 'MN',
		mississippi: 'MS',
		missouri: 'MO',
		montana: 'MT',
		nebraska: 'NE',
		nevada: 'NV',
		'new hampshire': 'NH',
		'new jersey': 'NJ',
		'new mexico': 'NM',
		'new york': 'NY',
		'north carolina': 'NC',
		'north dakota': 'ND',
		ohio: 'OH',
		oklahoma: 'OK',
		oregon: 'OR',
		pennsylvania: 'PA',
		'rhode island': 'RI',
		'south carolina': 'SC',
		'south dakota': 'SD',
		tennessee: 'TN',
		texas: 'TX',
		utah: 'UT',
		vermont: 'VT',
		virginia: 'VA',
		washington: 'WA',
		'west virginia': 'WV',
		wisconsin: 'WI',
		wyoming: 'WY',
		'american samoa': 'AS',
		guam: 'GU',
		'northern mariana islands': 'MP',
		'puerto rico': 'PR',
		'us virgin islands': 'VI',
		'us minor outlying islands': 'UM',
	};

	// Trim, remove all non-word characters with the exception of spaces, and convert to lowercase
	const key = name
		.trim()
		.replace(/[^\w ]/g, '')
		.toLowerCase();

	if (states[key.toLowerCase() as keyof typeof states] !== null) {
		return states[key.toLowerCase() as keyof typeof states];
	}

	// Fall back to the original state name if no exact match is found.
	return name;
};

export const stateAbbreviationToStateName = (abbreviation: string): string => {
	if (!abbreviation) {
		return '';
	}

	// The original state-to-abbreviation mapping
	const states = {
		arizona: 'AZ',
		alabama: 'AL',
		alaska: 'AK',
		arkansas: 'AR',
		california: 'CA',
		colorado: 'CO',
		connecticut: 'CT',
		'district of columbia': 'DC',
		delaware: 'DE',
		florida: 'FL',
		georgia: 'GA',
		hawaii: 'HI',
		idaho: 'ID',
		illinois: 'IL',
		indiana: 'IN',
		iowa: 'IA',
		kansas: 'KS',
		kentucky: 'KY',
		louisiana: 'LA',
		maine: 'ME',
		maryland: 'MD',
		massachusetts: 'MA',
		michigan: 'MI',
		minnesota: 'MN',
		mississippi: 'MS',
		missouri: 'MO',
		montana: 'MT',
		nebraska: 'NE',
		nevada: 'NV',
		'new hampshire': 'NH',
		'new jersey': 'NJ',
		'new mexico': 'NM',
		'new york': 'NY',
		'north carolina': 'NC',
		'north dakota': 'ND',
		ohio: 'OH',
		oklahoma: 'OK',
		oregon: 'OR',
		pennsylvania: 'PA',
		'rhode island': 'RI',
		'south carolina': 'SC',
		'south dakota': 'SD',
		tennessee: 'TN',
		texas: 'TX',
		utah: 'UT',
		vermont: 'VT',
		virginia: 'VA',
		washington: 'WA',
		'west virginia': 'WV',
		wisconsin: 'WI',
		wyoming: 'WY',
		'american samoa': 'AS',
		guam: 'GU',
		'northern mariana islands': 'MP',
		'puerto rico': 'PR',
		'us virgin islands': 'VI',
		'us minor outlying islands': 'UM',
	};

	// Create a new map for abbreviation to state name lookup using Object.entries
	const abbreviationsToStates: { [key: string]: string } = {};
	Object.entries(states).forEach(([state, abbreviation]) => {
		abbreviationsToStates[abbreviation] = state;
	});

	// Normalize the input abbreviation to uppercase
	const normalizedAbbreviation = abbreviation.toUpperCase();

	// Find the state name from the abbreviation
	const stateName = abbreviationsToStates[normalizedAbbreviation] || '';

	// Function to capitalize each word in the state name
	const capitalize = (str: string) => str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

	// Return the capitalized state name
	return capitalize(stateName);
};

