import { useMediaQuery } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

interface ContentfulImageProps {
	title?: string;
	description?: string;
	image?: {
		gatsbyImageData: IGatsbyImageData;
		description: string;
		title?: string;
	};
	width?: number;
	className?: string;
	loading?: 'lazy' | 'eager';
	objectFit?: 'cover' | 'contain';
	imgStyle?: React.CSSProperties;
	imgStyleMobile?: React.CSSProperties;
}

const ContentfulImage: React.FC<ContentfulImageProps> = (props) => {
	const {
		image,
		className,
		width = 570,
		loading = 'lazy',
		objectFit = 'cover',
		imgStyle,
		imgStyleMobile,
	} = props;
	const isMobile = useMediaQuery('(max-width:600px)');

	// const { image, className, width = 570 } = props; // corrected 'classname' to 'className'

	if (!image) {
		console.error('Image is not provided');
		return null;
	}

	const gatsbyImage = image.gatsbyImageData;

	if (!gatsbyImage) {
		console.error('Invalid image data:', image);
		return null;
	}

	const adjustedImgStyle =
		isMobile && imgStyleMobile ? imgStyleMobile : imgStyle;

	return (
		<GatsbyImage
			className={`${className} gatsby-image-wrapper`}
			image={gatsbyImage}
			alt={image.description || image.title || ''}
			loading={loading}
			objectFit={objectFit}
			style={adjustedImgStyle}
		/>
	);
};

export default ContentfulImage;
