import {
	DrivenBox,
	DrivenContainer,
	DrivenGrid,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';

import { graphql } from 'gatsby';
import { ComponentMatch } from '../hooks';
import ContentfulImage from './contentful-image';
import MarkdownContent from './mdx/markdown-content';

interface ImageBannerBlocks {
	body: {
		childMarkdownRemark: {
			rawMarkdownBody?: string;
		};
	};
	heading: string;
	actions?: React.ReactNode;
}

interface ImageBannerProps {
	image: {
		title: string;
		publicUrl: string;
	};
	blocks: ImageBannerBlocks;
}

export default function ImageBanner({ image, blocks }: ImageBannerProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const imageContainer = () => {
		return (
			<DrivenBox
				sx={{
					px: { xs: 6, md: 0 },
					pb: 3,
				}}
			>
				<ContentfulImage image={image} />
			</DrivenBox>
		);
	};

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenGrid
				container
				spacing={6}
				justifyContent='space-between'
				alignItems='center'
			>
				<DrivenGrid item xs={12} md={3}>
					{!isMobile && imageContainer()}
				</DrivenGrid>
				<DrivenGrid item xs={12} md={9}>
					<DrivenTypography
						textAlign={isMobile ? 'center' : 'initial'}
						sx={{
							mb: { xs: 2 },
						}}
						variant='h3'
					>
						{blocks?.heading}
					</DrivenTypography>
					{isMobile && imageContainer()}
					<MarkdownContent>
						{blocks?.body?.childMarkdownRemark.rawMarkdownBody}
					</MarkdownContent>
					{ComponentMatch(blocks?.actions)}
				</DrivenGrid>
			</DrivenGrid>
		</DrivenContainer>
	);
}

export const ImageBannerQuery = graphql`
	fragment ImageBannerFields on ContentfulImageBanner {
		__typename
		id
		name
		blocks {
			heading
			body {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
			actions {
				...ButtonFields
			}
		}
		image {
			description
			gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
			id
			title
		}
	}
`;
