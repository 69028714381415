import {
	ButtonIconPlacement,
	DrivenButton,
	DrivenStack,
	IconTypes
} from '@drivenbrands/driven-components';

type ProgressButtonProps = {
	disableSubmit?: boolean;
	hidePrevious?: boolean;
	loading?: boolean;
	submitIcon?: boolean;
	submitText?: string;
	handlePrevious?: () => void;
};

export const ProgressButtons = ({
	disableSubmit = true,
	hidePrevious = false,
	loading = false,
	submitIcon = true,
	submitText = 'Next',
	handlePrevious,
}: ProgressButtonProps) => {
	return (
		<DrivenStack>
			<DrivenButton
				aria-disabled={disableSubmit}
				disabled={disableSubmit || loading}
				icon={submitIcon || loading ? IconTypes.ARROW_FORWARD : undefined}
				iconPlacement={ButtonIconPlacement.RIGHT}
				isLoading={loading}
				type='submit'
				style={{ 'borderRadius': 30 }}
			>
				{submitText}
			</DrivenButton>
			{!hidePrevious && (
				<DrivenButton
					color='primary'
					style={{ 'borderRadius': 30 }}
					disabled={loading}
					onClick={() => handlePrevious && handlePrevious()}
					variant='text'
				>
					Go Back
				</DrivenButton>
			)}
		</DrivenStack>
	);
};
