import {
	DrivenContainer,
	DrivenStack
} from '@drivenbrands/driven-components';
import React, { useCallback, useContext } from 'react';

import { captureCustomDataLayerEvent } from '../../../services';
import {
	FormStateContext, QuotePriceCard
} from '../utils';
import { ScheduleForm } from './schedule-form';

export enum ButtonPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
}

export const BUTTON_TEXT = 'Schedule Service';

export const QuoteDetailsForm = React.memo(function QuoteDetailsForm() {
	const { quote } = useContext(FormStateContext);

	// console.log(">>><<<<  ON QUOTE DISPLAY >>><<<< ", quote)

	if (!quote) {
		return <></>;
	}

	const {
		Deductible,
		QuoteNum,
		QuoteAmount,
		QuoteAmountInsurance,
		ShopId,
		...rest
	} = quote;

	const triggerAnalyticEvent = useCallback(
		(buttonPosition: ButtonPosition) => {
			captureCustomDataLayerEvent('quote_submit', {
				oet_quote_id: QuoteNum,
				schedule_button_position: buttonPosition,
				store_id: ShopId,
				oet_quote_value: QuoteAmount,
			});
		},
		[quote]
	);

	return (
		<DrivenContainer >
			<DrivenStack spacing={4}>
				{/* <BodyText text='Your quote is now ready! A copy of your quote number and details have been sent to your email.' /> */}
				<QuotePriceCard quote={quote} />

				{/* schedule  */}
				<ScheduleForm />


				{/* <PaymentInfoCard /> */}


				{/* <DrivenButton
					type='submit'
					onClick={() => triggerAnalyticEvent(ButtonPosition.BOTTOM)}
				>
					{BUTTON_TEXT}
				</DrivenButton> */}
			</DrivenStack>
		</DrivenContainer>
	);
});
