import {
	DrivenBox,
	DrivenDropdown,
	DrivenGrid,
	DrivenTextInput
} from '@drivenbrands/driven-components';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	createDropdownOptions,
	ErrorIcon,
	FormStateContext,
	LoadingIcon,
	vehicleYears
} from '../../';
import {
	MAINSTREET_URL,
	MakeResponse,
	ModelResponse,
	QuoteResponse,
	StyleItem,
	StyleResponse,
	useQuoteService
} from '../../../../services';

const MAKE_ERROR =
	'We could not retrieve any makes for the year you selected. Please try again.';
const MODEL_ERROR =
	'We could not retrieve any models based on the year and/or make you selected. Please try again.';
const STYLE_ERROR =
	'We could not retrieve any styles based on the year, make, and/or model you selected. Please try again.';

export const MakeModelYearForm = React.memo(function MakeModelYearForm() {
	const {
		formState: { errors },
		register,
		setValue,
	} = useFormContext();
	const {
		makeOptions,
		setMakeOptions,
		modelOptions,
		setModelOptions,
		styleOptions,
		setStyleOptions,
		styleResponse,
		setStyleResponse,
		vehicleMake,
		setVehicleMake,
		vehicleModel,
		setVehicleModel,
		vehicleStyle,
		setVehicleStyle,
		vehicleYear,
		setVehicleYear,
	} = useContext(FormStateContext);

	const [loading, setLoading] = useState<boolean>(false);
	// Make
	const [vehicleMakeError, setVehicleMakeError] = useState<string>();
	// Model
	const [vehicleModelError, setVehicleModelError] = useState<string>();
	// Style
	const [vehicleStyleError, setVehicleStyleError] = useState<string>();

	// Year
	const handleVehicleYearChange = async (event: SelectChangeEvent<unknown>) => {
		const VehYear = event.target.value as number;
		setVehicleYear && setVehicleYear(VehYear);
		setLoading(true);
		setVehicleMakeError(undefined);
		// RESET ANY SELECTED OPTIONS
		setVehicleMake && setVehicleMake('');
		setVehicleModel && setVehicleModel('');
		setVehicleStyle && setVehicleStyle('');
		setMakeOptions && setMakeOptions(undefined);
		setModelOptions && setModelOptions(undefined);
		setStyleOptions && setStyleOptions(undefined);

		await useQuoteService({
			url: `${MAINSTREET_URL}makes`,
			body: { VehYear },
			onError: () => {
				setLoading(false);
				setVehicleMakeError(MAKE_ERROR);
			},
			onSuccess: (response: QuoteResponse) => {
				setLoading(false);
				const makeResponse = response as MakeResponse;
				if (makeResponse && makeResponse.Makes.length > 0) {
					setMakeOptions &&
						setMakeOptions(createDropdownOptions(makeResponse.Makes));
				} else {
					setVehicleMakeError(MAKE_ERROR);
				}
			},
		});
	};

	// Make
	const handleVehicleMakeChange = async (event: SelectChangeEvent<unknown>) => {
		const VehMake = event.target.value as string;
		setVehicleMake && setVehicleMake(VehMake);
		setLoading(true);
		setVehicleModelError(undefined);
		// RESET ANY SELECTED OPTIONS
		setVehicleModel && setVehicleModel('');
		setVehicleStyle && setVehicleStyle('');
		setModelOptions && setModelOptions(undefined);
		setStyleOptions && setStyleOptions(undefined);

		if (vehicleYear && VehMake) {
			await useQuoteService({
				url: `${MAINSTREET_URL}models`,
				body: {
					VehYear: vehicleYear,
					VehMake,
				},
				onError: () => {
					setLoading(false);
					setVehicleModelError(MODEL_ERROR);
				},
				onSuccess: (response: QuoteResponse) => {
					setLoading(false);
					const modelResponse = response as ModelResponse;
					if (modelResponse && modelResponse.Models.length > 0) {
						setModelOptions &&
							setModelOptions(createDropdownOptions(modelResponse.Models));
					} else {
						setVehicleModelError(MODEL_ERROR);
					}
				},
			});
		}
	};

	// Model
	const handleVehicleModelChange = async (
		event: SelectChangeEvent<unknown>
	) => {
		const VehModel = event.target.value as string;
		setVehicleModel && setVehicleModel(VehModel);
		setLoading(true);
		setVehicleStyleError(undefined);
		// RESET ANY SELECTED OPTIONS
		setVehicleStyle && setVehicleStyle('');
		setStyleOptions && setStyleOptions(undefined);

		if (vehicleYear && VehModel) {
			await useQuoteService({
				url: `${MAINSTREET_URL}styles`,
				body: {
					VehYear: vehicleYear,
					VehMake: vehicleMake,
					VehModel,
				},
				onError: () => {
					setLoading(false);
					setVehicleStyleError(STYLE_ERROR);
				},
				onSuccess: (response: QuoteResponse) => {
					setLoading(false);
					const styleResponse = response as StyleResponse;
					if (styleResponse && styleResponse.Styles.length > 0) {
						setStyleOptions &&
							setStyleOptions(
								styleResponse.Styles.map((style: StyleItem) => ({
									value: style.Style,
									option: style.Style,
								}))
							);
						setStyleResponse && setStyleResponse(styleResponse.Styles);
					} else {
						setVehicleStyleError(STYLE_ERROR);
					}
				},
			});
		}
	};

	// Style
	const handleVehicleStyleChange = (event: SelectChangeEvent<unknown>) => {
		const Style = event.target.value as string;
		setVehicleStyle && setVehicleStyle(Style);
		getCarIdByStyle(Style);
	};

	// CarId
	const getCarIdByStyle = (style: string) => {
		setValue(
			'CarId',
			styleResponse?.find(({ Style }) => Style === style)?.CarId
		);
	};

	return (
		<DrivenGrid container direction='column'>
			<DrivenGrid item xs>
				<DrivenDropdown
					label='Year'
					placeholder='Select One'
					options={vehicleYears}
					hasError={
						Boolean(vehicleMakeError?.length) || Boolean(errors['VehYear'])
					}
					errorHelperText={
						vehicleMakeError ?? (errors['VehYear']?.message as string)
					}
					errorIcon={<ErrorIcon />}
					value={vehicleYear ?? ''}
					required
					{...register('VehYear', {
						onChange: (event) => handleVehicleYearChange(event),
					})}
				/>
			</DrivenGrid>
			{Boolean(vehicleYear) && makeOptions && (
				<DrivenGrid item xs>
					<DrivenDropdown
						label='Make'
						placeholder='Select One'
						options={makeOptions}
						hasError={
							Boolean(vehicleModelError?.length) || Boolean(errors['VehMake'])
						}
						errorHelperText={
							vehicleModelError ?? (errors['VehMake']?.message as string)
						}
						errorIcon={<ErrorIcon />}
						value={vehicleMake}
						required
						{...register('VehMake', {
							onChange: (event) => handleVehicleMakeChange(event),
						})}
					/>
				</DrivenGrid>
			)}
			{vehicleMake && modelOptions && (
				<DrivenGrid item xs>
					<DrivenDropdown
						label='Model'
						placeholder='Select One'
						options={modelOptions}
						hasError={
							Boolean(vehicleStyleError?.length) || Boolean(errors['VehModel'])
						}
						errorHelperText={
							vehicleStyleError ?? (errors['VehModel']?.message as string)
						}
						errorIcon={<ErrorIcon />}
						value={vehicleModel}
						required
						{...register('VehModel', {
							onChange: (event) => handleVehicleModelChange(event),
						})}
					/>
				</DrivenGrid>
			)}
			{vehicleModel && styleOptions && (
				<DrivenGrid item xs>
					<DrivenDropdown
						label='Style'
						placeholder='Select One'
						options={styleOptions}
						hasError={Boolean(errors['Style'])}
						errorHelperText={errors['Style']?.message as string}
						errorIcon={<ErrorIcon />}
						value={vehicleStyle}
						required
						{...register('Style', {
							onChange: (event) => handleVehicleStyleChange(event),
						})}
					/>
				</DrivenGrid>
			)}
			{/* ONLY USING FOR FORM SUBMISSION PURPOSES - WILL NOT BE DISPLAYED */}
			<DrivenGrid item xs sx={{ display: 'none' }}>
				<DrivenTextInput
					data-id='car_id'
					label='Car Id'
					hasError={Boolean(errors['CarId'])}
					errorHelperText={errors['CarId']?.message as string}
					errorIcon={<ErrorIcon />}
					// required
					readOnly
					type='hidden'
					{...register('CarId')}
				/>
			</DrivenGrid>
			{loading && (
				<DrivenBox sx={{ my: 2 }}>
					<LoadingIcon />
				</DrivenBox>
			)}
			<DrivenGrid
				container
				direction='column'
				flexWrap='nowrap'
				item
				xs
				rowSpacing={2}
			>
				<DrivenGrid item xs sx={{ marginTop: 2 }}>
					{/* <Caption text='*Providing Year, Make, Model, and Style over VIN may require you to complete additional questions about your vehicle.' /> */}
				</DrivenGrid>
				{/* <DrivenGrid item xs>
					<FormInfoBanner
						header='Warning!'
						supportText='At this time, we cannot include an estimate for ADAS Calibration as part of your quote if you provide your vehicle details via Year, Make, Model, Style. Please note that this may be a service required at the time of your visit depending on the vehicle damage you specify.'
					/>
				</DrivenGrid> */}
			</DrivenGrid>
		</DrivenGrid>
	);
});
