import {
	DrivenBox,
	DrivenIcon,
	DrivenTypography,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import { visuallyHidden } from '@mui/utils';
import { GatsbyLinkBehavior } from '../../gatsby-link-behavior';

export type ShareButton = {
	url: string;
	title: string;
};

// Share buttons
export const ShareButtons = ({ url, title }: ShareButton) => {
	const shareUrl = url;
	const shareTitle = title; // configure share links to use title

	return (
		<DrivenBox
			display='flex'
			flexWrap='nowrap'
			alignItems='center'
			columnGap={1}
			mb={3}
			sx={{
				a: {
					color: 'primary.main',

					'&:hover': {
						color: 'primary.dark',
					},
				},
			}}
		>
			<DrivenTypography variant='body2Bold' component='h3'>
				Share This Article:{' '}
			</DrivenTypography>

			<GatsbyLinkBehavior
				href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
				target='_blank'
				onClick={(e) => {
					// captureCustomDataLayerEvent('share', { method: 'facebook' });
				}}
			>
				<DrivenIcon icon={IconTypes.FACEBOOK} size={IconSizes.MD} />
				<DrivenTypography component='span' sx={visuallyHidden}>
					Facebook
				</DrivenTypography>
			</GatsbyLinkBehavior>

			<GatsbyLinkBehavior
				href={`http://www.twitter.com/share?url=${shareUrl}`}
				target='_blank'
				onClick={(e) => {
					// captureCustomDataLayerEvent('share', { method: 'twitter' });
				}}
			>
				<DrivenIcon icon={IconTypes.X_SQUARE} size={IconSizes.MD} />
				<DrivenTypography component='span' sx={visuallyHidden}>
					Twitter
				</DrivenTypography>
			</GatsbyLinkBehavior>

			<GatsbyLinkBehavior
				href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
				target='_blank'
				onClick={(e) => {
					// captureCustomDataLayerEvent('share', { method: 'linkedin' });
				}}
			>
				<DrivenIcon icon={IconTypes.LINKEDIN} size={IconSizes.MD} />
				<DrivenTypography component='span' sx={visuallyHidden}>
					LinkedIn
				</DrivenTypography>
			</GatsbyLinkBehavior>

			<GatsbyLinkBehavior
				href={`mailto:?subject=Sent from Driven Brands: ${title}&amp;body=${shareUrl}`}
				target='_blank'
				onClick={(e) => {
					// captureCustomDataLayerEvent('share', { method: 'email' });
				}}
			>
				<DrivenIcon icon={IconTypes.ENVELOPE} size={IconSizes.MD} />
				<DrivenTypography component='span' sx={visuallyHidden}>
					Email
				</DrivenTypography>
			</GatsbyLinkBehavior>
		</DrivenBox>
	);
};
export default ShareButtons;
