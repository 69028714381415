import { graphql, useStaticQuery } from 'gatsby';

type SiteMetadataProps = {
	title: string;
	siteUrl: string;
	description: string;
};

type SiteMetadata = {
	site: {
		siteMetadata: SiteMetadataProps;
	};
};

export const useSiteMetadata = ():SiteMetadataProps => {
	const data: SiteMetadata = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					siteUrl
					description
				}
			}
		}
	`);

	return data?.site?.siteMetadata;
};
