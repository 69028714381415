import {
	DrivenBox,
	DrivenContentCard,
	DrivenDayTimePicker, DrivenStack,
	DrivenTextInput,
	DrivenTypography
} from '@drivenbrands/driven-components';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DayOfWeek } from '../../../services';
import {
	AppointmentContext, ButtonContext,
	FormStateContext,
	LoadingIcon,
	ProgressButtons
} from '../utils';

type DayPickerAvailableSlots = {
	day: DayOfWeek;
	slots: string[];
};

export const ScheduleForm = () => {
	const {
		appointmentsLoading,
		endDate,
		getShopSchedule,
		shopSchedule,
		startDate,
		waitForVehicle,
	} = useContext(AppointmentContext);
	const { handlePrevious } = useContext(ButtonContext);
	const { appointment, loading, quote } = useContext(FormStateContext);
	const {
		formState: { isValid },
		register,
		setValue,
	} = useFormContext();

	const [slotSelected, setSlotSelected] = useState<string>('');
	const [waitingForVehicle, setWaitingForVehicle] = useState<string>('');

	useEffect(() => {
		if (appointment?.ScheduleDate && appointment.Slot) {
			setSlotSelected(`${appointment?.ScheduleDate}::${appointment?.Slot}`);
		}
	}, [appointment]);

	const getShopAvailability = (searchWeek?: string) => {
		if (waitingForVehicle.length) {
			const isWaiting = waitingForVehicle === 'true';
			getShopSchedule && getShopSchedule(isWaiting, searchWeek);
		}
	};

	useEffect(() => {
		if (waitingForVehicle?.length) {
			getShopAvailability(quote?.FirstAvailable);
		}
	}, [waitingForVehicle]);

	const modifiedAvailability: DayPickerAvailableSlots[] = useMemo(() => {
		if (shopSchedule?.length) {
			return shopSchedule.map((data) => {
				const { Day, Slots } = data;
				return {
					day: Day,
					slots: Slots,
				};
			});
		}
		return [];
	}, [shopSchedule]);

	const handOff: string = useMemo(
		() => (waitingForVehicle.length ? waitingForVehicle : `${waitForVehicle}`),
		[waitForVehicle, waitingForVehicle]
	);

	useEffect(() => {
		const valueSettingOptions = {
			shouldDirty: true,
			shouldValidate: true,
		};
		const [date, time] = slotSelected?.split('::');
		setValue('ScheduleDate', date ?? '', valueSettingOptions);
		setValue('Slot', time ?? '', valueSettingOptions);
	}, [slotSelected]);

	const getMoreSlots = () => {
		if (endDate) {
			getShopAvailability(format(new Date(endDate), 'P'));
		}
	};

	function timeout(delay: number) {
		return new Promise(res => setTimeout(res, delay));
	}

	useEffect(() => {
		// manually trigger a load of times for waiting. 
		// Are drop off times different? 
		setWaitingForVehicle('true')
	}, [])

	return (
		<DrivenStack spacing={4}>
			<DrivenContentCard
				content={
					<DrivenStack spacing={2} sx={{ pt: 4 }}>
						{/* <BodyText text='Select an option for vehicle hand-off:' /> */}
						<DrivenStack spacing={3} direction='column'>
							{/* <DrivenRadioGroup
								defaultValue={handOff}
								label=''
								aria-label='Select an option for vehicle hand-off:'
								onChange={(e, checked) => setWaitingForVehicle(checked)}
								value={handOff}
							>
								<DrivenStack direction='row' spacing={2}>
									<DrivenRadioButton label='Drop off vehicle' value='false' />
									<DrivenRadioButton label='Wait for vehicle' value='true' />
								</DrivenStack>
							</DrivenRadioGroup> */}
							{appointmentsLoading && <LoadingIcon />}
							{modifiedAvailability &&
								modifiedAvailability?.length > 0 &&
								startDate &&
								endDate && (
									<DrivenDayTimePicker
										availableSlots={modifiedAvailability}
										dateRangeStart={startDate}
										dateRangeEnd={endDate}
										onReachSliderEnd={getMoreSlots}
										slidesPerView={4}
										slotSelected={slotSelected}
										onSlotSelect={setSlotSelected}
									/>
								)}
						</DrivenStack>
					</DrivenStack>
				}
				header={
					<DrivenTypography variant='subtitle1'>
						Select a date and time for your service
					</DrivenTypography>
				}
			/>
			{/* FOR FORM SUBMISSION ONLY - NO DISPLAY */}
			<DrivenBox sx={{ display: 'none' }}>
				<DrivenTextInput
					data-id='schedule_date'
					label='Schedule Date'
					readOnly
					type='hidden'
					{...register('ScheduleDate')}
				/>
				<DrivenTextInput
					data-id='schedule_slot'
					label='Slot'
					readOnly
					type='hidden'
					{...register('Slot')}
				/>
			</DrivenBox>
			<ProgressButtons
				submitText='Confirm & Schedule'
				disableSubmit={!isValid}
				handlePrevious={handlePrevious}
				loading={loading}
			/>
		</DrivenStack>
	);
};
