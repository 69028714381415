import {
	DrivenBox, DrivenButton,
	DrivenContainer,
	DrivenTypography,
} from '@drivenbrands/driven-components';

import { Link } from 'gatsby';
import { GatsbyLinkBehavior } from '../components/gatsby-link-behavior';

// This page is not actually used.  It is a placeholder for the 404 page. The 404 page is generated from contentful pages.

// TODO:  This might not be rendering correctly as a tsx file.  Changing to .js works.
// also should log some error here for what page was requested.

// markup
const NotFoundPage = () => {
	const gatsbyLinkStyles = {
		color: '#0070b9',
		textDecoration: 'none',
		fontWeight: 500,
	}

	return (
		<DrivenBox>
			<title>Not found</title>
			<DrivenContainer maxWidth='lg' sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				mt: { xs: 12, md: 10 },
				mb: { xs: 6, md: 12 },
			}}>
				<DrivenTypography variant='h1' component='h1'>
					Page not found
				</DrivenTypography>
				<DrivenTypography variant='body1' component='p'>
					Sorry{' '}
					<span role='img' aria-label='Pensive emoji'>😔</span>{' '}
					we couldn’t find what you were looking for.
					<br />
				</DrivenTypography>
				<DrivenTypography>
					You may be interested in
					<Link to='/our-locations/' style={gatsbyLinkStyles}> finding a location near you </Link>
					or
					<Link to='/online-estimate/' style={gatsbyLinkStyles}> getting a free quote online. </Link>
				</DrivenTypography>
				{process.env.NODE_ENV === 'development' ? (
					<>
						<br />
						Try creating a page in{' '}
						<DrivenTypography variant='body1Bold'>src/pages/</DrivenTypography>.
						<br />
					</>
				) : null}
				<DrivenButton
					color='primary'
					href='/'
					LinkComponent={GatsbyLinkBehavior}
					variant='contained'
					sx={{ mt: { xs: 1, sm: 2 }, maxWidth: '200px' }}
				>
					Go home
				</DrivenButton>
			</DrivenContainer>
		</DrivenBox>
	);
};

export default NotFoundPage;
