export const searchOnEnter = (
	e:
		| React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
		| React.KeyboardEvent<HTMLDivElement>,
	callBack: (value: string) => void
) => {
	const target = e.target as HTMLTextAreaElement;
	if (e?.key === 'Enter' && target.value?.trim().length) {
		callBack(target.value);
		e.preventDefault();
	}
};

export const booleanToNumber = (boolean?: boolean) => (boolean ? 1 : 0);

export const saveAdParameters = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const paramsToSave = [
		'utm_source', 'utm_campaign', 'utm_medium', 'mca_cmp',
		'utm_content', 'utm_term', 'gad_source', 'gclid',
	];

	paramsToSave.forEach(param => {
		const value = urlParams.get(param);
		if (value) {
			localStorage.setItem(param, value);
		}
	});
};