import {
	DrivenGrid,
	DrivenStack,
	DrivenTabs,
	DrivenTypography,
	TextSize,
} from '@drivenbrands/driven-components';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	BodyText,
	ButtonContext,
	CardContainer,
	FormStateContext,
	ProgressButtons,
} from '../../utils';
import { InsuranceForm } from '../insurance-info';
import { LicenseForm, MakeModelYearForm, VINForm } from '../vehicle-info';

type VehicleDetailProps = {
	handleTabChange: (activeTab: number) => void;
	handleInsuranceMethod: any;
	insuranceMethod?: any;
};

export const VehicleDetailForm = React.memo(function VehicleDetailForm({
	handleTabChange,
	handleInsuranceMethod,
	insuranceMethod,
}: VehicleDetailProps) {
	const {
		formState: { isValid },
	} = useFormContext();
	const { handlePrevious } = useContext(ButtonContext);
	const { loading, setLicenseError, setVinError, selectedInsurance } =
		useContext(FormStateContext);
	const [activeTab, setActiveTab] = useState<number>(0);

	// const [insuranceHasBeenSlected, setInsuranceHasBeenSlected] = useState(false)

	const [ableToProceed, setAbleToProceed] = useState(false);

	useEffect(() => {
		// console.log('SETTING insurance ON DETAILS >> ', selectedInsurance)

		if (isValid && selectedInsurance) {
			setAbleToProceed(true);
		} else {
			setAbleToProceed(false);
		}
	}, [selectedInsurance]);

	// TODO: Set valid state for button to show next.
	// needs
	// if tab > Y,M,M,S
	// must fill out 4 values.
	// must have selected yes or no on insurance.

	const handleActiveTab = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
		handleTabChange(newValue);
		setLicenseError && setLicenseError('');
		setVinError && setVinError('');
	};

	return (
		<DrivenStack spacing={4}>
			<CardContainer>
				<DrivenGrid container direction='column' spacing={2}>
					<DrivenGrid item xs>
						<DrivenTypography component='h1' variant='h5'>
							Your Vehicle Details
						</DrivenTypography>
						<BodyText text='Please provide one of the following vehicle lookup options for glass locations' />
					</DrivenGrid>
					<DrivenGrid item xs>
						<DrivenTabs
							tabs={[
								{
									label: 'YEAR, MAKE, MODEL, STYLE',
									tabPanelContent: <MakeModelYearForm />,
								},
								{
									label: 'USE VIN NUMBER',
									tabPanelContent: <VINForm />,
								},
								{
									label: 'LICENSE PLATE',
									tabPanelContent: <LicenseForm />,
								},
							]}
							tabVariant={TextSize.small}
							value={activeTab}
							onChange={handleActiveTab}
						/>
					</DrivenGrid>
				</DrivenGrid>

				{/* Add insurance here  */}
				<InsuranceForm
					handleInsuranceMethod={handleInsuranceMethod}
					insuranceMethod={insuranceMethod}
				/>
			</CardContainer>

			<ProgressButtons
				// disableSubmit={false}
				disableSubmit={!ableToProceed}
				handlePrevious={handlePrevious}
				loading={loading}
			/>
		</DrivenStack>
	);
});
