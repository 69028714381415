import {
	DrivenBox,
	DrivenContainer,
	DrivenContentSection,
} from '@drivenbrands/driven-components';
import {
	buttonClasses,
	gridClasses,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';

import { graphql } from 'gatsby';
import { ComponentMatch } from '../hooks';
import ContentfulImage from './contentful-image';
import MarkdownContent from './mdx/markdown-content';

interface ContentSectionProps {
	actions?: React.ReactNode;
	heading?: string;
	headingAlign: boolean;
	// USING HEADING LOGO FOR SMALLER IMAGES
	headingLogo?: {
		title: string;
		publicUrl: string;
	};
	imageAlign?: 'left' | 'right' | 'stacked';
	body: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
	image: {
		title: string;
		publicUrl: string;
	};
	noPadding?: boolean;
}

export default function ContentSection({
	actions,
	body,
	heading,
	headingAlign,
	headingLogo,
	image,
	imageAlign = 'left',
	noPadding = true,
}: ContentSectionProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const zeroPadding = noPadding ? 0 : undefined;
	const centerContent = imageAlign === 'stacked';

	return (
		<DrivenContainer
			sx={{
				paddingLeft: { md: zeroPadding },
				paddingRight: { md: zeroPadding },

				// Kind of hacky, but these are needed
				// in a pinch to force content to center when stacked
				'& .MuiTypography-root': {
					textAlign: centerContent ? 'center' : 'inherit',
				},

				'& .MuiTypography-h3': {
					textTransform: 'none',
					textAlign: isMobile ? 'center' : 'inherit',
				},

				'& .MuiBox-root': {
					justifyContent: centerContent ? 'center' : 'inherit',
					textAlign: centerContent ? 'center' : 'inherit',
				},

				[`.${buttonClasses.root}`]: {
					mb: 3,
				},

				[`.${gridClasses.item}`]: {
					mb: { xs: 2, md: 0 },
					mt: { xs: 0 },
				},
			}}
		>
			<DrivenContentSection
				apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
				body={
					body && (
						<DrivenContainer
							sx={{
								px: { xs: zeroPadding },
							}}
						>
							<MarkdownContent>
								{body?.childMarkdownRemark.rawMarkdownBody}
							</MarkdownContent>
							{actions && (
								<DrivenBox display='flex' gap={3}>
									{ComponentMatch(actions)}
								</DrivenBox>
							)}
						</DrivenContainer>
					)
				}
				bodySize='body2'
				direction={centerContent ? 'right' : imageAlign}
				header={heading}
				headerAlign='center'
				headerInline={headingAlign}
				headerSize='h3'
				headingLevel='h2'
				image={
					(image || headingLogo) && (
						<ContentfulImage image={image ?? headingLogo} />
					)
				}
				isStacked={centerContent && !isMobile}
			/>
		</DrivenContainer>
	);
}

export const ContentSectionQuery = graphql`
	fragment ContentSectionFields on ContentfulContentSection {
		__typename
		id
		image {
			description
			gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			publicUrl
			placeholderUrl
			id
			title
		}
		imageAlign
		body {
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
		actions {
			...ButtonFields
		}
		heading
		headingAlign
		headingLogo {
			description
			gatsbyImageData(width: 103, quality: 100, breakpoints: 3)
			publicUrl
			placeholderUrl
			id
			title
		}
	}
`;
