import * as yup from 'yup';

import { Questions } from '../utils';

export type FormSchema = {
	defaultValues: {
		[key: string]:
			| Array<any>
			| boolean
			| string
			| number
			| ''
			| Record<any, unknown>
			| undefined
			| null;
	};
	schema: yup.AnyObjectSchema;
};

const phoneRegex = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
export const matchesPhone = (input: string) => input.match(phoneRegex);

// Reused yup schema
const firstName = yup.string().required('Please enter your first name.');
const lastName = yup.string().required('Please enter your last name.');
const email = yup
	.string()
	.email('Must be a valid email address.')
	.required('Please enter your email address.');
const phone = yup
	.string()
	.matches(phoneRegex, {
		message: 'Must contain 10 digits',
		excludeEmptyString: true,
	})
	.required('Please enter your phone number.');

const store = yup.object({
	ShopId: yup.string(),
	Banner: yup.mixed(),
	Company: yup.string(),
	Address: yup.string(),
	City: yup.string(),
	State: yup.string(),
	Zip: yup.string(),
	Mobile: yup.boolean(),
	Distance: yup.number(),
	DistanceUnit: yup.string(),
});

// Quote and Schedule form schema and default values
// Initiate flow & confirm store
export const storeSchema: FormSchema = {
	defaultValues: {
		store: {},
	},
	schema: yup.object({
		store,
	}),
};

// Customer Information
export const customerInfoSchema: FormSchema = {
	defaultValues: {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		TextConsent: false,
	},
	schema: yup.object({
		FirstName: firstName,
		LastName: lastName,
		Email: email,
		Phone: phone,
		TextConsent: yup.boolean(),
	}),
};

// Vehicle information
// // VIN
export const vinSchema: FormSchema = {
	defaultValues: {
		VIN: '',
	},
	schema: yup.object({
		VIN: yup.string().required('Please enter your VIN'),
	}),
};

// // Year, make, model, style
export const makeModelYearSchema: FormSchema = {
	defaultValues: {
		VehYear: null,
		VehMake: '',
		VehModel: '',
		Style: '',
		CarId: '',
	},
	schema: yup.object({
		VehYear: yup
			.number()
			.required('Please enter the year your vehicle was manufactured.'),
		VehMake: yup.string().required('Please enter your vehicle make.'),
		VehModel: yup.string().required('Please enter your vehicle model.'),
		Style: yup.string().required('Please enter your vehicle style.'),
		CarId: yup.string().required('Please enter your vehicle style.'),
	}),
};

// // License plate
export const licenseSchema: FormSchema = {
	defaultValues: {
		LicensePlate: '',
		State: '',
	},
	schema: yup.object({
		LicensePlate: yup
			.string()
			.required("Please enter your vehicle's license plate."),
		State: yup.string().required('Please select your state.'),
	}),
};

// Damage assessment
export const damageSchema: FormSchema = {
	defaultValues: {
		DamageTypes: [],
	},
	schema: yup.object({
		DamageTypes: yup.array(
			yup.object({
				OpeningType: yup.string(),
				DamageType: yup.string(),
				Position: yup.string(),
				Size: yup.string(),
				Edges: yup.string(),
				Growing: yup.string(),
			})
		),
	}),
};
/////////////////////////////////
// QUESTIONS SCHEMA
/////////////////////////////////
const customValidation = (data: any, fieldName: Questions) => {
	let validation = yup.string().nullable();
	// QUESTIONS THAT MATCH THE CAR WILL BE REQUIRED
	if (data[fieldName] !== null) {
		validation = validation
			.transform((value) => (value === null ? 'no' : value))
			.required('Please answer this question to the best of your ability.');
	} else {
		// QUESTIONS DOES NOT MATCH THE VEHICLE QUESTION LIST
		validation = validation.notRequired();
	}
	return validation;
};

// HUGE SHOUT OUT TO @gmonte: Guilherme Monte FOR THIS SOLUTION
// https://github.com/jquense/yup/issues/559#issuecomment-682311746
export const schemaGenerator = (data: any) =>
	yup.object().shape({
		[Questions.ACOUSTIC]: (() => customValidation(data, Questions.ACOUSTIC))(),
		[Questions.ADAS]: (() => customValidation(data, Questions.ADAS))(),
		[Questions.CONDENSATION]: (() =>
			customValidation(data, Questions.CONDENSATION))(),
		[Questions.ELECTRO]: (() => customValidation(data, Questions.ELECTRO))(),
		[Questions.FORWARD_COLLISION]: (() =>
			customValidation(data, Questions.FORWARD_COLLISION))(),
		[Questions.HEATED]: (() => customValidation(data, Questions.HEATED))(),
		[Questions.HEATED_WIPER]: (() =>
			customValidation(data, Questions.HEATED))(),
		[Questions.HUMIDITY]: (() => customValidation(data, Questions.HUMIDITY))(),
		[Questions.HUD]: (() => customValidation(data, Questions.HUD))(),
		[Questions.HIBEAMS]: (() => customValidation(data, Questions.HIBEAMS))(),
		[Questions.LANE_DEPART]: (() =>
			customValidation(data, Questions.LANE_DEPART))(),
		[Questions.LOGO]: (() => customValidation(data, Questions.LOGO))(),
		[Questions.RAIN]: (() => customValidation(data, Questions.RAIN))(),
		[Questions.RAIN_LIGHT]: (() =>
			customValidation(data, Questions.RAIN_LIGHT))(),
		[Questions.VISOR_FRIT]: (() =>
			customValidation(data, Questions.VISOR_FRIT))(),
		[Questions.SOLAR]: (() => customValidation(data, Questions.SOLAR))(),
	});

export const questionSchema: FormSchema = {
	defaultValues: {
		[Questions.ACOUSTIC]: null,
		[Questions.ADAS]: null,
		[Questions.CONDENSATION]: null,
		[Questions.ELECTRO]: null,
		[Questions.FORWARD_COLLISION]: null,
		[Questions.HEATED]: null,
		[Questions.HEATED_WIPER]: null,
		[Questions.HUMIDITY]: null,
		[Questions.HUD]: null,
		[Questions.HIBEAMS]: null,
		[Questions.LANE_DEPART]: null,
		[Questions.LOGO]: null,
		[Questions.RAIN]: null,
		[Questions.RAIN_LIGHT]: null,
		[Questions.VISOR_FRIT]: null,
		[Questions.SOLAR]: null,
	},
	schema: yup.object().shape({
		[Questions.ACOUSTIC]: yup.string().nullable(),
		[Questions.ADAS]: yup.string().nullable(),
		[Questions.CONDENSATION]: yup.string().nullable(),
		[Questions.ELECTRO]: yup.string().nullable(),
		[Questions.FORWARD_COLLISION]: yup.string().nullable(),
		[Questions.HEATED]: yup.string().nullable(),
		[Questions.HEATED_WIPER]: yup.string().nullable(),
		[Questions.HUMIDITY]: yup.string().nullable(),
		[Questions.HUD]: yup.string().nullable(),
		[Questions.HIBEAMS]: yup.string().nullable(),
		[Questions.LANE_DEPART]: yup.string().nullable(),
		[Questions.LOGO]: yup.string().nullable(),
		[Questions.RAIN]: yup.string().nullable(),
		[Questions.RAIN_LIGHT]: yup.string().nullable(),
		[Questions.VISOR_FRIT]: yup.string().nullable(),
		[Questions.SOLAR]: yup.string().nullable(),
	}),
};

const DEDUCTIBLE_MESSAGE = 'Please enter your deductible.';

// INSURANCE
export const insuranceSchema = {
	defaultValues: {
		Carrier: '',
		Deductible: 0,
		Policy: '',
	},
	schema: yup.object({
		Carrier: yup.string().required('Please choose a carrier from the list.'),
		Deductible: yup
			.number()
			.typeError(DEDUCTIBLE_MESSAGE)
			.defined(DEDUCTIBLE_MESSAGE)
			.moreThan(0, DEDUCTIBLE_MESSAGE)
			.required(DEDUCTIBLE_MESSAGE),
		Policy: yup.string().required('Please enter your policy number.'),
	}),
};

export const noInsuranceSchema = {
	defaultValues: {
		PromoCode: '',
	},
	schema: yup.object({
		PromoCode: yup.string(),
	}),
};

// Quote - this is a fake out to prevent submission issues from react-hook-forms
export const quoteSchema = {
	defaultValues: {
		quote: '',
	},
	schema: yup.object({
		quote: yup.string(),
	}),
};

// SCHEDULE / REVIEW SCHEMA
export const scheduleSchema = {
	defaultValues: {
		ScheduleDate: '',
		Slot: '',
	},
	schema: yup.object({
		ScheduleDate: yup.string().required(),
		Slot: yup.string().required(),
	}),
};

// Submission - this is a fake out to prevent submission issues from react-hook-forms
export const submitSchema = {
	defaultValues: {
		submit: '',
	},
	schema: yup.object({
		submit: yup.string(),
	}),
};
