import * as React from 'react';
import { BlogPost, SeoBlog } from './news-search';

interface SEOProps {
	post: BlogPost;
}

export const BlogSeo: React.FC<SEOProps> = ({ post }) => {
	const { title: defaultTitle, slug, publishDate, excerpt, featuredImage, createdAt, updatedAt, seo, mainLogo = {} as SeoBlog } = post;

	// Prioritize SEO object values over defaults
	const blogUrl = `https://www.AutoGlassNow.com/blog/${slug}`;
	const title = seo?.title || defaultTitle;
	const description = seo?.description || excerpt?.childMarkdownRemark?.rawMarkdownBody || '';
	const siteName = seo?.name || 'Auto Glass Now Blog';
	const robotsMeta = `${seo?.noindex ? 'noindex' : 'index'},${seo?.nofollow ? 'nofollow' : 'follow'}`;
	const seoCanonical = seo?.canonicalUrl || blogUrl;

	let createDate = createdAt;

	if (publishDate) {

		const date = new Date(publishDate);
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');

		createDate = `${year}-${month}-${day}`;
	}

	// Dynamically create the GTM script using the provided GTM ID
	const gtmScript = seo?.googleTagManager ? `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${seo?.googleTagManager}');
  ` : '';

	let imageUrl = '';

	if (featuredImage?.gatsbyImageData?.images?.sources) {
		const srcSet =
			featuredImage.gatsbyImageData.images.sources[0]?.srcSet || '';
		// Select the highest-resolution URL, which is the last one in the list
		const urls = srcSet.split(',\n');
		imageUrl = urls.length > 0 ? urls[urls.length - 1].split(' ')[0] : '';
	}

	const schemaMarkup = {
		'@context': 'https://schema.org',
		'@type': 'BlogPosting',
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': blogUrl,
		},
		headline: title,
		description,
		image: imageUrl,
		author: {
			'@type': "Organization",
			name: 'Auto Glass Now',
		},
		publisher: {
			'@type': 'Organization',
			name: 'Auto Glass Now',
			logo: {
				'@type': 'ImageObject',
				url: mainLogo,
			},
		},
		datePublished: createDate,
		dateModified: updatedAt,
	};

	return (
		<>
			<title>{title}</title>
			<meta name="description" content={description} />
			{robotsMeta && <meta name="robots" content={robotsMeta} />}
			{seo?.scriptSrc && <script src={seo?.scriptSrc} async defer />}
			{/* eslint-disable-next-line react/no-danger */}
			{gtmScript && <script dangerouslySetInnerHTML={{ __html: gtmScript }} />}

			{/* Twitter Card data */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@AUTOGLASSNOW" />
			<meta name="twitter:creator" content="@AUTOGLASSNOW" />

			{/* Open Graph data */}
			<meta property="og:title" content={title} />
			<meta property="og:type" content="article" />
			<meta property="og:url" content={seoCanonical} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:description" content={description} />
			<meta property="og:site_name" content={siteName} />

			{/* Schema Markup */}
			<script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
		</>
	);
};
