import { DrivenBox, DrivenButton } from '@drivenbrands/driven-components';
import { GatsbyLinkBehavior } from '../../gatsby-link-behavior';
import { CrossBrandImage } from './cross-brand-image';

const CrossBrandTake5OilChange = (props: any) => {
	const { images } = props;

	return (
		<DrivenBox sx={{ mt: { xs: -5, sm: 0 } }}>
			<CrossBrandImage image={images[0]} />
			<DrivenButton
				color='secondary'
				href='https://www.take5.com/locations/'
				LinkComponent={GatsbyLinkBehavior}
				variant='contained'
				sx={{ mt: { xs: 1, sm: 2 }, mx: 'auto', display: 'block', width: 'fit-content' }}
			>
				Find a location
			</DrivenButton>
		</DrivenBox>
	);
}

export default CrossBrandTake5OilChange;
