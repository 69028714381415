import { DrivenTypography } from '@drivenbrands/driven-components';
import { Chip, styled } from '@mui/material';

interface ChipListProps {
	heading?: string;
	chips: Array<string>;
}

const StyledHeaderTypography = styled(DrivenTypography)(({ theme }) => ({
	fontWeight: 700,
	color: theme.palette.grey[800],
	marginTop: theme.spacing(4),
	marginBottom: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
	backgroundColor: '#E7E7E7', // TODO: use correct theme color.
	borderRadius: 50,
	margin: theme.spacing(0.5),
	height: '33px',
	color: theme.palette.common.black,

	'> span': {
		padding: '8px 15px 6px',
	},
}));

export default function ChipList({ heading, chips }: ChipListProps) {
	if (!chips || !chips.length) {
		return null;
	}

	return (
		<>
			{heading && <StyledHeaderTypography>{heading}</StyledHeaderTypography>}
			{chips.map((each, key) => (
				<StyledChip
					key={`${each}-${key}`}
					label={
						<DrivenTypography variant='smallButton2'>{each}</DrivenTypography>
					}
				/>
			))}
		</>
	);
}
