import React, { useContext, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import {
	CustomerInfoForm,
	DamageAssessmentForm,
	InsuranceSelection,
	QuoteDetailsForm,
	ReviewForm,
	SubmissionPage,
	VehicleDetailForm
} from '../';
import { CustomerPayload, StepsResponse, Store } from '../../../services';
import {
	customerInfoSchema,
	damageSchema,
	FormSchema,
	FormStateContext,
	insuranceSchema,
	licenseSchema,
	makeModelYearSchema,
	noInsuranceSchema,
	PromoValidationContext,
	Questions,
	questionSchema,
	quoteSchema,
	scheduleSchema,
	schemaGenerator,
	submitSchema,
	vinSchema
} from '../utils';
import { BaseContactForm, FormProps } from './';

type QuoteWrapperProps = Omit<FormProps, 'children'> &
	Partial<UseFormReturn> & {
		jumpToStep: (steps: Omit<StepsResponse, 'Success'>) => void;
		searchByVin: (vin: string) => void;
	};

export enum TabName {
	LICENSE = 'license',
	MANUAL = 'manual',
	VIN = 'vin',
}

// Use this component in order for nested forms to gain react-hook-form context
export const QuoteFormWrapper = ({
	formOption,
	handleFormSubmit,
	jumpToStep,
	resetValues = false,
	searchByVin,
}: QuoteWrapperProps) => {
	const [resetForm, setResetForm] = useState<boolean>(resetValues);
	const {
		additionalQuestions,
		answers,
		appointment,
		customerInfo,
		damageTypes,
		LeadId,
		insuranceInfo,
		selectedStore,
		setAnswers,
		setCustomerInfo,
		setSelectedStore,
		vehicleInformation,
		vehicleState,
	} = useContext(FormStateContext);
	const { promoCode } = useContext(PromoValidationContext); // TODO: not needed
	// Store selection state
	const [updatedStore, setUpdatedStore] = useState<Store | undefined>(); // TODO: not needed?

	// Vehicle information state
	const [activeTab, setActiveTab] = useState<number>(0);

	// Insurance choice state
	const [insuranceMethod, setInsuranceMethod] = useState<InsuranceSelection | undefined>();

	// Needed for GTM event
	const [switchedInsuranceMethod, setSwitchedInsuranceMethod] = useState<InsuranceSelection | undefined>();

	// not needed to add to insurance?
	// insuranceMethod
	useEffect(() => {
		setResetForm(true);
	}, [activeTab]);

	useEffect(() => {
		// TODO: Check this works as expected. 
		if (formOption === 2 && insuranceInfo?.IsInsurance !== undefined) {
			setInsuranceMethod(
				insuranceInfo?.IsInsurance
					? InsuranceSelection.WITH
					: InsuranceSelection.NONE
			);
		}
	}, [insuranceInfo]);

	const handleInsuranceSelection = (selection: InsuranceSelection) => {
		const newSelection = insuranceMethod && insuranceMethod === selection ? undefined : selection;
		setInsuranceMethod(newSelection);
		// Needed for GTM event
		if (insuranceMethod && insuranceMethod !== selection) {
			setSwitchedInsuranceMethod(selection);
		}
	};

	const formValues = React.useMemo(() => {
		// console.log('SWITCH FORM OPTION VALUES >> ', formOption)
		switch (formOption) {
			// case 1:
			// case 333:
			// 	return storeSchema;
			case 1:
				if (customerInfo) {
					customerInfoSchema.defaultValues = customerInfo;
				}
				return customerInfoSchema;
			case 2:
				setResetForm(false); // Needs to be set to false so any time the activeTab changes it actually resets the form

				switch (activeTab) {
					case 0:
						makeModelYearSchema.defaultValues = {
							VehYear: vehicleInformation?.VehYear ?? null,
							VehMake: vehicleInformation?.VehMake ?? '',
							VehModel: vehicleInformation?.VehModel ?? '',
							Style: vehicleInformation?.Style ?? '',
							CarId: vehicleInformation?.CarId ?? '',
						};
						return makeModelYearSchema;
					case 1:
						vinSchema.defaultValues = { VIN: vehicleInformation?.VIN ?? '' };
						return vinSchema;
					case 2:
						licenseSchema.defaultValues = {
							LicensePlate: vehicleInformation?.LicensePlate ?? '',
							State: vehicleState ?? '',
						};
						return licenseSchema;
					default:
						vinSchema.defaultValues = { VIN: vehicleInformation?.VIN ?? '' };
						return vinSchema;
				}
			// TODO: also add insurance  form info. 

			// switch (insuranceMethod) {
			// 	case InsuranceSelection.NONE:
			// 		noInsuranceSchema;
			// 		noInsuranceSchema.defaultValues = {
			// 			PromoCode: insuranceInfo?.PromoCode ?? '',
			// 		};
			// 		return noInsuranceSchema;
			// 	case InsuranceSelection.WITH:
			// 		insuranceSchema.defaultValues = {
			// 			Carrier: insuranceInfo?.Carrier ?? '',
			// 			Deductible: insuranceInfo?.Deductible ?? 0,
			// 			Policy: insuranceInfo?.Policy ?? '',
			// 		};
			// 		return insuranceSchema;
			// 	default:
			// 		noInsuranceSchema.defaultValues = {
			// 			PromoCode: insuranceInfo?.PromoCode ?? '',
			// 		};
			// 		return noInsuranceSchema;
			// }


			case 3:
				return damageSchema;
			case 4:
				return quoteSchema;
			case 5:
				return submitSchema;
			case 6: {
				// DYNAMICALLY SET THE DEFAULT VALUES FOR SCHEMA VALIDATION
				const requiredSchema = Object.keys(questionSchema.defaultValues).reduce<
					FormSchema['defaultValues']
				>((acc, curr) => {
					const question = additionalQuestions?.find(
						(question) => question.replace(/(\s)|(\/)/g, '_') === curr
					);
					const answer = answers?.find(
						(answer) => question && answer === question
					);
					if (!acc[curr] && question) {
						acc[curr] = answer ? 'yes' : '';
					} else if (!question) {
						acc[curr] = null;
					}
					return acc;
				}, {});
				// HUGE SHOUT OUT TO @gmonte: Guilherme Monte FOR THIS SOLUTION
				// https://github.com/jquense/yup/issues/559#issuecomment-682311746
				// Generating schema for answers dynamically
				questionSchema.defaultValues = requiredSchema;
				questionSchema.schema = schemaGenerator(requiredSchema);
				return questionSchema;
			}
			case 7:
				setResetForm(false); // Needs to be set to false so any time the insurance method changes it actually resets the form
				switch (insuranceMethod) {
					case InsuranceSelection.NONE:
						noInsuranceSchema;
						noInsuranceSchema.defaultValues = {
							PromoCode: insuranceInfo?.PromoCode ?? '',
						};
						return noInsuranceSchema;
					case InsuranceSelection.WITH:
						insuranceSchema.defaultValues = {
							Carrier: insuranceInfo?.Carrier ?? '',
							Deductible: insuranceInfo?.Deductible ?? 0,
							Policy: insuranceInfo?.Policy ?? '',
						};
						return insuranceSchema;
					default:
						noInsuranceSchema.defaultValues = {
							PromoCode: insuranceInfo?.PromoCode ?? '',
						};
						return noInsuranceSchema;
				}

			case 9:
				scheduleSchema.defaultValues = {
					ScheduleDate: appointment?.ScheduleDate ?? '',
					Slot: appointment?.Slot ?? '',
				};
				return scheduleSchema;
			case 10:
				return submitSchema;
			default:
				return customerInfoSchema;
		}
	}, [activeTab, additionalQuestions, answers, formOption]);
	// insuranceMethod

	// previous render form option steps. For reference until ready to delete.
	// case 1:
	// 	return <InitiateFlowForm jumpToStep={jumpToStep} />;
	// case 3:
	// 	return <ConfirmStoreForm updateStore={setUpdatedStore} />;

	// case 6:
	// 	return <AdditionalQuestionsForm handleVinSearch={searchByVin} />;
	// case 7:
	// 	return (
	// 		<InsuranceForm
	// 			handleInsuranceMethod={handleInsuranceSelection}
	// 			insuranceMethod={insuranceMethod}
	// 		/>
	// 	);

	// case 9:
	// 	return <ScheduleForm />;




	const renderFormOption = React.useCallback(
		(formOption: number) => {

			switch (formOption) {
				case 1:
					return <CustomerInfoForm jumpToStep={jumpToStep} />;
				case 2:
					return <VehicleDetailForm handleTabChange={setActiveTab}
						handleInsuranceMethod={handleInsuranceSelection}
						insuranceMethod={insuranceMethod} />;
				case 3:
					return <DamageAssessmentForm />;
				case 4:
					return <QuoteDetailsForm />;
				case 5:
					return <ReviewForm />;
				case 6:
					return <SubmissionPage />;
				default:
					return <></>;
			}
		},
		[formOption]
		// Removed dependency 
		// selectedStore
		// insuranceMethod
	);




	// Used to mutate data before it gets sent via API
	const handleFormSubmission = React.useCallback(
		(data: FieldValues) => {
			// console.log('HANDLE FORM SUBMISSION > ', data, formOption)

			let fullPayload = {};
			const leadId = LeadId ? { LeadId } : undefined;

			switch (formOption) {
				case 1: {
					// Save customer info before sending it off.
					const customerInfoFromData = data as CustomerPayload

					setCustomerInfo && setCustomerInfo(data as CustomerPayload);
					setSelectedStore && setSelectedStore(selectedStore);

					// console.log(' STEP ONE STORE >>> ', selectedStore)
					// console.log(' STEP ONE CUSTOMER INFO>>> ', customerInfoFromData)

					fullPayload = { ...data, customerInfo: customerInfoFromData, selectedStore: selectedStore };
					break;
				}
				// case 333:{
				// 	// Updated store is kept separate from selected store to
				// 	// prevent UI from updating upon user selection.
				// 	const storeSelection = updatedStore ?? selectedStore;
				// 	fullPayload = { ...storeSelection, ...leadId };
				// 	setSelectedStore && setSelectedStore(storeSelection);
				// 	break;}
				case 2: {
					switch (activeTab) {
						case 1:
							fullPayload = { ...data, activeTab: TabName.VIN };
							break;
						case 0:
							fullPayload = { ...data, activeTab: TabName.MANUAL };
							break;
						case 2:
							fullPayload = { ...data, activeTab: TabName.LICENSE };
							break;
						default:
							fullPayload = { ...data, activeTab: TabName.VIN };
							break;
					}

					const insurancePayload = {
						IsInsurance: insuranceMethod === InsuranceSelection.WITH,
						...leadId,
						PromoCode: promoCode,
						switch_to_with_insurance:
							switchedInsuranceMethod &&
							switchedInsuranceMethod === InsuranceSelection.WITH,
						switch_to_without_insurance:
							switchedInsuranceMethod &&
							switchedInsuranceMethod === InsuranceSelection.NONE,
					};

					// console.log('insurance >> ', insurancePayload)

					fullPayload = { ...fullPayload, insurancePayload }

					// TODO: add insurance to payload? case 4 for example
					break;
				}
				case 3:
					// console.log('SUBMIT FORM DAMAGE PAYLOAD > ', damageTypes)

					fullPayload = {
						DamageTypes: damageTypes?.map((item) => {
							// making a copy of the item to avoid mutating the damageTypes array
							item = JSON.parse(JSON.stringify(item));
							// resetting this field to null if altered for filtering purposes
							item.Size = null;
							return item;
						}),
						...leadId,
					};
					break;
				case 6: {
					const Answers: string[] = [];
					for (const questionOption in data) {
						if (Object.prototype.hasOwnProperty.call(data, questionOption)) {
							if (data[questionOption] && data[questionOption] !== 'no') {
								Answers?.push(
									questionOption === Questions.RAIN_LIGHT
										? 'Rain/Light Sensor'
										: questionOption.replace(/\_/g, ' ')
								);
							}
						}
					}
					setAnswers && setAnswers(Answers);
					fullPayload = { Answers, ...leadId };
					break;
				}
				case 4444:
					fullPayload = {
						...data,
						IsInsurance: insuranceMethod === InsuranceSelection.WITH,
						...leadId,
						PromoCode: promoCode,
						switch_to_with_insurance:
							switchedInsuranceMethod &&
							switchedInsuranceMethod === InsuranceSelection.WITH,
						switch_to_without_insurance:
							switchedInsuranceMethod &&
							switchedInsuranceMethod === InsuranceSelection.NONE,
					};
					break;
				case 8:
				case 10:
					break;
				default:
					if (data) {
						fullPayload = { ...data, ...leadId };
					}
					break;
			}
			// console.log("FULL PAYLOAD >> ", fullPayload)
			handleFormSubmit(fullPayload);
		},
		[
			activeTab,
			formOption,
			damageTypes,
			insuranceMethod,
			LeadId,
			promoCode,
			selectedStore,
			updatedStore,
		]
	);

	return (
		<BaseContactForm
			defaultValues={formValues.defaultValues}
			handleFormSubmit={handleFormSubmission}
			resetValues={resetForm || resetValues}
			schema={formValues.schema}
			formOption={formOption}
		>
			{renderFormOption(formOption as number)}
		</BaseContactForm>
	);
};
