import uuid4 from 'uuid4';
import Fallback from '../components/fallback';
import * as Components from '../components/sections';
import { ContentfulComponents } from '../components/sections';
import { PageContextProps } from '../templates/default-page.template';

type OverrideProps = {
  pageContext?: PageContextProps;
  [key: string]: any; // To allow for any other props
};


type ComponentProps = {
	id?: string | number;
	__typename: string;
};

export const ComponentMatch = (
	components?: any,
	typenameOverride?: string,
	overrideProps?: OverrideProps
) => {
	return (
		components &&
		components?.map((component: ComponentProps, index: number) => {
			const { id, __typename } = component;
			const updatedComponents = [
				'ContentfulInfoBanner',
				'ContentfulNewsSearch',
				'ContentfulForms',
				'ContentfulCustomSection',
			];
			const Component = updatedComponents.includes(__typename)
				? ContentfulComponents[typenameOverride ?? __typename] || Fallback
				: Components[typenameOverride ?? __typename] || Fallback;

			if (!Component) {
				return null;
			}
			return (
				<Component
					key={`${id} ${__typename} ${uuid4()}`}
					{...component}
					{...overrideProps}
				/>
			);
		})
	);
};
