import { DrivenLink, DrivenTypography } from '@drivenbrands/driven-components';
import ReactMarkdown from 'react-markdown';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeSlug from 'rehype-slug';

import {
	HeadingProps,
	LiProps,
	OrderedListProps,
	UnorderedListProps,
} from 'react-markdown/lib/ast-to-react';
import { GatsbyLinkBehavior } from '../gatsby-link-behavior';
import Heading from './heading';

type MarkdownProps = {
	children?: string;
	paragraphFontSize?: string;
};

// Seems to be reactMarkdown is loosely typed.
// paragraph has node and children. unsure about HR.
// Link has href, node and children.
// Possible to add more if needed.
// https://stackoverflow.com/a/66630179
type ATagProps = {
	href?: string;
	children?: React.ReactNode;
	node?: any;
	tabIndex?: number;
};

const markdownParagraph = (props: object, paragraphFontSize?: string) => {
	return (
		<DrivenTypography
			variant='body1'
			component='div'
			sx={{
				display: 'block',
				pb: 1,
				fontSize: paragraphFontSize ? `${paragraphFontSize}px` : 'unset',
			}}
			{...props}
		/>
	);
};
const markdownHR = (props: object) => {
	return <DrivenTypography component='hr' mt='4rem' {...props} />;
};
const markdownLink = (props: ATagProps) => {
	// If a MD link is empty return the text [example link]()
	const { href } = props;
	if (!href) {
		return (
			<DrivenTypography
				component='div'
				sx={{
					display: 'inline-flex',
				}}
				{...props}
			/>
		);
	}

	return (
		<DrivenTypography
			component='div'
			sx={{
				display: 'inline-flex',
			}}
		>
			<DrivenLink
				LinkComponent={GatsbyLinkBehavior}
				variant='body2Bold' // what is body2bold? seems to be in theme.js on components but not in typography variants?
				color='primary'
				href={href}
				{...props}
			/>
		</DrivenTypography>
	);
};

// types came from createTypography in MUI
type headingVariantTypes =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'inherit';

const markdownHeading = (
	variant: headingVariantTypes,
	component: string,
	styles: object,
	props: HeadingProps
) => {
	// unsure why we wrap in Heading. can use DrivenTypography directly.
	return (
		<Heading
			variant={variant}
			component={component}
			sx={{ display: 'block', ...styles }}
			{...props}
		/>
	);
};

const markdownList = (
	ordered: string,
	props: OrderedListProps | UnorderedListProps
) => {
	// ordered is not on Typography.
	// But it is still passed in with props. So commented out unless a need to bring back?
	return (
		<DrivenTypography
			component={ordered}
			// ordered={`${ordered}`}
			sx={{ display: 'block', listStyleType: 'disc' }}
			mt='1.25rem'
			mb='1.45rem'
			{...props}
		/>
	);
};
const markdownListItem = (props: LiProps) => {
	return (
		<DrivenTypography
			variant='body1'
			component='li'
			// ordered={`${ordered}`}
			{...props}
		/>
	);
};

// TODO: pass in optional style object that will override any styles on each component.
// example if you want to change h2 to have a different variant.
// Question? how will it target specific components? h3 for example?
// this generally should'nt be an issue. styles should be set once and not changed. But yet here we are.
const MarkdownContent = ({ children, paragraphFontSize }: MarkdownProps) => {
	if (!children || children.length < 0) {
		return <></>;
	}
	return (
		<ReactMarkdown
			rehypePlugins={[rehypeSlug, rehypeAutolinkHeadings]} // these add id's to headings and links to headings
			components={{
				a: (props) => {
					return markdownLink(props);
				},
				hr: (props) => {
					return markdownHR(props);
				},
				h1: (props) => {
					const styles = {
						fontWeight: '700', // Why change fontweight here? is it not set on styles?
						py: 3,
					};
					return markdownHeading('h1', 'h1', styles, props);
				},
				h2: (props) => {
					const styles = {
						py: 3,
					};
					return markdownHeading('h2', 'h2', styles, props);
				},
				h3: (props) => {
					// h3 gets styled as h6. not sure why or if this is still correct. // TODO: check if this is still correct.
					const styles = {
						py: 2, // 1rem was used for my previously. not sure why.
					};
					return markdownHeading('h6', 'h3', styles, props);
				},
				h4: (props) => {
					const styles = {
						py: 2, // 1rem was used for my previously. not sure why.
					};
					return markdownHeading('body2', 'h4', styles, props);
				},
				ul: (props) => {
					return markdownList('ul', props);
				},
				ol: (props) => {
					return markdownList('ol', props);
				},
				li: (props) => {
					return markdownListItem(props);
				},
				p: (props) => {
					return markdownParagraph(props, paragraphFontSize);
				},
			}}
		>
			{children}
		</ReactMarkdown>
	);
};

export default MarkdownContent;
