import {
	DrivenGrid,
	DrivenScrollableList,
	Marker
} from '@drivenbrands/driven-components';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { getMapMarker } from '../../../../utils';

import { StoreResultCard } from ".";
import { Store } from '../../../../services';

type StoreResultsProps = {
	mapLocation?: google.maps.places.PlaceGeometry;
	mapStatus: string;
	storeResults: Store[];
	handleStoreSelection: (store?: Store) => void;
};

export const StoreResults = ({
	handleStoreSelection,
	mapLocation,
	mapStatus,
	storeResults,
}: StoreResultsProps) => {
	const [activeMapMarker, setActiveMapMarker] = useState<
		ReactNode | undefined
	>();
	const [mapCenter, setMapCenter] = useState<
		google.maps.LatLngLiteral | undefined
	>();
	const [mapMarkers, setMapMarkers] = useState<JSX.Element[] | undefined>();
	const [selectedStore, setSelectedStore] = useState<Store>();
	const [scrollItem, setScrollItem] = useState<number | undefined>();
	const [storesResultsCards, setStoresResultsCards] = useState<
		ReactNode[] | undefined
	>();

	// Send up store selection regardless if there is value
	useEffect(() => {
		handleStoreSelection(selectedStore);
	}, [selectedStore]);

	const handleMapMarkerClick = (marker?: google.maps.Marker) => {
		setActiveMapMarker(marker?.getTitle());

		if (marker !== undefined) {
			setScrollItem(
				storeResults?.findIndex((store) => store.Company === marker.getTitle())
			);
			const store = storeResults?.find(
				(store) => store.Company === marker.getTitle()
			);
			setSelectedStore(store);

			const position = marker.getPosition();

			if (position && position.lat() && position.lng()) {
				setMapCenter({ lat: position.lat(), lng: position.lng() });
			}
		} else {
			setScrollItem(undefined);
			setSelectedStore(undefined);
		}
	};

	//  Generate the map markers for stores returned by the search API.
	// TODO, this is an unused legacy component. This has been refactored to use version 1.0 of the DrivenComponents and pass the logic and pins to the parent component.
	useEffect(() => {
		if (storeResults.length) {
			setMapMarkers(
				storeResults.map(({ ShopId, Lat, Long, Company }, i) => {
					const active = Boolean(activeMapMarker && Company === activeMapMarker);
					return (
						<Marker
							active={active}
							key={ShopId}
							onClick={handleMapMarkerClick}
							position={{ lat: Lat, lng: Long }}
							title={Company}
							markerUrl={getMapMarker(Company, active)}
						/>
					);
				})
			);
		} else {
			setMapMarkers(undefined);
		}
	}, [activeMapMarker, storeResults]);
	

	const handleStoreClick = useCallback(
		(store: Store) => {
			const storeDetails =
				selectedStore && store.ShopId === selectedStore.ShopId
					? undefined
					: store;
			if (storeDetails && storeDetails.Lat && storeDetails.Long) {
				setMapCenter({ lat: storeDetails.Lat, lng: storeDetails.Long });
			}
			setActiveMapMarker(storeDetails?.Company);
			setSelectedStore(storeDetails);
		},
		[mapMarkers, selectedStore]
	);

	//  Generate the list of cards for stores returned by the search API.
	useEffect(() => {
		if (storeResults.length) {
			setStoresResultsCards(
				storeResults.map((store, i) => {
					return (
						<StoreResultCard
							key={i}
							isSelected={selectedStore?.ShopId === store.ShopId}
							handleStoreSelection={handleStoreClick}
							store={store}
						/>
					);
				})
			);
		} else {
			setStoresResultsCards(undefined);
		}
	}, [selectedStore, storeResults, mapMarkers]);

	return (
		<DrivenGrid container direction='column'>
			{/* <DrivenGrid item >
				<DrivenTypography variant='body2'>
					Locations within 50 miles of you:
				</DrivenTypography>
			</DrivenGrid> */}
			<DrivenGrid item>
				{/* <DrivenGrid item > */}
				<DrivenScrollableList
					data-testid='locations-search-results'
					maxHeight='565px'
					scrollToItem={scrollItem}
				>
					{storesResultsCards}
				</DrivenScrollableList>
				{/* </DrivenGrid> */}
				{/* <DrivenGrid item xs={12} md={6}> */}
				{/* <DrivenBox sx={{ height: '560px' }}>
						<DrivenMap
							status={mapStatus as any}
							center={mapCenter}
							location={mapLocation}
							onClick={() => setActiveMapMarker(undefined)}
						>
							{mapMarkers}
						</DrivenMap>
					</DrivenBox> */}
				{/* </DrivenGrid> */}
			</DrivenGrid>
		</DrivenGrid>
	);
};
