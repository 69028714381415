import { SelectedLocation } from '@drivenbrands/driven-components';
import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useMemo,
	useState,
} from 'react';

export const DEFAULT_LOCATION = '';

type LocationContextType = {
	children?: ReactNode;
	storedSearchLocation: SelectedLocation;
	setStoredSearchLocation?: Dispatch<SetStateAction<SelectedLocation>>;
	searchLocation: SelectedLocation;
	setSearchLocation?: Dispatch<SetStateAction<SelectedLocation>>;
};

export const LocationContext = createContext<LocationContextType>({
	searchLocation: DEFAULT_LOCATION,
	storedSearchLocation: DEFAULT_LOCATION,
});

export const LocationProvider: FC<LocationContextType> = ({ children }) => {
	const [searchLocation, setSearchLocation] = useState<SelectedLocation>(DEFAULT_LOCATION);
	const [storedSearchLocation, setStoredSearchLocation] = useState<SelectedLocation>(DEFAULT_LOCATION);

	const contextValue = useMemo(() => ({
		searchLocation,
		setSearchLocation,
		storedSearchLocation,
		setStoredSearchLocation,
	}), [
		searchLocation,
		storedSearchLocation
	]);

	return (
		<LocationContext.Provider value={contextValue}>
			{children}
		</LocationContext.Provider>
	);
};
