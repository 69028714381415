/// ////////////////////////////
// API HEADER TYPES
/// ////////////////////////////
export type RequestHeaders = HeadersInit & {
	APIKEY: string;
};

/// ////////////////////////////
// API BODY TYPES
/// ////////////////////////////
export type Body =
	| AbandonPayload
	| CustomerPayload
	| DamageAssessmentPayload
	| GetStorePayload
	| InsurancePayload
	| LicensePlatePayload
	| ModelPayload
	| PromoPayload
	| QuestionsPayload
	| StorePayload
	| StylePayload
	| VehiclePayload
	| VinPayload
	| YearPayload
	| QuoteSubmissionPayload
	| AvailabilityPayload
	| AppointmentPayload
	| NewsletterPayload;

export type AbandonPayload = {
	LeadId: number;
};

export type GetStorePayload = {
	Zip: string;
	Distance: number;
};

// Customer API body options
export type CustomerPayload = {
	FirstName: string;
	LastName: string;
	Email: string;
	Phone: string;
	TextConsent: boolean;
	LeadId?: number;
};

// Store API body options
export type StorePayload = {
	ShopId: string;
	Banner: string | null;
	Company: string;
	Address: string;
	City: string;
	State: string;
	Zip: string;
	Lat: number;
	Long: number;
	Mobile: boolean;
	Distance: number;
	DistanceUnit: string;
	LeadId: number;
};

// Vehicle information API body options
//  // VIN Number
export type VinPayload = {
	VIN: string;
};
//  // License plate
export type LicensePlatePayload = {
	LicensePlate: string;
	State: string;
};
// // Year, Model, Style
export type YearPayload = {
	VehYear: number;
};
export type ModelPayload = {
	VehYear: number;
	VehMake: string;
};
export type StylePayload = {
	VehYear: number;
	VehMake: string;
	VehModel: string;
};

// // Save vehicle information
export type VehiclePayload = {
	CarId: string;
	VehYear: number;
	VehMake: string;
	VehModel: string;
	Style: string;
	VIN?: string;
	LicensePlate?: string;
	LeadId: number;
};

// Damage assessment
export type DamageType = {
	OpeningType: string;
	DamageType: string;
	Position: string | string[] | undefined | null;
	Size: string | null;
	Edges: string;
	Growing: string;
};

export type DamageAssessmentPayload = {
	DamageTypes: DamageType[];
	LeadId: number;
};

// Additional questions
export type QuestionsPayload = {
	Answers: string[];
	LeadId: number;
};

export type PromoPayload = {
	PromoCode: string;
};

// Insurance
export type InsurancePayload = {
	Carrier?: string;
	Deductible?: number;
	IsInsurance: boolean;
	LeadId: number;
	Policy?: string;
	PromoCode?: string;
	PromoAmount?: number;
};

export type QuoteSubmissionPayload = {
	LeadId: number;
};

// SCHEDULE SERVICES
export type AvailabilityPayload = {
	ShopId: string;
	Week: string;
	ShopMobile: number;
	QuotePk: number;
	WaitForVehicle: number;
};

export type AppointmentPayload = {
	LeadId: number;
	ScheduleDate: string;
	Slot: string;
};

export type NewsletterPayload = {
	email: string;
};

/// ////////////////////////////
// API RESPONSE TYPES
/// ////////////////////////////
export type QuoteResponse =
	| CustomerResponse
	| StepsResponse
	| StoreResponse
	| VehicleAPIResponse
	| GlassOptionResponse
	| DamageAssessmentResponse
	| QuestionsResponse
	| InsuranceResponse
	| QuoteSubmissionResponse
	| AvailabilityResponse
	| AppointmentResponse;

// When submitting customer information
export type CustomerResponse = {
	CustomerId: number;
	LeadId: number;
	Step: string;
	Success: boolean;
};

// Store Result when searching by zip code
export type Store = {
	ShopId: string;
	Banner?: string | null;
	Company: string;
	Address: string;
	City: string;
	State: string;
	Zip: string;
	Mobile: boolean;
	Distance: number;
	DistanceUnit: string;
	Lat: number;
	Long: number;
};
export type StoreResponse = {
	Success: boolean;
	Shops: Store[];
};

export type VehicleAPIResponse =
	| MakeResponse
	| ModelResponse
	| SaveVehicleResponse
	| StyleResponse
	| VehicleResponse;

// // Vehicle responses
// Make response
export type MakeResponse = {
	Success: boolean;
	Makes: string[];
};
// Model response
export type ModelResponse = {
	Success: boolean;
	Models: string[];
};
// Style response
export type StyleItem = {
	Style: string;
	CarId: string;
};
export type StyleResponse = {
	Success: boolean;
	Styles: StyleItem[];
};
// VIN and License response
export type VehicleResponse = {
	Success: boolean;
	Vehicle: {
		CarId: string;
		VehYear: number;
		VehMake: string;
		VehModel: string;
		Style: string;
		VIN?: string;
		LicensePlate?: string;
	};
};
// Add vehicle to quote
export type SaveVehicleResponse = {
	LeadId: number;
	Step: string;
	VehicleId: number;
	Success: boolean;
};

// Glass option response
export enum OpeningType {
	WINDSHIELD = 'Windshield',
	DOOR = 'Door',
	BACK_WINDOW = 'Back Window',
}

export enum Position {
	FR = 'FRONT_RIGHT',
	FL = 'FRONT_LEFT',
	RR = 'REAR_RIGHT',
	RL = 'REAR_LEFT',
}

export type GlassType = {
	OpeningType: string;
	Position: Array<string>;
	Question: Array<string>;
};

export type GlassOptionResponse = {
	Success: boolean;
	Options: {
		Message: string;
		ErrorCd: number;
		CarId: string;
		VehYear: number | null;
		Make: string | null;
		Model: string | null;
		Style: string | null;
		GlassTypes: GlassType[];
		VehicleId: number;
		LeadId: number | string;
	};
};

export type DamageAssessmentResponse = {
	DamageTypes: DamageType[];
	LeadId: number;
	Step: string;
	Success: boolean;
};

export type QuestionsResponse = {
	Answers: string[];
	LeadId: number;
	Step: string;
	Success: boolean;
};

export type InsuranceResponse = {
	Success: boolean;
	IsInsurance: boolean;
	Carrier: string;
	Deductible: number;
	Policy: string;
	PromoCode: string;
	PromoAmount: number;
};

export type QuoteDetails = {
	Message: string;
	ErrorCd: number;
	Vin?: string;
	VehCarId: string;
	DamageTypes: DamageType[];
	Insurance: string;
	Carrier: string | null;
	Deductible: number;
	ShopId: string;
	Mobile: boolean;
	PromoAmount: number;
	QuoteAmount: number;
	QuoteAmountInsurance: number;
	QuoteNum: string;
	QuotePk: number;
	RecallAmount: number;
	FirstAvailable: string;
};

export type QuoteSubmissionResponse = {
	Success: boolean;
	Quote: QuoteDetails;
	LeadId: number;
	QuotePk: number;
	Step: string;
};

export type DayOfWeek =
	| 'Sunday'
	| 'Monday'
	| 'Tuesday'
	| 'Wednesday'
	| 'Thursday'
	| 'Friday'
	| 'Saturday';

export type DayAvailable = {
	Day: DayOfWeek;
	Slots: string[];
};

export type AvailabilityResponse = {
	Success: boolean;
	Appointment: {
		Message: string;
		ErrorCd: number;
		StartWeek: string;
		EndWeek: string;
		Available: DayAvailable[];
	};
};

export type AppointmentConfirmation = {
	Message: string;
	ErrorCd: number;
	ShopId: string;
	ScheduleDate: string;
	Slot: string;
	QuotePk: number;
};

export type AppointmentResponse = {
	Success: boolean;
	AppointmentBooked: AppointmentConfirmation;
	LeadId: number;
	QuotePk: number;
	Step: string;
};

export type NewsletterResponse = {
	success: boolean;
};

// // Get steps by LeadId
// TODO: Change to new Version 2.0 steps. 

export type Steps = {
	Step1?: {
		Customer: CustomerPayload;
	};
	Step2?: {
		Store: StorePayload;
	};
	Step3?: {
		Vehicle: VehicleResponse['Vehicle'];
	};
	Step4?: {
		DamageTypes: DamageType[];
	};
	Step5?: {
		Answers: QuestionsPayload['Answers'];
	};
	Step6?: {
		Insurance: InsurancePayload;
	};
	Step7?: {
		Quote: QuoteDetails;
	};
	Step8?: AppointmentConfirmation;
};

export type StepsResponse = {
	Success: boolean;
	Steps: Steps;
	LeadId: number;
};

export const isError = (status: number) => status >= 400;
