import { LegacyStoreLocation, StoreLocation } from './types';

//Location_content is not in new api response. Need to generate from contentful.
// need placeholder content for now.
const locationPlaceholder = {
	heading: '',
	heading_es: '',
	content: '',
	content_es: '',
	title_tag: '',
	title_tag_es: '',
	meta_description: '',
	meta_description_es: '',
	windshieldRepairMobile: '', // comes from franconnect and needs to be updated on api.
};

// values needed on location_content
// {
// 	heading: '',
// 	heading_es: '',
// 	content: '',
// 	content_es: '',
// 	title_tag: '',
// 	title_tag_es: '',
// 	meta_description: '',
// 	meta_description_es: '',

// 	windshieldRepairMobile: '', // comes from franconnect and needs to be updated on api.
// }

// ---------------------------------------------
// not on new api but need values from old api
// current_brand
// historic_brand
// download.service_area  //  string like this Castle Pines|Castle Rock|Centennial|Commerce City|

// ---------------------------------------------
// Convert new api response to old api response shape.
// This avoids having to change the rest of the codebase in order to use the new api.
// This is a temporary solution until the new api is fully implemented.
// TODO: Remove this function and update the rest of the codebase to use the new api response shape. JB:12/03/23
export function transformNewLocationToOld(
	location: StoreLocation
): LegacyStoreLocation {
	const storeId = location.storeId;

	const legacyLocation: LegacyStoreLocation = {
		is_active: location.storeStatus === 'Open-Generating Business' ? 1 : 0, // TODO: only display active stores. API should only ever send active but check anyway JB:12/16/23
		current_brand: 'Auto Glass Now', // // FIXME: Verify this is correct JB:12/23
		historic_brand: 'Auto Glass Now', // // FIXME: not in response. Need to Generate. JB:12/23
		brand: location.brand || 'agn',
		sub_brand: location.division || 'Auto Glass Now (AGN)',
		store_address: location.address?.address || '',
		store_city: location.address?.city || '',
		store_state: location.address?.state || '',
		store_postcode: location.address?.zipcode || '',
		store_phone: location.meta?.contact?.phone1 || '',
		organic_phone: location.meta?.contact?.phone || '',
		store_id: parseInt(storeId),
		store_name: location.storeName,
		testimonials: location.meta?.testimonials || '',
		location_type: location.meta?.locationType || '',
		BU: '',
		hours: location.meta?.hours || defaultHours,
		store_lat: location.lat,
		store_long: location.lng,
		geo_address: location.address?.geoAddress || '',
		download: {
			service_area: '', // FIXME: Not in response. Need to Generate. JB:12/23
		},
		location_content: locationPlaceholder, // Not in response. Need to Generate from contentful.
		// success: true,
		distance: location.distance,
		storeStatus: location.storeStatus,
	};
	return legacyLocation;
}

// only used if no hours are found in new api
const defaultHours = {
	Monday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Tuesday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Wednesday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Thursday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Friday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Saturday: {
		isOpen: false,
		open: '',
		close: '',
	},
	Sunday: {
		isOpen: false,
		open: '',
		close: '',
	},
};
