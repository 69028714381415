import {
	DrivenBox,
	DrivenIcon,
	DrivenPromoCard,
	DrivenTextInput,
	DrivenTypography,
	IconTypes,
} from '@drivenbrands/driven-components';
import { graphql } from 'gatsby';

enum DiscountType {
	DOLLAR = 'Dollar Amount',
	OTHER = 'Other',
	PERCENT = 'Percent Off',
}

type PromotionProps = {
	conditions?: string;
	customDiscount?: string;
	discountType?: DiscountType;
	promotionAmount?: number;
	promoCode?: string;
	title: string;
	terms: string;
};

type PromotionTextProps = {
	promotionAmount?: number;
	discountType?: DiscountType;
	customDiscount?: string;
};

const PromotionText = ({
	promotionAmount,
	discountType,
	customDiscount,
}: PromotionTextProps) => {
	if (promotionAmount && discountType !== DiscountType.OTHER) {
		return (
			<>
				<DrivenTypography variant='h1' sx={{ paddingRight: '8px' }}>
					{discountType === DiscountType.DOLLAR && `$${promotionAmount}`}
					{discountType === DiscountType.PERCENT && `${promotionAmount}%`}
				</DrivenTypography>
				<DrivenTypography variant='h3Uppercase'>OFF</DrivenTypography>
			</>
		);
	}
	if (customDiscount && discountType === DiscountType.OTHER) {
		return (
			<DrivenTypography variant='h3Uppercase'>
				{customDiscount}
			</DrivenTypography>
		);
	}
	return <></>;
};

export const PromotionCard = ({
	conditions,
	customDiscount,
	discountType,
	promotionAmount,
	promoCode,
	title,
	terms,
}: PromotionProps) => {
	return (
		<DrivenBox flex={1}>
			<DrivenPromoCard
				actions={
					promoCode &&
					promoCode.trim().length && (
						<DrivenTextInput
							ariaLabel='Promo Code'
							disabled={
								promoCode === 'No code necessary' ||
								promoCode === 'No se necesita código'
							}
							label=''
							icon={<DrivenIcon icon={IconTypes.COPY} />}
							clickToCopy
							readOnly
							value={promoCode}
						/>
					)
				}
				collapsedContent={
					terms &&
					terms?.trim()?.length > 0 && (
						<DrivenTypography variant='body2' component='p'>
							{terms}
						</DrivenTypography>
					)
				}
				collapsedHeader={terms ? 'Terms & Exclusions' : undefined}
				conditions={conditions ?? ''}
				title={title}
				promotion={
					<PromotionText
						promotionAmount={promotionAmount}
						discountType={discountType}
						customDiscount={customDiscount}
					/>
				}
			/>
		</DrivenBox>
	);
};

export const PromotionCardQuery = graphql`
	fragment PromotionCardFields on ContentfulPromotionCard {
		__typename
		id
		name
		conditions
		customDiscount
		discountType
		promoCode
		promotionAmount
		title
		terms
	}
`;
