import { graphql } from 'gatsby';
import React from 'react';
import ContactRequest from './contactRequest';
import OnlineQuote from './onlineQuote';

export const ContentfulForms: React.FC<any> = (props) => {
	const { formName } = props;
	if (formName === 'ContactRequest') {
		return <ContactRequest {...props} />;
	}
	if (formName === 'Auto Glass & Windshield Online Quote') {
		return <OnlineQuote {...props} />;
	}
	return null;
};

export default ContentfulForms;

export const query = graphql`
	fragment FormFields on ContentfulForms {
		formName
		headline
		headlineLevel
		headlineStyle
		body {
			childMarkdownRemark {
				rawMarkdownBody
				html
			}
		}
		subscriptionConsent {
			childMarkdownRemark {
				rawMarkdownBody
				html
			}
		}
		__typename
	}
`;
