import {
	DrivenBox,
	DrivenTypography,
	LinkProps,
} from '@drivenbrands/driven-components';
import { TypographyProps } from '@mui/material/Typography';

type HeadingProps = TypographyProps & {
	children: React.ReactNode;
	component?: string;
	links?: LinkProps[];
	url?: string;
};

const Heading = ({
	children,
	component,
	links, // not being used in? maybe combine in ...rest?
	url, // not being used in? maybe combine in ...rest?
	...rest
}: HeadingProps) => {
	return (
		<DrivenBox
			sx={{
				display: ['block', 'block', 'flex'],
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<DrivenTypography
				sx={{
					fontWeight: 'bold',
					textTransform: { xs: 'none' },
				}}
				component={component}
				{...rest}
			>
				{children}
			</DrivenTypography>
		</DrivenBox>
	);
};

export default Heading;
