/* eslint-disable prefer-arrow-callback */
import {
	DrivenGrid,
	DrivenInputWithButton,
	DrivenTypography
} from '@drivenbrands/driven-components';
import React, { useContext, useState } from 'react';

import {
	captureCustomDataLayerEvent,
	MAINSTREET_URL,
	QuoteResponse,
	StepsResponse,
	Store,
	useQuoteService
} from '../../../services';
import {
	ErrorIcon,
	FormStateContext,
	GeocodingContext,
	LoadingIcon,
	searchOnEnter,
	StoreResults,
	StoreSearchContext
} from '../utils';

type InitiateFlowProps = {
	jumpToStep: (steps: Omit<StepsResponse, 'Success'>) => void;
};

export const InitiateFlowForm = React.memo(function InitiateFlowForm({
	jumpToStep,
}: InitiateFlowProps) {
	const { loading, setCustomerZip, setSelectedStore } = useContext(FormStateContext);

	// // For users with quote numbers
	const [expanded, setExpanded] = useState<string[]>([]); // Quote accordion
	const [quoteLoading, setQuoteLoading] = useState<boolean>(false);
	const [quoteResultError, setQuoteResultError] = useState<boolean>(false);

	// // For Google Maps functionality
	const { getLocationByZip, mapStatus } = useContext(GeocodingContext);
	const [mapLocation, setMapLocation] = useState<google.maps.places.PlaceGeometry>();

	// // For store results and store selection
	const { searchForStores, storesLoading, storeResults, storeResultsError } = useContext(StoreSearchContext);
	const [selection, setSelection] = useState<boolean>(false); // Will disable button if false

	const handleChange =
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (expanded.includes(panelTriggered)) {
					setExpanded(expanded.filter((panel) => panel !== panelTriggered));
				} else {
					setExpanded([...expanded, panelTriggered]);
				}
			};

	const handleStoreSelection = (store?: Store) => {
		// console.log('SET STORE VALUE >> ', store)
		setSelection(!!store);
		// Send up selected store to keep for the confirmation page.
		if (store && setSelectedStore) {
			// console.log("set selected store >> ", store)
			setSelectedStore(store);
		} else {
			setSelectedStore(undefined)
			// console.log(selectedStore)
		}
	};

	const getStores = (zip?: string) => {
		if (!zip || !zip?.trim()?.length) {
			return;
		}
		// Send up the searched zip code to keep for the confirmation page.
		setCustomerZip && setCustomerZip(zip);
		searchForStores && searchForStores(zip);
		// Google api search to move map to zip code boundaries
		getLocationByZip &&
			getLocationByZip(
				{ address: zip },
				(results: google.maps.GeocoderResult[] | null) => {
					if (results) {
						setMapLocation(results[0].geometry);
					}
				}
			);
	};

	// TODO: Find and handle returning quotes
	const searchByQuoteId = async (LeadID?: string) => {
		if (!LeadID || !LeadID?.trim()?.length) {
			return;
		}
		setQuoteLoading(true);
		setQuoteResultError(false);

		await useQuoteService({
			url: `${MAINSTREET_URL}get-steps/${LeadID}`,
			method: 'GET',
			onError: (error) => {
				setQuoteLoading(false);
				setQuoteResultError(error);
			},
			onSuccess: (response: QuoteResponse) => {
				setQuoteLoading(false);
				const stepResponse = response as StepsResponse;
				const { LeadId, Steps, Success } = stepResponse;
				if (Success && Steps) {
					captureCustomDataLayerEvent('returning_quote_submit', {
						oet_quote_id: LeadID,
					});
					jumpToStep({ Steps, LeadId });
				} else {
					setQuoteResultError(true);
				}
			},
		});
	};

	return (
		// <CardContainer>
		<DrivenGrid container direction='column' flexWrap='nowrap' spacing={2}>
			{/* <DrivenGrid item xs display='flex' justifyContent='center'> */}
			{/* <DrivenTypography variant='h5'>Get Started Today</DrivenTypography> */}
			{/* </DrivenGrid> */}
			{/* <DrivenGrid item xs display='flex' justifyContent='center'>
					<BodyText text='To get started, provide the zip code of where you’d like your vehicle to be serviced:' />
				</DrivenGrid> */}
			<DrivenGrid
				container
				direction='column'
				flexWrap='nowrap'
				item
				xs
				rowSpacing={2}
			>
				<DrivenGrid item xs>
					<DrivenTypography
						component='label'
						htmlFor='location-search'
						variant='body2'
					>
						Zip Code
					</DrivenTypography>
					<DrivenInputWithButton
						id='location-search'
						ariaLabel='Location search'
						buttonText='Search'
						errorHelperText='Bummer, there are currently no locations in your area. Don’t fret, we may be able to come to you with our mobile services! Contact us today at 1-800-300-8884 to get started.'
						errorIcon={<ErrorIcon />}
						hasError={storeResultsError}
						placeholder='Enter Zip Code'
						handleSubmit={getStores}
						onKeyDown={(e) => searchOnEnter(e, getStores)}
					/>
				</DrivenGrid>
				<DrivenGrid item xs>
					{storesLoading && <LoadingIcon />}
					{storeResults && (
						<StoreResults
							handleStoreSelection={handleStoreSelection}
							mapLocation={mapLocation}
							mapStatus={mapStatus ?? 'loading'}
							storeResults={storeResults}
						/>
					)}
				</DrivenGrid>
				{/* <DrivenGrid item xs>
						<DrivenButton
							type='submit'
							disabled={!selection}
							sx={{ width: '100%' }}
						>
							Get Started
						</DrivenButton>
					</DrivenGrid> */}
			</DrivenGrid>

			{/* // TODO: returning quote */}
			{/* <DrivenGrid item xs>
					<DrivenAccordion
						handleChange={handleChange}
						panelsOpen={expanded}
						records={[
							{
								summary: 'I already have a quote',
								details: (
									<DrivenInputWithButton
										ariaLabel='Quote number input'
										buttonText='Go'
										disableButton={quoteLoading || loading}
										loading={quoteLoading || loading}
										errorHelperText='The quote number you provided did not return a valid quote. Please try again.'
										errorIcon={<ErrorIcon />}
										hasError={quoteResultError}
										handleSubmit={searchByQuoteId}
										onKeyDown={(e) => searchOnEnter(e, searchByQuoteId)}
										placeholder='Enter Quote Number'
										spacing={0}
									/>
								),
							},
						]}
						plain
					/>
				</DrivenGrid> */}
		</DrivenGrid>
	);
});
