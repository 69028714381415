import { getStructuredStoreHours, StoreHours } from './structure-store-hours-from-file';

type StoreLocation = {
	BU: string;
	brand: string;
	current_brand: string;
	distance: string;
	geo_address: string;
	historic_brand: string;
	hours: StoreHours;
	is_active: number;
	location_type: string;
	mobileRepair: boolean;
	organic_phone: string;
	serviceArea: string;
	storeStatus: string;
	store_address: string;
	store_city: string;
	store_id: string;
	store_lat: number;
	store_long: number;
	store_name: string;
	store_phone: string;
	store_postcode: string;
	store_state: string;
	sub_brand: string;
	testimonials: string;
};

export const SEARCH_RADIUS_IN_MILES = 25;

export const mapStoreData = (store: any): StoreLocation => {
	return {
		BU: '',
		brand: 'AGN',
		current_brand: 'Auto Glass Now',
		distance: '',
		geo_address: '',
		historic_brand: 'Auto Glass Now',
		hours: getStructuredStoreHours(store),
		is_active: 1,
		location_type: 'Mobile',
		mobileRepair: true,
		organic_phone: store['Organic Tracking Number'],
		serviceArea: store['Service Areas'],
		storeStatus: 'Open-Generating Business',
		store_address: store['Street Address'],
		store_city: store.City,
		store_id: store['Franchise ID'],
		store_lat: store.Latitude,
		store_long: store.Longitude,
		store_name: store['Center Name'],
		store_phone: store['Organic Tracking Number'],
		store_postcode: store['Zip / Postal Code'],
		store_state: store['State / Province'],
		sub_brand: store['Brand Name'],
		testimonials: store.Testimonials,
	};
}

const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
	const toRadians = (degrees: number) => degrees * (Math.PI / 180);
	const R = 3959; // Radius of Earth in miles

	const dLat = toRadians(lat2 - lat1);
	const dLng = toRadians(lng2 - lng1);

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRadians(lat1)) *
		Math.cos(toRadians(lat2)) *
		Math.sin(dLng / 2) *
		Math.sin(dLng / 2);

	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return R * c; // Distance in miles
};

export const searchStoresFromFile = ({ lat, lng }, locations) => {
	const userLat = lat;
	const userLng = lng;

	return locations
		.map((location) => {
			const distance = calculateDistance(
				userLat,
				userLng,
				location.Latitude,
				location.Longitude
			);
			return { ...location, distance };
		})
		.filter((location) => location.distance <= SEARCH_RADIUS_IN_MILES)
		.sort((a, b) => a.distance - b.distance);
};


