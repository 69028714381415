import { DrivenBox, DrivenTypography } from '@drivenbrands/driven-components';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentfulImage from '../../contentful-image';
import MarkdownContent from '../../mdx/markdown-content';
import { BlogPost } from './news-search';

export function BlogFeatured(post: BlogPost) {
	const { title, featuredImage, excerpt, path, publishDate } = post;
	const theme = useTheme();
	const markdownWithLink = `${excerpt.childMarkdownRemark.rawMarkdownBody} [Continue Reading](${path})`;
	const isMobile = useMediaQuery('(max-width:600px)');
	return (
		<DrivenBox
			alignContent='center'
			p={4}
			mb={6}
			sx={{ bgcolor: `${theme.palette.grey[200]} !important` }}
		>
			{/* Main image */}
			<DrivenBox sx={{ position: 'relative' }} maxHeight={320} mb={2}>
				{featuredImage && (
					<ContentfulImage
						image={{
							gatsbyImageData: featuredImage.gatsbyImageData,
							description: featuredImage.description || title,
							title,
						}}
						imgStyle={{ height: 320, maxHeight: 320 }}
					/>
				)}
				<DrivenBox
					sx={{
						position: 'absolute',
						top: 32,
						left: 0,
						height: isMobile ? 30 : 36,
						width: isMobile ? 110 : 148,
						backgroundColor: `${theme.palette.secondary.light} !important`,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DrivenTypography variant='caption'>FEATURED</DrivenTypography>
				</DrivenBox>
			</DrivenBox>
			{publishDate && (
				<DrivenTypography variant='subtitle1' mb={1}>
					<time dateTime={publishDate}>{publishDate}</time>
				</DrivenTypography>
			)}
			{/* Title */}
			{title && (
				<DrivenTypography variant='h3' my={2}>
					{title}
				</DrivenTypography>
			)}
			{/* Body content. Text or markdown */}
			<DrivenBox
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					gap: '8px',
				}}
			>
				{excerpt && <MarkdownContent>{markdownWithLink}</MarkdownContent>}
			</DrivenBox>
		</DrivenBox>
	);
}
export default BlogFeatured;
