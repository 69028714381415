import {
	DrivenCTABanner,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { MSS3822_476_PRIMARY_LIGHT } from '../elevate/constants';
import { captureCustomDataLayerEvent } from '../services';

const CTABanner = (ctaBanner: Queries.CTABannerFieldsFragment) => {
	const { button, header } = ctaBanner;

	const theme = useTheme();

	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	// FIXME: CTA Banner is not using all or correct data from Contentful. ex handleEventCallback

	// const optionalStoreIdParameter = store_id ? { store_id } : {};
	const handleEventCallback = () => {
		captureCustomDataLayerEvent('find_a_location_click', {
			request_quote_cta_location: 'bottom_nav',
			// ...optionalStoreIdParameter
		});
	};
	return (
		<DrivenCTABanner
			ctaText={button?.text || ''}
			ctaProps={{
				href: button?.url || '',
				style: {
					marginTop: isTablet ? '20px' : 'unset',
					width: isTablet ? '100%' : 'unset',
					borderRadius: 30,
					marginRight: isDesktop ? '15%' : 'unset',
				},
				onClick: handleEventCallback,
			}}
			header={
				<DrivenTypography
					variant='h3'
					sx={{
						textTransform: { xs: 'none' },
						textAlign: 'left',
					}}
				>
					{header}
				</DrivenTypography>
			}
			sx={{
				pt: { xs: 0, sm: '100px' },
				pb: { xs: 0, sm: '100px' },
				backgroundColor: MSS3822_476_PRIMARY_LIGHT,
			}}
		/>
	);
};

export default CTABanner;

export const query = graphql`
	fragment CTABannerFields on ContentfulCtaBanner {
		__typename
		node_locale
		header
		contentful_id
		button {
			__typename
			page {
				slug
				name
			}
			url
			contentful_id
			disabled
			fullWidth
			text: contentfulchildren
			url
			variant
			gtmEventName
			gtmAdditionalFields {
				internal {
					content
				}
			}
		}
	}
`;
