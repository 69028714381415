import { storeIdsWithPrepositionAtForH1 } from './exceptions-location-seo';

interface StoreHours {
	open: string;
	close: string;
	isOpen: boolean;
}

interface OpeningHoursSpecification {
	'@type': string;
	dayOfWeek: string;
	opens?: string;
	closes?: string;
}

interface Address {
	streetAddress: string;
	addressLocality: string;
	addressRegion: string;
	postalCode: string;
	addressCountry: string;
}

interface StructuredData {
	'@context': string;
	'@type': string;
	name: string;
	image: string;
	'@id': string;
	url: string;
	telephone: string;
	address: Address;
	openingHoursSpecification: OpeningHoursSpecification[];
}

const removeLeadingAddressNumbers = (address: string): string => {
	return address.replace(/^\d+\s*/, '').replace(/\./g, ' ').trim();
}

export const generateHeading = (address: string, storeId: string): string => {
	const formattedAddress = removeLeadingAddressNumbers(address);
	const preposition: 'at' | 'on' = storeIdsWithPrepositionAtForH1.includes(storeId) ? 'at' : 'on';

	return `Windshield Replacement & Repair ${preposition} ${formattedAddress}`;
}

export const generateTitle = (storeName: string, city: string, storeId: string): string => {
	const brand = storeName.includes('Jack Morris') ? 'Jack Morris Auto Glass' : 'Auto Glass Now';

	return `Windshield Replacement & Repair in ${city} | ${brand} ${storeId}`;
}

export const generateDescription = (city: string, address: string): string => {
	const formattedAddress = removeLeadingAddressNumbers(address);

	return `Windshield replacement & repair near you in ${city} on ${formattedAddress}. Auto Glass Now offers low price quality service. Get a Free Online Quote!`;
}

export const generateOpeningHoursSpecification = (hours: Record<string, StoreHours>): OpeningHoursSpecification[] => {
	return Object.keys(hours)
		.map((day) => {
			const { open, close, isOpen } = hours[day];
			return {
				'@type': 'OpeningHoursSpecification',
				dayOfWeek: day,
				opens: isOpen ? open : undefined,
				closes: isOpen ? close : undefined,
			};
		})
		.filter((spec) => spec.opens);
};

export const generateStructuredData = (
	storeName: string,
	imageUrl: string,
	storeIdUrl: string,
	storePhone: string,
	address: Address,
	openingHoursSpecification: OpeningHoursSpecification[]
): StructuredData => {
	return {
		'@context': 'https://schema.org',
		'@type': 'AutoBodyShop',
		name: storeName,
		image: imageUrl,
		'@id': storeIdUrl,
		url: storeIdUrl,
		telephone: storePhone,
		address,
		openingHoursSpecification,
	};
};

export const generateHeadData = (
	storeLocation: any,
	contentfulLocationContent: any,
	detailPage: any,
) => {
	const store = storeLocation;
	const seoTitle = contentfulLocationContent?.titleTag || generateTitle(store.store_name, store.store_city, store.store_id);
	const seoDescription = contentfulLocationContent?.metaDescription || generateDescription(store.store_city, store.store_address);
	const canonicalUrl = `https://www.autoglassnow.com/${store.path}/`

	const ogImageUrl = detailPage?.nodes[0]?.blocks?.find((block: any) => block.__typename === 'ContentfulHero')?.media?.gatsbyImageData?.images?.fallback?.src;

	const openingHoursSpecification = generateOpeningHoursSpecification(store.hours);

	const address = {
		streetAddress: store.store_address,
		addressLocality: store.store_city,
		addressRegion: store.store_state,
		postalCode: store.store_postcode,
		addressCountry: 'US',
	};

	const structuredData = generateStructuredData(
		store.store_name,
		store.image || '',
		canonicalUrl,
		store.store_phone,
		address,
		openingHoursSpecification
	);

	return {
		seoTitle,
		seoDescription,
		canonicalUrl,
		ogImageUrl,
		structuredData,
	};
};
