import { APIRequestHeaders } from '@drivenbrands/gatsby-source-locations/src/types';
import { isError, NewsletterPayload, NewsletterResponse } from '.';

const API_NEWSLETTER_KEY = process.env.GATSBY_API_KEY;
const API_NEWSLETTER_URL = process.env.GATSBY_BASE_API_URL;
export type NewsletterServiceProps = {
	body?: NewsletterPayload;
	onError?: (error: boolean) => void;
	onSuccess?: (response: NewsletterResponse) => void;
};

export const useNewsletterService = async ({
	body,
	onError,
	onSuccess,
}: NewsletterServiceProps) => {
	return fetch(`${API_NEWSLETTER_URL}newsletter`, {
		method: 'POST',
		headers: {
			apikey: API_NEWSLETTER_KEY,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		} as APIRequestHeaders,
		body: JSON.stringify(body),
	})
		.then(async (response) => {
			const { success, ...responseData } = await response.json();
			const { ok, status } = response;
			const hasError = !ok || isError(status) || !success;

			if (hasError) {
				onError && onError(hasError);
			} else {
				onSuccess && onSuccess({ ...responseData, success });
			}
		})
		.catch((error) => {
			console.log('NEWSLETTER SUBMIT ERROR ', error);
			onError && onError(true);
		});
};
