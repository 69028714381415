import {
	DrivenBox,
	DrivenContainer,
	DrivenTabs,
	TextSize,
} from '@drivenbrands/driven-components';

import { useMediaQuery, useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { SyntheticEvent, useState } from 'react';
import { ComponentMatch } from '../hooks';

interface TabsProps {
	tabs: Array<any>;
}

export default function Tabs({ tabs }: TabsProps) {
	const defaultTheme = useTheme();
	const isResponsive = useMediaQuery(defaultTheme.breakpoints.down('md'));

	const [value, setValue] = useState(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const tabsWithContent = tabs.map(({ name, content }: any) => ({
		label: name,
		tabPanelContent: (
			<DrivenBox display='flex' flexDirection={isResponsive ? 'column' : 'row'}>
				{ComponentMatch(content)}
			</DrivenBox>
		),
	}));

	return (
		<DrivenContainer>
			<DrivenBox sx={{ my: { xs: 5, md: 0 } }}>
				<DrivenTabs
					tabs={tabsWithContent}
					tabVariant={TextSize.large}
					value={value}
					onChange={handleChange}
					sx={{
						button: {
							maxWidth: '100%',
						},
					}}
				/>
			</DrivenBox>
		</DrivenContainer>
	);
}

export const query = graphql`
	fragment TabsFields on ContentfulTabs {
		__typename
		tabs {
			... on ContentfulTab {
				__typename
				id
				contentful_id
				name
				content {
					...CardFields
				}
			}
		}
	}
`;
