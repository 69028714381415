import {
	DrivenBox,
	DrivenGrid,
	DrivenIcon,
	DrivenTypography,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import { useMediaQuery } from '@mui/material';
import { navigate } from 'gatsby';
import ContentfulImage from '../../contentful-image';

const RelatedPosts = ({ posts }) => {
	// Randomly select 3 posts // correct this logic after posts exist / biz decides related logic LM/8/1/23
	// images will render as they are uploaded, ratio logic may need fine tuning after content exists
	const selectedPosts = posts.sort(() => 0.5 - Math.random()).slice(0, 3);

	const isMobile = useMediaQuery('(max-width:600px)');
	const truncateTitle = (title: string, length = 35) => {
		if (title.length <= length) return title;
		return `${title.substring(0, length)}...`;
	};

	return (
		<>
			<DrivenTypography variant='h2' sx={{ mt: 5, mb: 3, textAlign: 'center' }}>
				Related Posts
			</DrivenTypography>
			<DrivenGrid container spacing={3}>
				{selectedPosts.map((post) => (
					<DrivenGrid item xs={12} sm={4} key={post.id}>
						<DrivenBox
							sx={{
								boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
								height: { xs: 84, sm: 282 },
								display: { xs: 'flex', sm: 'grid' },
								gridTemplateRows: { sm: '2fr 1fr' },
								overflow: 'hidden',
								position: 'relative',
								cursor: 'pointer',
								mb: 3,
							}}
							onClick={async () => {
								await navigate(`/blog/${post.slug}`);
							}}
						>
							<ContentfulImage
								image={{
									gatsbyImageData: post.featuredImage?.gatsbyImageData,
									description: post.featuredImage?.description || post.title,
									title: post.title,
								}}
								loading='eager'
								imgStyle={{
									objectFit: 'cover',
									height: '100%',
								}}
								imgStyleMobile={{
									// styles for mobile screens
									objectFit: 'cover',
									height: '84px',
									width: '101px',
								}}
							/>
							<DrivenBox
								sx={{
									bg: 'rgba(255, 255, 255, 0.8)',
									p: 2,
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flex: { xs: '1 0 67%', sm: '1 0 100%' },
								}}
							>
								<DrivenTypography variant='body1' sx={{ flex: '1 0 auto' }}>
									{truncateTitle(post.title)}
								</DrivenTypography>
								<DrivenIcon
									icon={IconTypes.ARROW_FORWARD}
									size={IconSizes.LG}
								/>
							</DrivenBox>
						</DrivenBox>
					</DrivenGrid>
				))}
			</DrivenGrid>
		</>
	);
};

export default RelatedPosts;
