import { DrivenToast, DrivenTypography } from '@drivenbrands/driven-components';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import {
	MAINSTREET_URL,
	QuoteResponse,
	useQuoteService,
} from '../../../../services';

type PromoContextType = {
	children?: React.ReactNode;
	promoCode?: string;
	promoLoading?: boolean;
	promoError?: boolean;
	setPromoCode?: Dispatch<SetStateAction<string | undefined>>;
	validatePromoCode?: (promo: string) => void;
};

export const PromoValidationContext = React.createContext<PromoContextType>({
	promoCode: undefined,
	promoLoading: false,
	promoError: false,
	setPromoCode: undefined,
	validatePromoCode: () => {},
});

export const PromoValidationProvider: FC<PromoContextType> = ({ children }) => {
	const [promoCode, SetPromoCode] = useState<string | undefined>();
	const [promoLoading, setPromoLoading] = useState<boolean>(false);
	const [promoError, setPromoError] = useState<boolean>(false);
	const [showToast, setShowToast] = useState(false);

	const validatePromoCode = async (promo: string) => {
		setPromoLoading(true);
		setPromoError(false);
		// Mainstreet API validate promo code
		await useQuoteService({
			url: `${MAINSTREET_URL}validate-promo-code`,
			body: {
				PromoCode: promo,
			},
			onError: (error) => {
				setPromoLoading(false);
				setPromoError(error);
			},
			onSuccess: (response: QuoteResponse) => {
				setPromoLoading(false);
				if (response.Success) {
					setPromoError(false);
					setShowToast(true);
					SetPromoCode(promo);
				} else {
					setPromoError(true);
				}
			},
		});
	};

	return (
		<PromoValidationContext.Provider
			value={{
				promoCode,
				promoLoading,
				promoError,
				setPromoCode: SetPromoCode,
				validatePromoCode,
			}}
		>
			{showToast && (
				<DrivenToast
					open={showToast}
					handleClose={() => setShowToast(false)}
					severity='success'
					title='Your promo code has been applied to your purchase.'
				>
					<DrivenTypography>
						You will see the final value upon quote submission.
					</DrivenTypography>
				</DrivenToast>
			)}
			{children}
		</PromoValidationContext.Provider>
	);
};
