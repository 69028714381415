
interface FallbackProps {
	__typename: string;
}

export default function Fallback({ __typename }: FallbackProps) {

	console.error(
		`No component found for: ${JSON.stringify(__typename, null, 2)}`
	);
	return <></>;
}
