import {
	BaseContentGrid as DrivenBaseContentGrid,
	DrivenContainer,
	HeadingLevel,
	SubheaderSize,
} from '@drivenbrands/driven-components';

import { ComponentMatch } from '../hooks';

type BodySize = 'body1' | 'body2';
type HeaderSize = 'h1' | 'h2' | SubheaderSize;

type DuplexProps = {
	brand?: string;
	brandColor?: string;
	heading?: string;
	headingSize?: HeaderSize;
	headingLevel?: HeadingLevel;
	subText?: string;
	subTextSize?: BodySize;
	id: string;
	name: string;
	sections: any[];
};
export function Duplex({
	brand,
	brandColor,
	heading,
	headingLevel,
	headingSize,
	subText,
	subTextSize,
	sections,
}: DuplexProps) {

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenBaseContentGrid
				block
				header={heading}
				headerAlign='flex-start'
				headingLevel={headingLevel}
				headerSize={headingSize}
				subText={subText}
				bodySize={subTextSize}
			>
				{ComponentMatch(sections, brand, brandColor)}
			</DrivenBaseContentGrid>
		</DrivenContainer>
	);
}

export default Duplex;
