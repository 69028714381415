import { FC, createContext, useEffect, useMemo, useState } from 'react';

type LOCALE_TYPE = {
	locale: string;
	seoLocale?: string;
	display: string;
};

const LOCALE_OPTIONS: Array<LOCALE_TYPE> = [
	{
		locale: 'en-US',
		seoLocale: 'en-US',
		display: 'EN',
	},
];

export const DEFAULT_LOCALE = LOCALE_OPTIONS[0];

// only need to check start of pathname due to business logic, default zero essentially means checks begin at:
// www.drivenbrands.com/es/path/to-page
// ==================^^==========
const DEFAULT_PATHNAME_ARRAY_POSITION_OF_LOCALE = 0;

const generateNewUrlWithLocale = (
	urlPath: string | string[],
	newLocale: LOCALE_TYPE
) => {
	let pathnameToArray: string[] = [];
	if (typeof urlPath === 'string') {
		pathnameToArray = urlPath.split('/');
	} else if (Array.isArray(urlPath)) {
		pathnameToArray = urlPath;
	}
	const cleanedPathname = pathnameToArray.filter((p) => p);

	let modifiedPathname = [...cleanedPathname];

	const doesPathContainLocale = LOCALE_OPTIONS.some(
		(option) =>
			option.locale ===
			cleanedPathname[DEFAULT_PATHNAME_ARRAY_POSITION_OF_LOCALE]
	);

	if (doesPathContainLocale) {
		modifiedPathname = cleanedPathname.slice(1);
	}

	const newUrlPath = `/${modifiedPathname.join('/')}`;

	const newUrl = newLocale.display === DEFAULT_LOCALE.display
		? newUrlPath
		: `/${newLocale.locale}${newUrlPath}`;

	// append leading and trailing slashes in order to follow Gatsby conventions
	return newUrl.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1/') || '/';
};

const getLocaleByDisplayName = (displayName: string) => {
	const matchingLocaleByDisplayName = LOCALE_OPTIONS.find(
		(option: LOCALE_TYPE) => option.display === displayName
	);

	return matchingLocaleByDisplayName ?? DEFAULT_LOCALE;
};

const getLocaleFromPathname = (pathname: string) => {
	const pathnameToArray = pathname.split('/').filter((p) => p);

	const matchingLocaleDetails = LOCALE_OPTIONS.findIndex(
		(option: LOCALE_TYPE) =>
			option.locale ===
			pathnameToArray[DEFAULT_PATHNAME_ARRAY_POSITION_OF_LOCALE]
	);

	return matchingLocaleDetails === -1
		? LOCALE_OPTIONS[0] // no match found, default to en-US locale
		: LOCALE_OPTIONS[matchingLocaleDetails];
};

type LocaleContextType = {
	// children?: React.ReactNode;
	isLocaleSpanish: boolean;
	locale: LOCALE_TYPE;
	localeOptions: Array<LOCALE_TYPE>;
	setLocale?: React.Dispatch<React.SetStateAction<LOCALE_TYPE>>;
	getLocaleByDisplayName: (displayName: string) => LOCALE_TYPE;
	generateNewUrlWithLocale: (
		path: string | string[],
		newLocale: LOCALE_TYPE
	) => string;
};

type LocaleProviderProps = {
	children?: React.ReactNode;
	pathname?: string;
};

export const LocaleContext = createContext<LocaleContextType>({
	isLocaleSpanish: false,
	locale: DEFAULT_LOCALE,
	localeOptions: LOCALE_OPTIONS,
	getLocaleByDisplayName: () => DEFAULT_LOCALE,
	generateNewUrlWithLocale,
});

export const LocaleProvider: FC<LocaleProviderProps> = ({ children, pathname = '/' }) => {

	const [locale, setLocale] = useState<LOCALE_TYPE>(getLocaleFromPathname(pathname));

	useEffect(() => {
		// url location is source of truth, set to url if pathname contains valid locale
		setLocale(getLocaleFromPathname(window.location.pathname));
	}, []);

	const isLocaleSpanish = locale.locale === 'es';

	const contextValue = useMemo(() => ({
		isLocaleSpanish,
		localeOptions: LOCALE_OPTIONS,
		locale,
		setLocale,
		getLocaleByDisplayName,
		generateNewUrlWithLocale,
	}), [isLocaleSpanish, locale, setLocale]);

	return (
		<LocaleContext.Provider
			value={contextValue}
		>
			{children}
		</LocaleContext.Provider>
	);
};
