import {
	DrivenBox,
	DrivenIcon,
	DrivenLink,
	IconTypes,
} from '@drivenbrands/driven-components';

type PaginationProps = {
	currentPage: number;
	totalBlogPages: number;
};

export const Pagination = ({
	currentPage,
	totalBlogPages,
}: PaginationProps) => {
	const prevPage = currentPage > 2 ? `/blog/${currentPage - 1}` : '/blog';

	return (
		<DrivenBox display='flex' justifyContent='center' alignItems='center'>
			{currentPage > 1 ? (
				<DrivenLink href={prevPage}>
					<DrivenIcon icon={IconTypes.CHEVRON_LEFT} />
				</DrivenLink>
			) : (
				<DrivenIcon icon={IconTypes.CHEVRON_LEFT} sx={{ color: 'lightgrey' }} />
			)}
			{Array.from({ length: totalBlogPages }, (_, i) => {
				const page = i + 1;
				const href = page === 1 ? '/blog' : `/blog/${page}`; // Adjust href for the first page
				return (
					<DrivenLink
						key={i}
						href={href}
						sx={{
							px: 2,
							fontWeight: currentPage === page ? 'bold' : 'normal',
							'&:hover': {
								backgroundColor: 'lightgrey',
								textDecoration: 'none',
							},
						}}
					>
						{page}
					</DrivenLink>
				);
			})}
			{currentPage < totalBlogPages ? (
				<DrivenLink
					href={currentPage === 1 ? '/blog/2' : `/blog/${currentPage + 1}`}
				>
					<DrivenIcon icon={IconTypes.CHEVRON_RIGHT} />
				</DrivenLink>
			) : (
				<DrivenIcon
					icon={IconTypes.CHEVRON_RIGHT}
					sx={{ color: 'lightgrey' }}
				/>
			)}
		</DrivenBox>
	);
};
