import { DrivenContainer } from '@drivenbrands/driven-components';
import { graphql } from 'gatsby';
import MarkdownContent from './mdx/markdown-content';

interface TextProps {
	text: {
		id: string;
		childMarkdownRemark: {
			rawMarkdownBody: string;
		};
	};
	paragraphFontSize?: string;
}

export default function TextBlock(props: TextProps) {
	const { text,paragraphFontSize } = props;
	return (
		<DrivenContainer>
			<MarkdownContent paragraphFontSize={paragraphFontSize}>
				{text.childMarkdownRemark.rawMarkdownBody}
			</MarkdownContent>
		</DrivenContainer>
	);
}

export const query = graphql`
	fragment TextBlockFields on ContentfulTextBlock {
		__typename
		id
		text {
			id
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
		paragraphFontSize
	}
`;
