import { add, eachYearOfInterval } from 'date-fns';

export const defaultOption = [{ value: '', option: 'Select One' }];

export const insuranceCarriers = [
	{ value: '', option: 'Select One' },
	{
		value: 'Allstate',
		option: 'Allstate',
	},
	{
		value: 'American Family Travelers',
		option: 'American Family Travelers',
	},
	{
		value: 'Farmers',
		option: 'Farmers',
	},
	{
		value: 'Geico',
		option: 'Geico',
	},
	{
		value: 'Liberty Mutual',
		option: 'Liberty Mutual',
	},
	{
		value: 'Nationwide',
		option: 'Nationwide',
	},
	{
		value: 'Progressive',
		option: 'Progressive',
	},
	{
		value: 'State Farm',
		option: 'State Farm',
	},
	{
		value: 'USAA',
		option: 'USAA',
	},
	{ value: 'Other', option: 'Other' },
];

export enum Questions {
	ACOUSTIC = 'Acoustic',
	ADAS = 'ADAS',
	CONDENSATION = 'Condensation',
	ELECTRO = 'Electro',
	FORWARD_COLLISION = 'Forward_Collision',
	HEATED = 'Heated',
	HEATED_WIPER = 'Heated_Wiper_Park',
	HUMIDITY = 'Humidity_Sensor',
	HUD = 'HUD',
	HIBEAMS = 'Intelligent_HiBeams',
	LANE_DEPART = 'Lane_Departure',
	LOGO = 'Logo',
	RAIN_LIGHT = 'Rain_Light_Sensor',
	RAIN = 'Rain_Sensor',
	VISOR_FRIT = 'Visor_Frit',
	SOLAR = 'Solar',
}

export const questionOptions = [
	{
		value: Questions.ACOUSTIC,
		option:
			'Is your glass made with an additional insulation that reduces the sound inside your vehicle? You can tell because it is noted in the trademark stamp at the bottom corner of your windshield. The glass may say something like “Acoustic”, “Acoustigate” or “Sound Master”. Usually included in vehicles with premium sound systems.',
	},
	{
		value: Questions.ADAS,
		option:
			'Advanced Driver Assistance System electronic technologies that assist with driving and parking functions.',
	},
	{
		value: Questions.CONDENSATION,
		option:
			'Do you have small vent holes in the bracket that holds your rear-view mirror?',
	},
	{
		value: Questions.ELECTRO,
		option:
			'Does your rear-view mirror sense when light reflects from behind and automatically dim? There isn’t a tab to flip the mirror up and down and this is often noted in your user manual. Also called auto dimming mirrors.',
	},
	{
		value: Questions.FORWARD_COLLISION,
		option:
			'Does your vehicle have forward collision alert? Your brakes may engage automatically or alarm sounds when approaching an object.',
	},
	{
		value: Questions.HEATED,
		option:
			'Do you have very small grid lines all over the glass to defrost it? This is similar to a condensation sensor, heated windshields have small wires throughout the glass that heat up when activated, keeping the glass from icing up or fogging.',
	},
	{
		value: Questions.HEATED_WIPER,
		option:
			'Does the windshield have heating grids / lines along the bottom to keep wiper blades heated in the winter? Heating elements inside the windshield glass by the wiper park area prevent windshield wipers from icing up.',
	},
	{
		value: Questions.HUMIDITY,
		option:
			'Is your vehicle equipped with a sensor that will measure and report the relative humidity and change in temperature within the vehicle?',
	},
	{
		value: Questions.HUD,
		option:
			'Do you have technology in which your vehicles instrument projects onto the windshield, usually in front of the driver? Holographic display projected onto the windshield.',
	},
	{
		value: Questions.HIBEAMS,
		option:
			'Does your vehicle have intelligent High Beams that turn off when another car is approaching ? During nighttime driving, the automatic high beam system automatically turns on your high beams if it is dark enough and no other traffic is present.',
	},
	{
		value: Questions.LANE_DEPART,
		option:
			'Is your vehicle equipped with LED turn signal indicators on the side mirrors? Is your vehicle equipped with a sensor or camera which will warn you of an impending lane change, or if you are drifting into a lane without a signal?',
	},
	{
		value: Questions.LOGO,
		option:
			'Does your vehicle have the (Vehicle Make) logo on the top middle of the windshield or along the bottom?',
	},
	{
		value: Questions.RAIN,
		option:
			'Is your vehicle equipped with a sensor that senses rain and will turn the wipers on and control them with the speed the of rain? A special attachment that communicates with the car’s computer to turn on, off and regulate the speed of windshield wipers.',
	},
	{
		value: Questions.RAIN_LIGHT,
		option:
			'Is your vehicle equipped with a sensor that senses rain and will turn the wipers on and control them with the speed the of rain? A special attachment that communicates with the car’s computer to turn on, off and regulate the speed of windshield wipers.',
	},
	{
		value: Questions.VISOR_FRIT,
		option:
			'Do you have black dots that look like a visor behind your rear-view mirror? This is a series of black dots in your windshield behind your rear-view mirror. It is designed to keep the sun out of your eyes while in the awkward space in the top middle of your windshield between your fold down visors.',
	},
	{
		value: Questions.SOLAR,
		option:
			"Does your windshield have a solar coating? Solar coated glass(common) blocks the sun's UV rays.Solar Control glass contains reflective material within the glass to help reduce the amount of heat passed to the vehicle's interior.",
	},
];

export const repairList = [
	'The damage to the windshield is smaller than a quarter',
	`The crack hasn't started to "spider" and grow in size`,
	`The chip isn't on the edge of the windshield, affecting structural integrity of the windshield`,
	`The crack hasn't gone all the way through the windshield`,
];

export const replaceList = [
	'If your windshield has multiple stone chips or you have a cracks larger than 10 inches, a windshield replacement is most likely required',
];

export const usStates = [
	{ value: '', option: 'Select One' },
	{ value: 'AL', option: 'Alabama' },
	{ value: 'AK', option: 'Alaska' },
	{ value: 'AZ', option: 'Arizona' },
	{ value: 'AR', option: 'Arkansas' },
	{ value: 'CA', option: 'California' },
	{ value: 'CO', option: 'Colorado' },
	{ value: 'CT', option: 'Connecticut' },
	{ value: 'DE', option: 'Delaware' },
	{ value: 'DC', option: 'District Of Columbia' },
	{ value: 'FL', option: 'Florida' },
	{ value: 'GA', option: 'Georgia' },
	{ value: 'HI', option: 'Hawaii' },
	{ value: 'ID', option: 'Idaho' },
	{ value: 'IL', option: 'Illinois' },
	{ value: 'IN', option: 'Indiana' },
	{ value: 'IA', option: 'Iowa' },
	{ value: 'KS', option: 'Kansas' },
	{ value: 'KY', option: 'Kentucky' },
	{ value: 'LA', option: 'Louisiana' },
	{ value: 'ME', option: 'Maine' },
	{ value: 'MD', option: 'Maryland' },
	{ value: 'MA', option: 'Massachusetts' },
	{ value: 'MI', option: 'Michigan' },
	{ value: 'MN', option: 'Minnesota' },
	{ value: 'MS', option: 'Mississippi' },
	{ value: 'MO', option: 'Missouri' },
	{ value: 'MT', option: 'Montana' },
	{ value: 'NE', option: 'Nebraska' },
	{ value: 'NV', option: 'Nevada' },
	{ value: 'NH', option: 'New Hampshire' },
	{ value: 'NJ', option: 'New Jersey' },
	{ value: 'NM', option: 'New Mexico' },
	{ value: 'NY', option: 'New York' },
	{ value: 'NC', option: 'North Carolina' },
	{ value: 'ND', option: 'North Dakota' },
	{ value: 'OH', option: 'Ohio' },
	{ value: 'OK', option: 'Oklahoma' },
	{ value: 'OR', option: 'Oregon' },
	{ value: 'PA', option: 'Pennsylvania' },
	{ value: 'RI', option: 'Rhode Island' },
	{ value: 'SC', option: 'South Carolina' },
	{ value: 'SD', option: 'South Dakota' },
	{ value: 'TN', option: 'Tennessee' },
	{ value: 'TX', option: 'Texas' },
	{ value: 'UT', option: 'Utah' },
	{ value: 'VT', option: 'Vermont' },
	{ value: 'VA', option: 'Virginia' },
	{ value: 'WA', option: 'Washington' },
	{ value: 'WV', option: 'West Virginia' },
	{ value: 'WI', option: 'Wisconsin' },
	{ value: 'WY', option: 'Wyoming' },
];

export const vehicleYears = eachYearOfInterval({
	start: new Date(1949, 1, 1),
	end: add(new Date(), { years: 1 }), // Include the next year in list
}).reverse().map((year) => ({ value: year.getFullYear(), option: year.getFullYear() }));

export const glassDamageAnswerOptions = [
	{
		option: 'No',
		value: 'no',
	},
	{
		option: 'Yes',
		value: 'yes',
	}
];

export const damagedAreasAnswerOptions = [
	{
		option: 'No',
		value: 'no',
	},
	{
		option: 'Yes',
		value: 'yes',
	}
];

export const autoInsuranceAnswerOptions = [
	{
		option: 'Yes, I plan to use insurance',
		value: 'yes',
	},
	{
		option: 'No, I plan to pay out of pocket',
		value: 'no',
	},
	{
		option: 'Not sure yet',
		value: 'not-sure-yet',
	}
]
