import {
	BaseContentGridProps,
	BaseContentGrid as DrivenBaseContentGrid,
	DrivenContainer,
	DrivenContentSection,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { gridClasses } from '@mui/material/Grid';

import MarkdownContent from './mdx/markdown-content';

export function BaseContentGrid({
	header,
	headerSize,
	headingLevel = 'h3',
	contentfulchildren,
}: BaseContentGridProps & { contentfulchildren: any }) {

	return (
		<DrivenContainer maxWidth='lg'>
			<DrivenBaseContentGrid
				sx={{
					[`> .${gridClasses.item}`]: {
						flexBasis: 'unset',
						flexGrow: 1,
						flexDirection: {
							md: 'column',
							lg: 'row',
						},
					},
					[`> .${gridClasses.item}:first-of-type`]: {
						maxWidth: {
							md: '100%',
							lg: '30%',
						},
					},
					[`> .${gridClasses.item}:last-of-type`]: {
						maxWidth: {
							md: '100%',
							lg: '70%',
						},
					},
				}}
			>
				<DrivenTypography
					variant={headerSize}
					component={headingLevel}
					sx={{ textTransform: { xs: 'none' } }}
				>
					{header}
				</DrivenTypography>
				{contentfulchildren &&
					contentfulchildren.map(({ id, component }: any) => {
						return (
							<DrivenContentSection
								key={id}
								body={<MarkdownContent children={component?.text} />}
							/>
						);
					})}
			</DrivenBaseContentGrid>
		</DrivenContainer>
	);
}

export default BaseContentGrid;
