import {
	DrivenButton,
	ButtonIconPlacement,
	IconTypes,
} from '@drivenbrands/driven-components';

type SelectionButtonProps = {
	fullWidth?: boolean;
	isSelected: boolean;
	onClick: () => void;
};

export const SelectionButton = ({
	fullWidth,
	isSelected,
	onClick,
}: SelectionButtonProps) => {
	return (
		<DrivenButton
			fullWidth={fullWidth}
			icon={isSelected ? IconTypes.CHECK : undefined}
			iconPlacement={isSelected ? ButtonIconPlacement.LEFT : undefined}
			variant={isSelected ? 'contained' : 'outlined'}
			onClick={onClick}
		>
			{isSelected ? 'Selected' : 'Select'}
		</DrivenButton>
	);
};
