import { DrivenTypography } from '@drivenbrands/driven-components';
import { darkGrey } from '../colors';

type TextProps = {
	text: string;
};

export const BodyText = ({ text, ...props }: TextProps) => {
	return (
		<DrivenTypography variant='body2' color={darkGrey} {...props}>
			{text}
		</DrivenTypography>
	);
};

export const Caption = ({ text }: TextProps) => {
	return (
		<DrivenTypography variant='caption' color={darkGrey}>
			{text}
		</DrivenTypography>
	);
};
