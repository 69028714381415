import React, { FC } from 'react';

type ButtonContextType = {
	handlePrevious?: () => void;
	children?: React.ReactNode;
};

export const ButtonContext = React.createContext<ButtonContextType>({
	handlePrevious: undefined,
});
export const ButtonPropProvider: FC<ButtonContextType> = ({
	handlePrevious,
	children,
}) => {
	return (
		<ButtonContext.Provider value={{ handlePrevious }}>
			{children}
		</ButtonContext.Provider>
	);
};
