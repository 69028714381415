import {
	DrivenActionableList, DrivenContentCard,
	DrivenIcon,
	DrivenLink,
	DrivenStack,
	DrivenTypography,
	IconTypes
} from '@drivenbrands/driven-components';
import { format } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { GatsbyLinkBehavior } from '../../../components/gatsby-link-behavior';

import { captureCustomDataLayerEvent, OpeningType } from '../../../services';
import {
	AppointmentContext,
	BodyText,
	ButtonContext,
	FormStateContext
} from '../utils';

export const ReviewForm = React.memo(function ReviewForm() {
	const { waitForVehicle } = useContext(AppointmentContext);
	const { handlePrevious } = useContext(ButtonContext);
	const { appointment, damageTypes, quote, loading, selectedStore } =
		useContext(FormStateContext);

	if (!quote || !appointment || !selectedStore || !damageTypes) {
		return <></>;
	}

	const windShieldItems = useMemo(
		() =>
			damageTypes?.filter(
				(item) => item.OpeningType === OpeningType.WINDSHIELD
			),
		[damageTypes]
	);
	const rearWindshieldItems = useMemo(
		() =>
			damageTypes?.filter(
				(item) => item.OpeningType === OpeningType.BACK_WINDOW
			),
		[damageTypes]
	);
	const doorWindowItems = useMemo(
		() => damageTypes?.filter((item) => item.OpeningType === OpeningType.DOOR),
		[damageTypes]
	);

	const {
		Carrier,
		Deductible,
		PromoAmount,
		QuoteAmount,
		QuoteNum,
		RecallAmount,
		ShopId,
	} = quote;

	return (

		<DrivenStack spacing={4}>

			<DrivenContentCard
				content={
					<DrivenStack>
						<DrivenActionableList
							items={[
								{
									title: (
										<DrivenTypography variant='subtitle2'>
											Date/Time
										</DrivenTypography>
									),
									description: (
										<DrivenTypography variant='body2'>
											{format(new Date(appointment?.ScheduleDate), 'PPPP')} at{' '}
											{appointment?.Slot.replace('-', ' ')}
										</DrivenTypography>
									),
								},
								// {
								// 	title: (
								// 		<DrivenTypography variant='subtitle2'>
								// 			Handoff
								// 		</DrivenTypography>
								// 	),
								// 	description: (
								// 		<DrivenTypography variant='body2'>
								// 			{waitForVehicle ? 'Wait For Vehicle' : 'Drop Off'}
								// 		</DrivenTypography>
								// 	),
								// },
								{
									title: (
										<DrivenTypography variant='subtitle2'>
											Location
										</DrivenTypography>
									),
									description: (
										<DrivenStack>
											<DrivenTypography variant='body2'>
												{selectedStore?.City} -{' '}
												{selectedStore?.Distance?.toFixed(2)}{' '}
												{selectedStore?.DistanceUnit}
											</DrivenTypography>
											<DrivenTypography variant='body2'>
												{selectedStore?.Address}
											</DrivenTypography>
											<DrivenTypography variant='body2'>
												{selectedStore?.City}, {selectedStore?.State}{' '}
												{selectedStore?.Zip}
											</DrivenTypography>
										</DrivenStack>
									),
								},
								{
									title: (
										<DrivenTypography variant='subtitle2'>
											Service Details
										</DrivenTypography>
									),
									description: (
										<DrivenStack>
											{windShieldItems?.length > 0 &&
												windShieldItems.map((glass, index) => (
													<DrivenTypography
														key={`${glass.OpeningType}-${index}`}
														variant='body2'
													>
														Windshield
														{glass.Position ? `- ${glass.Position} ` : ' '}
														{glass.DamageType}
													</DrivenTypography>
												))}
											{rearWindshieldItems?.length > 0 &&
												rearWindshieldItems.map((glass, index) => (
													<DrivenTypography
														key={`${glass.OpeningType}-${index}`}
														variant='body2'
													>
														Rear Windshield
														{glass.Position ? `- ${glass.Position} ` : ' '}
														{glass.DamageType}
													</DrivenTypography>
												))}
											{doorWindowItems?.length > 0 &&
												doorWindowItems.map((glass, index) => {
													if (typeof glass?.Position === 'string') {
														return (
															<DrivenTypography
																key={`${glass.OpeningType}-${index}`}
																variant='body2'
															>
																Car Window -{' '}
																{glass?.Position.replace('_', ' ')}
															</DrivenTypography>
														);
													}
													return <></>;
												})}
										</DrivenStack>
									),
								},
								{
									title: (
										<DrivenTypography variant='subtitle2'>
											Quote Details:
										</DrivenTypography>
									),
									description: (
										<DrivenStack spacing={4}>

											<DrivenStack direction={"row"}>
												<DrivenTypography variant='body2' pr={5}>
													Pay as little as
												</DrivenTypography>

												<DrivenTypography color='success.main' variant='h4'>
													${QuoteAmount}*
												</DrivenTypography>
											</DrivenStack>

											<DrivenTypography variant='subtitle2' color='grey.600'>
												Quote Number: {QuoteNum}
											</DrivenTypography>

											{/* <DrivenTypography variant='subtitle2' color='grey.600'>
													Quote Number: {QuoteNum}
												</DrivenTypography>
												 */}
											<DrivenLink
												href='/buy-now-pay-later/'
												icon={<DrivenIcon icon={IconTypes.ARROW_FORWARD} />}
												LinkComponent={GatsbyLinkBehavior}
												target='_blank'
												onClick={() => {
													captureCustomDataLayerEvent(
														'review_buy_now_pay_later_click'
													);
												}}
											>
												Learn about our buy now, pay later options.
											</DrivenLink>

										</DrivenStack>
									)
								},
								{
									title: (
										<></>
									),
									description: (
										<DrivenStack spacing={4}>

											<DrivenTypography variant='subtitle2' color='grey.600'>
												This is an estimate and does not include tax or other fees. This estimate is based on the information you provided and is subject to change after our auto glass experts assess the damage.
											</DrivenTypography>

										</DrivenStack>
									)
								}
							]}
						/>
					</DrivenStack>
				}
				header={
					// <DrivenTypography variant='subtitle1'>
					// 	Appointment Details 
					// </DrivenTypography>
					<DrivenStack>
						<DrivenTypography variant='h5' component='h1'>
							We look forward to seeing you soon!
						</DrivenTypography>
						<BodyText text='Your appointment has been successfully scheduled and you will receive an email confirmation shortly. ' />
					</DrivenStack>
				}
			/>
			{/* <QuotePriceCard isReviewPage quote={quote} /> */}
			{/* <PaymentInfoCard isReviewPage /> */}
			{/* <ProgressButtons
					disableSubmit={false}
					handlePrevious={handlePrevious}
					loading={loading}
					submitIcon={false}
					submitText='Submit'
				/> */}
		</DrivenStack>

	);
});
