import React, { FC, useCallback, useState } from 'react';

import {
	MAINSTREET_URL,
	QuoteResponse,
	Store,
	StoreResponse,
	useQuoteService,
} from '../../../../services';

type StoreSearchContextType = {
	children?: React.ReactNode;
	searchForStores?: (zip: string, distance?: number) => void;
	storesLoading?: boolean;
	storeResults?: Store[];
	storeResultsError?: boolean;
};

export const StoreSearchContext = React.createContext<StoreSearchContextType>({
	searchForStores: () => {},
	storesLoading: false,
	storeResults: undefined,
	storeResultsError: false,
});

export const StoreSearchProvider: FC<StoreSearchContextType> = ({
	children,
}) => {
	const [storesLoading, setStoresLoading] = useState<boolean>(false);
	const [storeResults, setStoreResults] = useState<Store[] | undefined>();
	const [storeResultsError, setStoreResultsError] = useState<boolean>(false);

	const searchForStores = useCallback(
		async (zip: string, distance: number = 50) => {
			// Send up the searched zip code to keep for the confirmation page.
			setStoresLoading(true);
			setStoreResultsError(false);
			// Mainstreet API search by zip code to get stores
			await useQuoteService({
				url: `${MAINSTREET_URL}shops`,
				body: {
					Zip: zip,
					Distance: distance,
				},
				onError: (error) => {
					setStoresLoading(false);
					setStoreResultsError(error);
				},
				onSuccess: (response: QuoteResponse) => {
					setStoresLoading(false);
					const shopResponse = response as StoreResponse;
					if (shopResponse && shopResponse.Shops.length > 0) {
						setStoreResults(shopResponse.Shops);
					} else {
						setStoreResults([]);
						setStoreResultsError(true);
					}
				},
			});
		},
		[]
	);

	return (
		<StoreSearchContext.Provider
			value={{
				searchForStores,
				storesLoading,
				storeResults,
				storeResultsError,
			}}
		>
			{children}
		</StoreSearchContext.Provider>
	);
};
