import {
	DrivenBox,
	DrivenStack,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { PageProps } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { LocaleContext } from '../contexts';
import { useEnglishNavigationQuery } from '../hooks';
import Footer from './footer';

import '../styles/global.css';

import '@fontsource/nunito/variable-italic.css';
import '@fontsource/nunito/variable.css';
import '@fontsource/roboto/latin-400-italic.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500-italic.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700-italic.css';
import '@fontsource/roboto/latin-700.css';
import { PageContextProps } from '../templates/default-page.template';
import { Navigation } from './navigation';

export interface LayoutProps extends Omit<PageProps, 'children'> {
	children: React.ReactNode;
	pageContext: PageContextProps;
	location: PageProps['location'];
}

export function Layout({ children, location: { pathname } }: LayoutProps) {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setLoaded(true);
	}, []);

	// node_locale or pageContext was not being injected on pages in the /pages directory.
	// This was causing the page to crash when trying to access the node_locale property.
	// Use context value instead.
	// TODO: Locale and translations need to be refactored to use contentful data or i18n
	const { locale } = useContext(LocaleContext);

	// const agnTheme = getTheme(DrivenBrands.AGN);
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const localNavigationMap: { [key: string]: any } = {
		en: useEnglishNavigationQuery,
		'en-US': useEnglishNavigationQuery,
	};

	const localNavigation = localNavigationMap[locale.locale];

	const {
		footerNavigation,
		footerLegalNavigation,
		footerSocialNavigation,
		primaryNavigation,
		utilityNavigation,
	} = localNavigation();

	const pathnameToArray: string[] = pathname.split('/').filter((p) => p);
	const isOnlineEstimatePage = pathnameToArray[0] === 'online-estimate';

	if (!loaded) {
		return null;
	}

	return (
		<>
			{loaded && (
				<DrivenBox
					sx={{
						backgroundColor: 'inherit',
						'& nav[aria-label="Utility-Navigation"] div': {
							justifyContent: 'end',
						},
					}}
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<DrivenBox>
							<Navigation
								navigationItems={primaryNavigation.items}
								utilityNavigationItems={utilityNavigation}
								logo={primaryNavigation.logo}
							/>
							<DrivenStack
								spacing={isMobile ? 3 : 6}
								sx={{
									overflowX: 'clip',
									mt: isOnlineEstimatePage ? 3 : 0,
								}}
							>
								{children}
							</DrivenStack>
							<Footer
								footerCategories={footerNavigation}
								footerLegal={footerLegalNavigation}
								footerSocial={footerSocialNavigation}
							/>
						</DrivenBox>
					</motion.div>
				</DrivenBox>
			)}
		</>
	);
}

export default Layout;
