import {
	DrivenDropdown,
	DrivenGrid,
	DrivenTextInput,
} from '@drivenbrands/driven-components';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	Caption,
	ErrorIcon,
	FormInfoBanner,
	FormStateContext,
	usStates,
} from '../../';

export const LicenseForm = React.memo(function LicenseForm() {
	const {
		formState: { errors },
		register,
		setValue,
	} = useFormContext();
	const {
		licenseError,
		setLicenseError,
		vehicleInformation,
		vehicleState,
		setVehicleState,
	} = useContext(FormStateContext);

	const handleVehicleStateChange = (event: SelectChangeEvent<unknown>) => {
		setVehicleState && setVehicleState(event.target.value as string);
	};

	useEffect(() => {
		if (vehicleInformation?.LicensePlate) {
			setValue('LicensePlate', vehicleInformation?.LicensePlate);
		}
	}, [vehicleInformation]);

	return (
		<DrivenGrid container direction='column'>
			<DrivenGrid item xs>
				<DrivenTextInput
					label='License Plate'
					hasError={
						Boolean(licenseError?.length) || Boolean(errors['LicensePlate'])
					}
					errorHelperText={
						licenseError || (errors['LicensePlate']?.message as string)
					}
					errorIcon={<ErrorIcon />}
					// required
					{...register('LicensePlate', {
						onChange: (event) => setLicenseError && setLicenseError(''),
					})}
				/>
			</DrivenGrid>
			<DrivenGrid item xs sx={{ marginBottom: 2 }}>
				<DrivenDropdown
					label='State'
					placeholder='Select One'
					options={usStates}
					hasError={Boolean(errors['State'])}
					errorHelperText={errors['State']?.message as string}
					errorIcon={<ErrorIcon />}
					value={vehicleState ?? ''}
					// required
					{...register('State', {
						onChange: (event) => {
							handleVehicleStateChange(event);
							setLicenseError && setLicenseError('');
						},
					})}
				/>
			</DrivenGrid>
			{/* <DrivenGrid
				container
				direction='column'
				flexWrap='nowrap'
				item
				xs
				rowSpacing={2}
			>
				<DrivenGrid item xs>
					<Caption text='*Providing License Plate over VIN may require you to complete additional questions about your vehicle. Please note that the vehicle details pulled from License Plate are provided by CarMax. We are not responsible for any inaccuracies in CarMax data.' />
				</DrivenGrid>
				<DrivenGrid item xs>
					<FormInfoBanner
						header='Warning!'
						supportText='Without the VIN number, we cannot include an estimate for ADAS Calibration as part of your quote if you provide your vehicle details via License Plate. Please note that this may be a service required at the time of your visit depending on the vehicle damage you specify.'
					/>
				</DrivenGrid>
			</DrivenGrid> */}
		</DrivenGrid>
	);
});
