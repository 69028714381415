import {
	BaseModal,
	CloseButton,
	DrivenBox,
	DrivenButton,
	DrivenDropdown, DrivenLineItems, DrivenStack, DrivenTextInput, DrivenTypography, IconTypes
} from '@drivenbrands/driven-components';
import InputAdornment from '@mui/material/InputAdornment';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	BodyText,
	Caption, ErrorIcon,
	FormStateContext, insuranceCarriers
} from '../../utils';
import { InsuranceType } from './insurance-form';

type WithInsuranceProps = {
	insurance?: InsuranceType;
	setInsurance: (insurance?: InsuranceType) => void;
};

export const WithInsuranceForm = React.memo(function NoInsuranceForm({
	insurance,
	setInsurance,
}: WithInsuranceProps) {
	const {
		formState: { errors, isValid },
		register,
		getValues,
		setValue,
	} = useFormContext();
	const { insuranceInfo, setInsuranceInfo } = useContext(FormStateContext);

	const [insuranceCarrier, setInsuranceCarrier] = useState<string>('');
	const [otherSelected, setOtherSelected] = useState<boolean>(false);
	const [overridePreselect, setOverridePreselect] = useState<boolean>(false);
	const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false);

	const insuranceAdded: boolean = useMemo(() => {
		return Boolean((insuranceInfo && insuranceInfo.IsInsurance) || insurance);
	}, [insurance, insuranceInfo]);

	const selectedInsurance = useMemo(() => {
		if (insuranceInfo && !overridePreselect) {
			return insuranceInfo;
		}
		return insurance;
	}, [insuranceInfo, insurance, overridePreselect]);

	const otherPreSelected: boolean = useMemo(() => {
		return (
			getValues('Carrier')?.length > 0 &&
			!insuranceCarriers.find(
				(carrier) => carrier.value === getValues('Carrier')
			)?.value
		);
	}, [getValues('Carrier'), insuranceCarriers]);

	useEffect(() => {
		if (!getValues('Carrier')?.length && insuranceCarrier?.length) {
			setValue(
				'Carrier',
				insuranceCarrier !== 'Other' ? insuranceCarrier : '',
				{
					shouldDirty: true,
					shouldTouch: true,
					shouldValidate: true,
				}
			);
		}
	}, [insuranceCarrier]);

	const setInsuranceData = () => {
		if (isValid) {
			setOverridePreselect(true);
			setShowInsuranceModal(false);
			setInsurance({
				Carrier: getValues('Carrier') ? getValues('Carrier') : insuranceCarrier,
				Deductible: getValues('Deductible'),
				Policy: getValues('Policy'),
			});
		}
	};

	const handleCarrierSelect = (event: SelectChangeEvent<unknown>) => {
		const selectValue = event.target.value as string;
		setInsuranceCarrier(selectValue);
		setOtherSelected(selectValue === 'Other');
	};

	return (
		<>
			<DrivenStack spacing={2}>
				<BodyText text='Specify your insurance details below:' />
				<DrivenStack alignItems='center' direction='row' spacing={1}>
					<DrivenTypography>
						{insuranceAdded ? 'Edit' : 'Add'} Insurance Deductible
					</DrivenTypography>
					<DrivenButton
						icon={IconTypes.PLUS}
						onClick={() => setShowInsuranceModal(true)}
						variant='text'
						sx={{
							alignContent: 'center',
							color: 'common.black',
							p: 1,
							minWidth: 'auto',

							'.MuiButton-startIcon': {
								mr: 0,
								ml: 0,
							},
						}}
					/>
				</DrivenStack>
				<DrivenStack>
					{insuranceAdded && selectedInsurance && (
						<DrivenLineItems
							items={[
								{
									title: (
										<DrivenTypography variant='body2'>
											{selectedInsurance.Carrier}*
										</DrivenTypography>
									),
									description: (
										<DrivenStack
											alignItems='center'
											direction='row'
											spacing={2}
										>
											<DrivenTypography variant='body2'>
												You Pay:{' $'}
												{selectedInsurance.Deductible}
											</DrivenTypography>
											<CloseButton
												clickCallback={() => {
													if (insurance) {
														setInsurance(undefined);
													} else {
														setInsuranceInfo && setInsuranceInfo(undefined);
													}
												}}
											/>
										</DrivenStack>
									),
									highlightColor: 'success',
								},
							]}
						/>
					)}
				</DrivenStack>
				<Caption text='*The deductible amount you provide will be confirmed after you schedule your appointment and is not final until you receive a final payment amount at the time of your visit. Cannot be combined with promotions or discounts.' />
			</DrivenStack>
			<BaseModal
				actions={
					<DrivenBox
						display='flex'
						justifyContent='flex-end'
						width='100%'
						sx={{ pt: 2 }}
					>
						<DrivenButton
							onClick={() => setShowInsuranceModal(false)}
							variant='text'
						>
							Cancel
						</DrivenButton>
						<DrivenButton onClick={() => setInsuranceData()}>Save</DrivenButton>
					</DrivenBox>
				}
				fullWidth
				handleClose={() => setShowInsuranceModal(false)}
				handleSubmit={(e) => e.preventDefault()}
				open={showInsuranceModal}
				maxWidth='md'
				modalTitle={`${insuranceAdded ? 'Edit' : 'Add'} Insurance Deductible`}
				sx={{
					'form > .MuiBox-root': {
						width: '100%',

						'.MuiBox-root:nth-of-type(2)': {
							width: '100%',
						},
					},
				}}
			>
				<DrivenStack spacing={2} sx={{ pt: 1, pr: 3, pl: 5, pb: 0 }}>
					<DrivenDropdown
						label='Carrier'
						placeholder='Select One'
						options={insuranceCarriers}
						hasError={
							insuranceCarrier !== 'Other' ? Boolean(errors['Carrier']) : false
						}
						errorHelperText={
							insuranceCarrier !== 'Other'
								? (errors['Carrier']?.message as string)
								: undefined
						}
						errorIcon={<ErrorIcon />}
						value={
							getValues('Carrier')?.length
								? otherPreSelected
									? 'Other'
									: getValues('Carrier')
								: insuranceCarrier
						}
						onChange={handleCarrierSelect}
					/>
					{(otherSelected || otherPreSelected) && (
						<DrivenTextInput
							label='Other'
							hasError={Boolean(errors['Carrier'])}
							errorHelperText={
								Boolean(errors['Carrier'])
									? 'Please enter your carrier or select one from the list.'
									: ''
							}
							errorIcon={<ErrorIcon />}
							{...register('Carrier')}
						/>
					)}
					<DrivenTextInput
						label='Policy Number'
						hasError={Boolean(errors['Policy'])}
						errorHelperText={errors['Policy']?.message as string}
						errorIcon={<ErrorIcon />}
						{...register('Policy')}
					/>
					<DrivenTextInput
						label='Deductible'
						hasError={Boolean(errors['Deductible'])}
						errorHelperText={errors['Deductible']?.message as string}
						errorIcon={<ErrorIcon />}
						{...register('Deductible')}
						startAdornment={
							<InputAdornment
								position='start'
								sx={{
									color: 'common.black',
									ml: 1,

									'& .MuiTypography-root': {
										color: 'common.black',
									},
								}}
							>
								$
							</InputAdornment>
						}
						type='number'
					/>
				</DrivenStack>
			</BaseModal>
		</>
	);
});
