import {
	DrivenBox,
	DrivenContainer,
	DrivenIcon,
	DrivenLink,
	DrivenTypography,
	IconTypes,
} from '@drivenbrands/driven-components';
import { useTheme } from '@mui/material';
import { graphql } from 'gatsby';
import { ShareButtons } from '../components/contentful/blog/share-links';
import MarkdownContent from '../components/mdx/markdown-content';
// import { captureCustomDataLayerEvent } from '../services';
import ContentfulImage from '../components/contentful-image';
import { BlogPost } from '../components/contentful/blog';
import RelatedPosts from '../components/contentful/blog/related-posts';
import { BlogSeo } from '../components/contentful/blog/SEO';
import { GatsbyLinkBehavior } from '../components/gatsby-link-behavior';

// export const Head = ({ data, pageContext }) => (
// 	<SEO
// 		path={pageContext.pagePath}
// 		title={data?.page?.title}
// 		description={data?.page?.description}
// 		canonical={data?.page?.canonicalUrl}
// 		noindex={data?.page?.noindex}
// 		nofollow={data?.page?.nofollow}
// 	/>
// );
// id can change based on locale
// contentful_id is the same across locales.
// so id passed in will be for the current locale. Or change to contentful_id and add node_locale filter like this
// blogPost: contentfulBlogPost(contentful_id: {eq: $cid}, node_locale: {eq: $node_locale} )

export const Head = ({ data }) => {

	const mainLogo = data?.mainLogo?.logo?.gatsbyImageData?.images?.fallback?.src || '';

	return <>
		<BlogSeo post={{ ...data.blogPost, mainLogo }} />
	</>
};
const BlogPage = ({ data, pageContext, ...props }) => {
	const theme = useTheme();
	if (!data) return null;
	const { publishDate, bodyMarkdown, title, slug, featuredImage } =
		data.blogPost as BlogPost;
	const shareUrl = props.location.href;
	const { nextPostSlug, previousPostSlug } = pageContext;
	return (
		<DrivenContainer
			sx={{
				marginLeft: 'auto !important',
				marginRight: 'auto !important',
				mt: { xs: 5, md: 5 },
			}}
		>
			<DrivenBox display='flex' alignItems='center' sx={{ my: 2 }}>
				<DrivenLink href='/' LinkComponent={GatsbyLinkBehavior}>
					Home
				</DrivenLink>
				<DrivenTypography sx={{ px: 1 }}> / </DrivenTypography>
				<DrivenLink href='/blog' LinkComponent={GatsbyLinkBehavior}>
					Blog
				</DrivenLink>
				<DrivenTypography sx={{ px: 1 }}> / </DrivenTypography>
				<DrivenLink href={`/blog/${slug}`} LinkComponent={GatsbyLinkBehavior}>
					{title}
				</DrivenLink>
			</DrivenBox>
			<DrivenBox alignContent='center'>
				<DrivenTypography variant='subtitle1' mb={1}>
					<time dateTime={publishDate}>{publishDate}</time>
				</DrivenTypography>

				<DrivenTypography variant='h1' my={2}>
					{title}
				</DrivenTypography>
				<ShareButtons url={shareUrl} title={title} />
				{/* Main image */}
				{featuredImage && featuredImage.gatsbyImageData && (
					<DrivenBox mb={5} sx={{ maxHeight: '100%', overflow: 'hidden' }}>
						<ContentfulImage
							image={{
								gatsbyImageData: featuredImage.gatsbyImageData,
								description: featuredImage.description || title,
								title,
							}}
							loading='eager'
							imgStyle={{ width: '100%', height: '100%', objectFit: 'contain' }}
						/>
					</DrivenBox>
				)}
				{/* Body content. Text or markdown */}
				<MarkdownContent>
					{bodyMarkdown?.childMarkdownRemark.rawMarkdownBody || ''}
				</MarkdownContent>
			</DrivenBox>
			<DrivenBox
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					my: 4,
					pt: 4,
				}}
			>
				<DrivenBox sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
					<DrivenLink
						placeholder=''
						variant='body1'
						// LinkComponent={GatsbyLink}
						href={previousPostSlug ? `/${previousPostSlug}` : '#'}
						sx={{
							color: theme.palette.primary.main,
							display: 'flex',
							alignItems: 'center',
							visibility: previousPostSlug ? '' : 'hidden',
						}}
					>
						<DrivenIcon
							icon={IconTypes.ARROW_BACK}
							sx={{ marginRight: '10px' }}
						/>
						Back
					</DrivenLink>
				</DrivenBox>
				<DrivenLink
					sx={{ color: theme.palette.primary.main }}
					placeholder=''
					href='/blog'
				>
					Blog Home
				</DrivenLink>
				<DrivenBox sx={{ display: 'flex', flexDirection: 'row' }}>
					<DrivenLink
						placeholder=''
						variant='body1'
						href={nextPostSlug ? `/${nextPostSlug}` : '#'}
						sx={{
							color: theme.palette.primary.main,
							display: 'flex',
							alignItems: 'center',
							visibility: nextPostSlug ? '' : 'hidden',
						}}
					>
						Next
						<DrivenIcon
							sx={{ marginLeft: '10px', color: theme.palette.primary.main }}
							icon={IconTypes.ARROW_FORWARD}
						/>
					</DrivenLink>
				</DrivenBox>
			</DrivenBox>
			{/* // TODO: build Related posts JB:7/25/23 */}
			{/* // LM: TODO added 8/1/23 Place holder related posts showing random posts, correct sort logic after posts exist. // */}
			<RelatedPosts posts={data.relatedPosts.nodes} />
		</DrivenContainer>
	);
};

// TODO: add pagination when we have
// more than 1 page of content.  LM/8/1/23
// TODO: metadata JB:7/23

// id can change based on locale
// contentful_id is the same across locales.
// so id passed in will be for the current locale. Or change to contentful_id and add node_locale filter like this
// blogPost: contentfulBlogPost(contentful_id: {eq: $cid}, node_locale: {eq: $node_locale} )
export const query = graphql`
	query BlogTemplateQuery($id: String) {
		blogPost: contentfulBlogPost(id: { eq: $id }) {
			id
			contentful_id
			node_locale
			title
			slug
			path
			createdAt(formatString: "YYYY-MM-DD")
			updatedAt(formatString: "YYYY-MM-DD")
			 seo{
				name
				title
				description
				noindex
				nofollow
				canonicalUrl
				scriptSrc
				googleTagManager
			}
			publishDate(formatString: "MMMM DD, YYYY")
			excerpt {
				childMarkdownRemark {
					rawMarkdownBody
					html
				}
			}
			featuredImage {
				gatsbyImageData(
					layout: FULL_WIDTH
					placeholder: BLURRED
					quality: 60
					width: 1200
					formats: [WEBP]
				)
				title
				description
				width
			}
			bodyMarkdown {
				childMarkdownRemark {
					html
					rawMarkdownBody
				}
			}
		}
		relatedPosts: allContentfulBlogPost(filter: { id: { ne: $id } }, limit: 3) {
			nodes {
				id
				title
				slug
				featuredImage {
					gatsbyImageData(
						placeholder: BLURRED
						quality: 60
						height: 300
						width: 300
						formats: [WEBP]
					)
					title
					description
				}
			}
		}
		mainLogo: contentfulNavigation {
			logo {
				gatsbyImageData(
						layout: CONSTRAINED
						formats: [AUTO, WEBP, AVIF]
						width: 155
					)
			}
		}
	}
`;

export default BlogPage;
