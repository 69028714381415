import {
	DrivenBox,
	DrivenButton,
	DrivenContentSection,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';

import { graphql } from 'gatsby';
import ContentfulImage from '../contentful-image';
import { GatsbyLinkBehavior as GatsbyLink } from '../gatsby-link-behavior';
import MarkdownContent from '../mdx/markdown-content';

export const Card = ({ image, body, title, actions }: any) => {
	const theme = useTheme();
	const { page, url, variant, text } = actions ?? {};
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<DrivenBox
			flex={1}
			sx={{
				'.gatsby-image-wrapper': {
					mb: { xs: 2, mb: 0 },
				},

				mb: { xs: 6 },
				// Kind of hacky, but these are needed
				// in a pinch to force text transform to not capitalize
				'& .MuiTypography-h5': {
					textTransform: 'none',
				},
			}}
		>
			<DrivenContentSection
				body={
					<>
						<MarkdownContent>
							{body?.childMarkdownRemark.rawMarkdownBody}
						</MarkdownContent>
						<DrivenButton
							fullWidth={isMobile}
							LinkComponent={GatsbyLink}
							href={page?.slug ?? url}
							sx={{ mt: 3 }}
							style={{ borderRadius: 30 }}
							variant={variant}
						>
							{text}
						</DrivenButton>
					</>
				}
				header={title}
				headerSize='h5'
				headerInline
				image={image && <ContentfulImage image={image} />}
				isStacked
			/>
		</DrivenBox>
	);
};

export default Card;

export const CardQuery = graphql`
	fragment CardFields on ContentfulCard {
		__typename
		id
		contentful_id
		name
		icon
		title
		variant
		actions {
			...ButtonFields
		}
		body {
			childMarkdownRemark {
				rawMarkdownBody
				html
			}
		}
		image {
			title
			publicUrl
			url
			description
			gatsbyImageData(
				layout: CONSTRAINED
				width: 900
				quality: 85
				aspectRatio: 1.778
				placeholder: DOMINANT_COLOR
			)
		}
	}
`;
