import {
	DrivenBox, DrivenLink,
} from '@drivenbrands/driven-components';
import { CrossBrandImage } from './cross-brand-image';

const CrossBrandTake5CarWash = (props: any) => {
	const { images } = props;

	return (
		<DrivenBox sx={{ mt: 4 }}>
			<DrivenLink
				href='https://www.take5.com/car-wash/unlimited/'
				target='_blank'
			>
				<CrossBrandImage image={images[0]} />
			</DrivenLink>
		</DrivenBox>
	);
}

export default CrossBrandTake5CarWash;
