import {
	DrivenBox,
	DrivenBulletList,
	DrivenTypography,
	IconTypes,
} from '@drivenbrands/driven-components';
import { listItemIconClasses, styled } from '@mui/material';
import { graphql } from 'gatsby';

interface ListItemProps {
	icon: IconTypes;
	description: string;
}

interface ListProps {
	heading?: string;
	listItems: Array<ListItemProps>;
}

const StyledHeaderTypography = styled(DrivenTypography)(({ theme }) => ({
	fontWeight: 700,
	color: theme.palette.grey[800],
	marginTop: theme.spacing(4),
	marginBottom: theme.spacing(-2),
}));

// TODO: Queries like this?  JB:12/16/23
// export default function List({ heading, listItems }: Queries.ContentfulList) {
export default function List({ heading, listItems }: ListProps) {
	if (!listItems || !listItems.length) {
		return null;
	}

	return (
		<DrivenBox
			sx={{
				maxWidth: '500px', // Added max-width per design
				[`.${listItemIconClasses.root}`]: {
					alignSelf: 'flex-start',
				},
			}}
		>
			{heading && <StyledHeaderTypography>{heading}</StyledHeaderTypography>}
			<DrivenBulletList
				iconColor='primary'
				items={listItems.map((items) => {
					const { icon, description } = items || {};
					return {
						icon,
						text: (
							<DrivenTypography variant='body2'>{description}</DrivenTypography>
						),
					};
				})}
			/>
		</DrivenBox>
	);
}

export const query = graphql`
	fragment ListFields on ContentfulList {
		__typename
		id
		name
		heading
		listItems {
			icon
			isDisabled
			description
		}
	}
`;

// On listItem model but not used?
// image > media.
// button > buttonReference
