import {
	CardContentAlignment,
	DrivenIcon,
	DrivenIconCard,
	DrivenLink,
	IconSizes,
} from '@drivenbrands/driven-components';
import { DrivenBrands, getTheme } from '@drivenbrands/driven-tokens';
import { typographyClasses } from '@mui/material';

import { graphql } from 'gatsby';
import { GatsbyLinkBehavior as GatsbyLink } from '../gatsby-link-behavior';
import MarkdownContent from '../mdx/markdown-content';

export const IconCard = ({
	icon,
	title,
	body,
	isDarkMode = false,
	actions,
}: any) => {
	const sectionBackgroundColor = {
		backgroundColor: `${isDarkMode ? 'black' : 'white'} !important`,
	};
	// TODO: actions can be Button, InputField or Link. JB:12/17/23
	return (
		<DrivenIconCard
			actions={
				actions && (
					<DrivenLink
						LinkComponent={GatsbyLink}
						href={actions.page.slug}
						icon={<DrivenIcon icon={actions.icon} />}
					>
						{actions.text}
					</DrivenLink>
				)
			}
			cardContentProps={{
				variant: 'body1',
				component: 'div',
			}}
			cardHeaderProps={{
				sx: {
					textAlign: 'center',

					'& .MuiTypography-root': {
						textTransform: 'none',
					},
				},
			}}
			darkMode={isDarkMode}
			dense={false}
			icon={icon}
			contentAlignment={CardContentAlignment.CENTER}
			iconSize={IconSizes.XL}
			maxWidth='100%'
			title={title}
			sx={{
				boxShadow: `0px 1px 8px rgba(0, 0, 0, 0.12)`,
				...sectionBackgroundColor,

				'.MuiBox-root': {
					...sectionBackgroundColor,
				},

				[`.${typographyClasses.subtitle1} i`]: {
					color: getTheme(DrivenBrands.AGN).palette[
						isDarkMode ? 'secondary' : 'primary'
					].main,
				},
			}}
		>
			<MarkdownContent>
				{body?.childMarkdownRemark.rawMarkdownBody}
			</MarkdownContent>
		</DrivenIconCard>
	);
};

export const IconCardQuery = graphql`
	fragment IconCardFields on ContentfulIconCard {
		__typename
		icon
		title
		isDarkMode
		borderBottom
		body {
			childMarkdownRemark {
				rawMarkdownBody
			}
		}
		subtitle
		variant
		# TODO: actions can be Button, InputField or Link. JB:12/17/23
		actions {
			text
			icon
			page {
				title
				slug
			}
		}
	}
`;
