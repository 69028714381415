import {
	DrivenBox,
	DrivenFooter,
	DrivenInputWithButton,
	DrivenToast,
	DrivenTypography,
	FooterThemes,
	NavigationItem,
	SelectedLocation,
} from '@drivenbrands/driven-components';
import React, { useContext, useState } from 'react';

import { LocaleContext } from '../contexts';
import {
	captureCustomDataLayerEvent,
	NewsletterResponse,
	useNewsletterService,
} from '../services';
import { AssortedWordsToSpanish } from '../utils';
import { searchOnEnter } from './forms';
import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';

interface FooterProps {
	children?: React.ReactNode;
	footerCategories: NavigationItem[];
	footerLegal: NavigationItem;
	footerSocial: NavigationItem;
}

export default function Footer({
	children,
	footerCategories,
	footerLegal,
	footerSocial,
}: FooterProps) {
	const [showToast, setShowToast] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);
	const { isLocaleSpanish } = useContext(LocaleContext);
	const newsletterLabel = isLocaleSpanish
		? AssortedWordsToSpanish['Sign Up For Our Mailing List!']
		: 'Sign Up For Our Mailing List!';
	const socialMenu = {
		text: footerSocial.text,
		href: footerSocial.href,
		submenu: [
			{
				href: '',
				text: '',
				submenu: footerSocial.submenu,
			},
		],
	};

	// TODO: Re enable newsletter after transition and api is working. Currently issue with Zeta breaks sign ups.  JB:12/17/23
	const submitNewsletterSignup = async (email: string) => {
		await useNewsletterService({
			body: { email },
			onError: () => setShowErrorToast(true),
			onSuccess: ({ success }: NewsletterResponse) => {
				if (success) {
					setShowToast(true);
					captureCustomDataLayerEvent('newsletter_signup', {
						newsletter_signup_location: 'footer',
					});
				} else {
					setShowErrorToast(true);
				}
			},
		});
	};

	const newsletter = {
		label: newsletterLabel,
		component: (
			<DrivenInputWithButton
				ariaLabel={newsletterLabel}
				buttonColor='primary'
				buttonText={
					isLocaleSpanish ? AssortedWordsToSpanish['Sign Up'] : 'Sign up'
				}
				handleSubmit={(email: SelectedLocation) =>
					email && submitNewsletterSignup(email as string)
				}
				onKeyDown={(evt) =>
					searchOnEnter(evt, () => searchOnEnter(evt, submitNewsletterSignup))
				}
				placeholder={
					isLocaleSpanish
						? AssortedWordsToSpanish['Enter Email Address']
						: 'Enter email address'
				}
				spacing={0}
			/>
		) as React.ReactNode,
	};
	return (
		<>
			{children}
			<DrivenBox mt={0}>
				<DrivenFooter
					categories={footerCategories}
					footerTheme={FooterThemes.dark}
					legal={footerLegal}
					LinkComponent={GatsbyLink}
					// newsletter={newsletter}
					social={socialMenu}
				/>
				{showToast && (
					<DrivenToast
						toasterProps={{
							anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
						}}
						open={showToast}
						handleClose={() => setShowToast(false)}
						title='Success!'
					>
						<DrivenTypography variant='body1'>
							Thank you for signing up to our monthly newsletter, stay tuned!
						</DrivenTypography>
					</DrivenToast>
				)}
				{showErrorToast && (
					<DrivenToast
						toasterProps={{
							anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
						}}
						open={showErrorToast}
						handleClose={() => setShowErrorToast(false)}
						severity='error'
						title='Something went wrong'
					>
						<DrivenTypography variant='body1'>
							Your email could not be submitted at this time. Please try again
							later.
						</DrivenTypography>
					</DrivenToast>
				)}
			</DrivenBox>
		</>
	);
}
