import { DrivenContainer, DrivenGrid } from '@drivenbrands/driven-components';
import { BoxProps } from '@mui/material';

import { graphql } from 'gatsby';
import { ComponentMatch } from '../hooks';

interface GridProps {
	items?: Array<any>;
	spacing?: number;
	columns: number;
	rows?: number;
	children?: React.ReactNode;
}
// TODO: This might not be used currently. Maybe match the grid on DB.com project if needed JB:7/23
export default function Grid({
	items,
	columns,
	rows = 1,
	spacing,
}: GridProps & BoxProps) {
	return (
		<DrivenContainer>
			<DrivenGrid
				container
				columns={12}
				maxrows={rows}
				display='flex'
				columnSpacing={{ xs: 0, md: spacing ?? 0 }}
				rowSpacing={{ xs: spacing ?? 0, md: 0 }}
				sx={{
					flexDirection: { xs: 'column', md: 'row' },
				}}
			>
				{items?.map((item, index) => (
					<DrivenGrid
						key={index}
						item
						display='flex'
						alignItems='flex-start'
						justifyContent='center'
						xs={12}
						md={(12 / columns ?? items?.length) * rows}
					>
						{ComponentMatch([item])}
					</DrivenGrid>
				))}
			</DrivenGrid>
		</DrivenContainer>
	);
}

export const query = graphql`
	fragment GridFields on ContentfulGrid {
		__typename
		items {
			...CardFields

			...ImageCardFields

			...GridMediaFields
		}
		columns
		rows
		spacing
	}
`;
