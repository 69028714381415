import { ReactNode, useContext } from 'react';

import uuid4 from 'uuid4';
import { LocaleContext } from '../contexts';
import { useSiteMetadata } from '../hooks';

type SEOProps = {
	path: string;
	title: string;
	description: string;
	canonical: string;
	noindex?: boolean;
	nofollow?: boolean;
	ogImageUrl?: string;
	children?: ReactNode; // TODO: does SEO need children?
};

export const SEO = ({
	path,
	title,
	description,
	canonical,
	noindex,
	nofollow,
  ogImageUrl,
	children,
}: SEOProps) => {
	const {
		title: defaultTitle,
		description: defaultDescription,
		siteUrl,
	} = useSiteMetadata();
	const { generateNewUrlWithLocale, localeOptions } = useContext(LocaleContext);
	const maxTitleLength = 60;
	const separator = ' | ';

	const generateRobotsMeta = () => {
		if (noindex && nofollow) {
			return 'noindex,nofollow';
		}

		if (noindex) {
			return 'noindex';
		}

		if (nofollow) {
			return 'nofollow';
		}

		return '';
	};

	const generateTitle = () => {
		// When possible, will append a separator and the defaultTitle onto the title.
		if (title) {
			// Don't duplicate the defaultTitle if it's the same as title.
			if (title === defaultTitle) {
				return title;
			}

			// Only combine titles if they don't exceed max character limit (60).
			if (
				title.length + separator.length + defaultTitle.length <=
				maxTitleLength
			) {
				return `${title}${separator}${defaultTitle}`;
			}

			// Prioritize the page's title over the default title if we have to pick one.
			return title;
		}

		return defaultTitle;
	};

	const generateCanonical = () => {
		if (canonical) {
			return canonical.toLowerCase();
		}

		if (path) {
			let adjustedPath = path;
			if (!adjustedPath.endsWith('/')) {
				adjustedPath += '/';
			}
			const canonicalUrl = new URL(adjustedPath, siteUrl);
			return canonicalUrl.href.toLowerCase();
		}

		return '';
	};

	const seo = {
		title: generateTitle(),
		description:
			description && description !== defaultDescription
				? description
				: defaultDescription,
		// Keeping in case it is needed
		// image: `${siteUrl}${image}`,
		// url: `${siteUrl}${pathname || ``}`,
		// twitterUsername,
		canonical: generateCanonical(),
		robots: generateRobotsMeta(),
	};

	return (
		<>
			<title>{seo.title}</title>
			<meta name='description' content={seo.description} />

			{seo.robots && <meta name='robots' content={seo.robots} />}

			{seo.canonical && <link key={seo.canonical} rel='canonical' href={seo.canonical} />}

			{/* // TODO: Handle pages opting out of being indexed */}
			{localeOptions.map((option) => {
				// Currently do not support quote in Spanish
				if (path === '/request-quote' && option.seoLocale === 'es-US') {
					return null;
				}
				const hostName = new URL(path, siteUrl);

				const localeUrl = generateNewUrlWithLocale(path, option);
				return (
					<link
						key={uuid4()}
						rel='alternate'
						href={`${hostName.origin}${localeUrl}`}
					/>
				);
			})}

			<meta
				key="facebook-domain-verification"
				name='facebook-domain-verification'
				content='anewlbr3qqi435t9vuqa39mdrnimxs'
			/>

			<meta property='og:title' content={seo.title} />
			<meta property='og:description' content={seo.description} />
			<meta property='og:url' content={seo.canonical} />
			<meta property='og:type' content='website' />
			<meta property='og:site_name' content='Auto Glass Now®' />
			{ogImageUrl && <meta property='og:image' content={ogImageUrl} />}
			<meta name="twitter:card" content='summary_large_image' />
			<meta name="twitter:title" content={seo.title} />
			<meta name="twitter:description" content={seo.description} />

			{/* Keeping in case it is needed for future cases */}
			{/* <meta name="image" content={seo.image} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} /> */}
			{/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
			{children}
		</>
	);
};
