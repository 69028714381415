import {
	CardContentAlignment,
	DrivenTextCard,
} from '@drivenbrands/driven-components';
import { ReactNode } from 'react';

type CardContainerProps = {
	align?: CardContentAlignment;
	children: ReactNode;
	dense?: boolean;
};

export const CardContainer = ({
	align = CardContentAlignment.LEFT,
	children,
	dense,
}: CardContainerProps) => {
	return (
		<DrivenTextCard
			contentAlignment={align}
			cardContentProps={{ component: 'div' }}
			maxWidth='100%'
			minWidth='100%'
			sx={{
				px: dense ? 1 : { xs: 2, sm: 5 },
				py: dense ? 1 : { xs: 3, sm: 6 },

				'& .MuiCardContent-root': {
					pt: 0,
					width: '100%',
				},
			}}
		>
			{children}
		</DrivenTextCard>
	);
};
