import {
	CustomTypography,
	DrivenBox,
	DrivenContainer,
	DrivenIcon,
	DrivenInfoBanner,
	DrivenLink,
	DrivenStack,
	DrivenTypography,
	IconSizes,
	IconTypes,
	IconTypes_FontAwesome,
	LinkIconPlacement,
} from '@drivenbrands/driven-components';

import { graphql } from 'gatsby';
import { GatsbyLinkBehavior } from './gatsby-link-behavior';
import MarkdownContent from './mdx/markdown-content';

type BannerProps = {
	displayIcon?: boolean;
	header: string;
	headerVariant?: CustomTypography;
	link?: string;
	linkText?: string;
	openNewTab?: boolean;
	supportText: string;
	body: {
		childMarkdownRemark: {
			html: string;
			rawMarkdownBody?: string;
		};
	};
};

export const ContentfulInfoBanner = ({
	body,
	displayIcon = true,
	header,
	headerVariant,
	link, // unused
	linkText, // unused
	openNewTab = false, // unused
	supportText, // unused
}: BannerProps) => {
	return (
		<DrivenContainer sx={{ alignSelf: 'center' }}>
			<DrivenInfoBanner
				bgColor='secondary'
				header={
					<DrivenBox alignItems='center' display='flex'>
						{displayIcon && (
							<DrivenIcon
								icon={IconTypes_FontAwesome.CIRCLE_EXCLAMATION}
								size={IconSizes.MD}
								sx={{ marginRight: 1 }}
							/>
						)}
						<DrivenTypography
							variant={headerVariant ?? 'h5'}
							sx={{ textTransform: { xs: 'none' } }}
						>
							{header}
						</DrivenTypography>
					</DrivenBox>
				}
			>
				<DrivenStack spacing={2}>
					<DrivenTypography variant='body1'>{supportText}</DrivenTypography>
					{link && linkText && (
						<DrivenBox>
							<DrivenLink
								href={link}
								icon={<DrivenIcon icon={IconTypes.ARROW_FORWARD} />}
								iconPlacement={LinkIconPlacement.RIGHT}
								LinkComponent={GatsbyLinkBehavior}
								sx={{ fontWeight: 700 }}
								target={openNewTab ? '_blank' : undefined}
							>
								{linkText}
							</DrivenLink>
						</DrivenBox>
					)}
					<MarkdownContent>
						{body?.childMarkdownRemark.rawMarkdownBody}
					</MarkdownContent>
				</DrivenStack>
			</DrivenInfoBanner>
		</DrivenContainer>
	);
};

export default ContentfulInfoBanner;

export const query = graphql`
	fragment InfoBannerFields on ContentfulInfoBanner {
		__typename
		id
		body {
			childMarkdownRemark {
				html
				rawMarkdownBody
			}
		}
		displayIcon
		header
		headerVariant
	}
`;
