import { graphql } from 'gatsby';
import CTABanner from '../components/cta-banner';
import { SEO } from '../components/seo';
import { ComponentMatch } from '../hooks';
import { Breadcrumbs } from '../components/breadcrumbs/breadcrumbs';

type DefaultPageTemplateProps = {
	data: {
		page: Queries.DefaultPageTemplateQueryQuery['page'];
		ctaBanner: Queries.DefaultPageTemplateQueryQuery['ctaBanner'];
	};
	pageContext: PageContextProps;
};

export type PageHeadProps = {
	data: {
		page: {
			title: string;
			description: string;
			canonicalUrl: string;
			noindex: boolean;
			nofollow: boolean;
		};
	};
	pageContext: PageContextProps;
};

// These come from gatsby-node.ts
export type PageContextProps = {
  contentful_id?: string;
  id: string;
  pagePath: string;
  slug: string;
  node_locale: string;
  searchValue?: string;
  currentPage?: number; // Added: current page number in pagination
  totalBlogPages?: number; // Added: total number of blog pages available
  postsPerPage?: number; // Added: number of posts per page
};


const IndexPage = ({ data, pageContext }: DefaultPageTemplateProps) => {
	const { ctaBanner } = data;
	const { page } = data;

	const heroElement = page?.blocks.filter(block => block.__typename === 'ContentfulHero');
	const otherElements = page?.blocks.filter(block => block.__typename !== 'ContentfulHero');

	return (
		<>
			{ComponentMatch(heroElement, undefined, { pageContext })}
			<Breadcrumbs pageSlug={pageContext.pagePath} />
			{ComponentMatch(otherElements, undefined, { pageContext })}
			{ctaBanner && <CTABanner {...ctaBanner} />}
		</>
	);
};

export const Head = ({ data, pageContext }: PageHeadProps) => {
	return (
		<SEO
			path={pageContext.pagePath}
			title={data.page.title || ''}
			description={data.page.description}
			canonical={data.page.canonicalUrl}
			noindex={data.page.noindex}
			nofollow={data.page.nofollow}
			ogImageUrl={data?.page?.blocks?.find((block) => block.__typename === 'ContentfulHero')?.media?.gatsbyImageData?.images?.fallback?.src}
		/>
	);
};

export const query = graphql`
	query DefaultPageTemplateQuery($id: String, $node_locale: String) {
		ctaBanner: contentfulCtaBanner(
			contentful_id: { eq: "77ygezZ3jthDun8FZxcVJM" }
			node_locale: { eq: $node_locale }
		) {
			...CTABannerFields
		}

		page: contentfulPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
			id
			title
			canonicalUrl
			contentful_id
			description
			name
			noindex
			nofollow
			slug
			blocks {
				...FactBannerFields

				...HeroFields

				...FormFields

				...InfoBannerFields

				...SectionFields
			}
		}
	}
`;

export default IndexPage;
