import {
	CardContentAlignment,
	DrivenBox,
	DrivenContainer,
	DrivenGrid,
	DrivenIcon,
	DrivenInputWithButton,
	DrivenLink,
	DrivenStack,
	DrivenTextCard,
	DrivenToast,
	DrivenTypography,
	IconSizes,
	IconTypes,
	LinkIconPlacement,
	SelectedLocation
} from '@drivenbrands/driven-components';
import React, { useContext, useState } from 'react';

import {
	captureCustomDataLayerEvent,
	NewsletterResponse,
	useNewsletterService
} from '../../../services';

import { GatsbyLinkBehavior as GatsbyLink } from '../../gatsby-link-behavior';
import {
	BodyText,
	CardContainer,
	FormStateContext,
	searchOnEnter
} from '../utils';

export const SubmissionPage = React.memo(function SubmissionPage() {
	const { customerInfo } = useContext(FormStateContext);

	const [showToast, setShowToast] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);

	const handleNewsletterSignup = async (email: string) => {
		await useNewsletterService({
			body: { email },
			onError: () => setShowErrorToast(true),
			onSuccess: ({ success }: NewsletterResponse) => {
				if (success) {
					setShowToast(true);
					captureCustomDataLayerEvent('newsletter_signup', {
						newsletter_signup_location: 'oet_confirmation_page',
					});
				} else {
					setShowErrorToast(true);
				}
			},
		});
	};

	return (
		<DrivenContainer maxWidth='md'>
			{showToast && (
				<DrivenToast
					open={showToast}
					handleClose={() => setShowToast(false)}
					severity='success'
					title='Success!'
				>
					<DrivenTypography variant='body1'>
						Thank you for signing up to our monthly newsletter, stay tuned!
					</DrivenTypography>
				</DrivenToast>
			)}
			{showErrorToast && (
				<DrivenToast
					open={showErrorToast}
					handleClose={() => setShowErrorToast(false)}
					severity='error'
					title='Something went wrong'
				>
					<DrivenTypography variant='body1'>
						Your email could not be submitted at this time. Please try again
						later.
					</DrivenTypography>
				</DrivenToast>
			)}
			<DrivenStack spacing={3}>
				<CardContainer align={CardContentAlignment.CENTER}>
					<DrivenStack spacing={3}>
						<DrivenBox sx={{ color: 'primary.main' }}>
							<DrivenIcon icon={IconTypes.CALENDAR_CHECK} size={IconSizes.XL} />
						</DrivenBox>
						<DrivenTypography variant='h5' component='h1'>
							We look forward to seeing you soon!
						</DrivenTypography>
						<BodyText text='Your appointment has been successfully scheduled and you will receive an email confirmation shortly.  Keep up to date with Auto Glass Now promotions by signing up for our newsletter:' />
						<DrivenInputWithButton
							ariaLabel='Newsletter Sign Up'
							buttonText='Sign Up'
							handleSubmit={(email: SelectedLocation) =>
								handleNewsletterSignup(email as string)
							}
							defaultValue={customerInfo?.Email}
							onKeyDown={(e) => searchOnEnter(e, handleNewsletterSignup)}
							placeholder='Enter Email Address'
							spacing={0}
						/>
					</DrivenStack>
				</CardContainer>
				<DrivenTextCard
					contentAlignment={CardContentAlignment.LEFT}
					cardContentProps={{ component: 'div' }}
					maxWidth='100%'
					minWidth='100%'
					sx={{
						p: 0,

						'& .MuiCardContent-root': {
							p: 0,
							width: '100%',
						},
					}}
				>
					<DrivenGrid container alignItems='center'>
						<DrivenGrid item xs={12} sm={4} sx={{}}>
							<img
								src='/assets/images/reminder.jpg'
								alt='Woman getting help from customer service.'
								width='100%'
							/>
						</DrivenGrid>
						<DrivenGrid item display='flex' xs={12} sm={8} sx={{ p: 4 }}>
							<DrivenStack alignSelf='center' spacing={2}>
								<DrivenTypography variant='body2'>
									Just a reminder, payment will be required after your service.
									Prepare for your visit by checking out our payment options:
								</DrivenTypography>
								<DrivenLink
									color='primary'
									fontWeight={700}
									href='/payment-options/'
									icon={
										<DrivenBox sx={{ color: 'primary.main' }}>
											<DrivenIcon icon={IconTypes.ARROW_FORWARD} />
										</DrivenBox>
									}
									iconPlacement={LinkIconPlacement.RIGHT}
									LinkComponent={GatsbyLink}
									target='_blank'
								>
									Learn more about our payment options
								</DrivenLink>
							</DrivenStack>
						</DrivenGrid>
					</DrivenGrid>
				</DrivenTextCard>
			</DrivenStack>
		</DrivenContainer>
	);
});
