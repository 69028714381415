import {
	DrivenButton,
	DrivenConfirmationModal,
	DrivenRadioButton,
	DrivenRadioGroup,
	DrivenStack,
	DrivenTypography
} from '@drivenbrands/driven-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
	BodyText,
	ButtonContext, FormStateContext, PromoValidationContext
} from '../../utils';

export enum InsuranceSelection {
	NONE = 'Claim without Insurance',
	WITH = 'Claim with Insurance',
}

export type InsuranceType = {
	Carrier: string;
	Deductible: number;
	Policy: string;
};

type InsuranceFormProps = {
	handleInsuranceMethod: (method: InsuranceSelection) => void;
	insuranceMethod?: InsuranceSelection;
};

export const InsuranceForm = React.memo(function InsuranceForm({
	handleInsuranceMethod,
	insuranceMethod,
}: InsuranceFormProps) {
	const {
		formState: { isValid },
	} = useFormContext();
	const { handlePrevious } = useContext(ButtonContext);
	const { insuranceInfo, loading, setSelectedInsurance } = useContext(FormStateContext);
	const { promoCode, setPromoCode } = useContext(PromoValidationContext);

	const [overridePreselect, setOverridePreselect] = useState<boolean>(false);
	const [insurance, setInsurance] = useState<InsuranceType>();
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
	const [storedSelection, setStoredSelection] = useState<InsuranceSelection>();

	const selectedMethod = useMemo(() => {
		if (insuranceInfo && !overridePreselect) {
			return insuranceInfo.IsInsurance
				? InsuranceSelection.WITH
				: InsuranceSelection.NONE;
		}
		return insuranceMethod;
	}, [insuranceInfo, insuranceMethod, overridePreselect]);

	useEffect(() => {
		if (insuranceInfo && insuranceInfo.IsInsurance) {
			const { Carrier, Deductible, Policy } = insuranceInfo;
			setInsurance({
				Carrier: Carrier ?? '',
				Deductible: Deductible ?? 0,
				Policy: Policy ?? '',
			});
		}
	}, [insuranceInfo]);

	const handleInsuranceSwitch = (selection: InsuranceSelection) => {
		setSelectedInsurance(selection)

		if (selection === InsuranceSelection.NONE && insurance) {
			setShowWarningModal(true);
			setStoredSelection(InsuranceSelection.NONE);
		} else if (
			selection === InsuranceSelection.WITH &&
			promoCode &&
			promoCode.length > 0
		) {
			setShowWarningModal(true);
			setStoredSelection(InsuranceSelection.WITH);
		} else {
			handleInsuranceMethod(selection);
			setStoredSelection(undefined);
		}
	};



	// const handleFrontWindshield = (e) => {

	// 	const value = e.target.value
	// 	const previousDamageType = frontGlassDamageType.type as RepairReplace

	// 	if(value ==='Chip'){
	// 		setFrontGlass({type:'chipped'})
	// 	}

	// 	if(value ==='Crack'){
	// 		setFrontGlass({type:'cracked'})
	// 	}
	// 	//if switching from one to other need to clear any damage types out. 
	// 	handleWindshieldSelection(previousDamageType)
	// 	setFrontGlassDamageType(0)
	// }




	return (
		<DrivenStack spacing={4}>

			<DrivenStack direction='column' spacing={2}>
				<BodyText text='Do you want to use your insurance for this claim?' />

				<DrivenRadioGroup
					// defaultValue={handOff}
					label=''
					aria-label='Do you want to use insurance?'
					onChange={(e, checked) => handleInsuranceSwitch(e.currentTarget.value as InsuranceSelection)}
					value={selectedMethod}
				>
					{/* // TODO: add these to set if already selected? Maybe from returning customer. Need to verify. Use the above value options? */}
					{/* isSelected={selectedMethod === InsuranceSelection.WITH} */}
					{/* isSelected={selectedMethod === InsuranceSelection.NONE} */}
					<DrivenRadioButton label='Yes, use insurance' value={InsuranceSelection.WITH} />
					<DrivenRadioButton label='No, pay out of pocket.' value={InsuranceSelection.NONE} />
				</DrivenRadioGroup>

				{/* {selectedMethod === InsuranceSelection.NONE && <NoInsuranceForm />} */}
				{/* {selectedMethod === InsuranceSelection.WITH && (
					<WithInsuranceForm
						insurance={insurance}
						setInsurance={setInsurance}
					/>
				)} */}

				{/* <Caption text='**At this time, you cannot combine both promotions and insurance to your visit.' /> */}
			</DrivenStack>

			<DrivenConfirmationModal
				actions={
					<>
						<DrivenButton
							onClick={() => {
								if (selectedMethod === InsuranceSelection.NONE) {
									setPromoCode && setPromoCode(undefined);
								} else if (selectedMethod === InsuranceSelection.WITH) {
									setInsurance(undefined);
								}
								setOverridePreselect(true);
								setShowWarningModal(false);
								storedSelection && handleInsuranceMethod(storedSelection);
							}}
							variant='text'
						>
							Switch Option
						</DrivenButton>
						<DrivenButton onClick={() => setShowWarningModal(false)}>
							Keep Existing
						</DrivenButton>
					</>
				}
				handleClose={() => setShowWarningModal(false)}
				onSubmit={(e) => e.preventDefault()}
				open={showWarningModal}
				title='Warning'
			>
				<DrivenStack spacing={2}>
					<DrivenTypography variant='body2'>
						Are you sure you want to switch to “{storedSelection}”? At this
						time, you cannot combine both promotions and insurance to your
						visit. If you continue, the{' '}
						{storedSelection === InsuranceSelection.NONE
							? 'insurance deductible'
							: 'promo code'}{' '}
						added will be erased.
					</DrivenTypography>
				</DrivenStack>
			</DrivenConfirmationModal>

			{/* <ProgressButtons
				disableSubmit={
					!selectedMethod ||
					(selectedMethod === InsuranceSelection.WITH && !insurance)
				}
				handlePrevious={handlePrevious}
				loading={loading}
			/> */}
		</DrivenStack>
	);
});
