import {
	DrivenBox, DrivenLink,
} from '@drivenbrands/driven-components';
import { Fragment } from 'react';
import { CrossBrandImage } from './cross-brand-image';

const CrossBrandMaaco = (props: any) => {
	const { images } = props;

	return (
		<DrivenBox sx={{ mt: 4 }}>
			{images.map((image: any, index: number) =>
				<Fragment key={image.title}>
					{index === 0 ? <CrossBrandImage image={image} />
						: <DrivenLink
							href='https://www.maaco.com/locations/'
							target='_blank'
						>
							<CrossBrandImage image={image} />
						</DrivenLink>}
				</Fragment>
			)}
		</DrivenBox>
	);
}

export default CrossBrandMaaco;
