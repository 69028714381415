import {
	DrivenBox, DrivenLink,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { motion } from 'framer-motion';
import { captureCustomDataLayerEvent } from '../services';
import { GatsbyLinkBehavior } from '../components/gatsby-link-behavior';

export const aboveNavBarHeight = '48px';

interface AboveNavBarProps {
	bodyText: string;
	phoneNumber?: string;
}

export const AboveNavBar: React.FC<AboveNavBarProps> = ({
	bodyText,
}) => {
	return (
		<DrivenLink
			href='/online-estimate/'
			LinkComponent={GatsbyLinkBehavior}
			onClick={() => {
				captureCustomDataLayerEvent('banner_mobile_get_quote');
			}}
			sx={{
				display: 'block',
				width: '100%',
			}}
		>
			<motion.div
				initial={{ y: 0 }}
				animate={{ y: 0 }}
				exit={{ y: `-${aboveNavBarHeight}` }} // Slides up on exit
			>
				<DrivenBox
					sx={{
						height: aboveNavBarHeight,
						width: '100%',
						backgroundColor: 'secondary.main',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'row',
						flexWrap: 'wrap',
					}}
				>
					<DrivenBox sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 1
					}}>
						<DrivenTypography variant='body1' style={{ lineHeight: '1.0' }} sx={{
							textTransform: 'uppercase',
							fontWeight: 800
						}}>
							{bodyText}
						</DrivenTypography>
						<DrivenBox component='svg' sx={{
							width: 24,
							height: 24,
						}}>
							<use href='/assets/images/icons/arrow-right.svg#arrow-right'/>
						</DrivenBox>
					</DrivenBox>
				</DrivenBox>
			</motion.div>
		</DrivenLink>
	);
};
