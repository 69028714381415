import { DrivenTypography } from '@drivenbrands/driven-components';
import { Theme } from '@mui/material';

export type StoreHours = {
	open: string;
	close: string;
};

export type ConsolidatedHours = {
	days: { start: string; end: string };
	hours: StoreHours;
};

export const consolidateStoreHours = (
	storeHours: Record<string, StoreHours>
): ConsolidatedHours[] => {
	let index = 0;
	return Object.entries(storeHours).reduce<ConsolidatedHours[]>(
		(acc, [dayOfWeek, availability]) => {
			const isHoursSameAsPriorDay =
				availability.open === acc[index]?.hours.open &&
				availability.close === acc[index]?.hours.close;

			if (!isHoursSameAsPriorDay) {
				index += 1;
				acc[index] = {
					days: { start: dayOfWeek, end: dayOfWeek },
					hours: { open: availability.open, close: availability.close },
				};
			} else {
				acc[index] = {
					days: { ...acc[index].days, end: dayOfWeek },
					hours: { ...acc[index].hours },
				};
			}
			return acc;
		},
		[]
	);
};


export const formatStoreHours = (consolidatedHours: ConsolidatedHours[], theme: Theme) =>
	consolidatedHours.map(({ days, hours }) => {
		const daysString = days.start === days.end ? days.start : `${days.start}-${days.end}`;
		const hoursString = hours.open === hours.close ? hours.open : `${hours.open} - ${hours.close}`;

		return (
			<DrivenTypography component='span' key={`${daysString}-${hoursString}`} sx={{ color: theme.palette.common.black }}>
				<strong>{daysString}:</strong>&nbsp;
				{hoursString}
			</DrivenTypography>
		);
	});
