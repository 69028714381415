import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const CrossBrandImage: any = ({ image }: any) => {
	const { title } = image;
	const gatsbyImage = getImage(image?.gatsbyImageData);
	if (!gatsbyImage) {
		return undefined;
	}
	return (
		<GatsbyImage
			image={gatsbyImage}
			alt={title}
			objectFit='contain'
			style={{ maxHeight: '450px' }}
		/>
	);
};