import React, { FC, useEffect, useCallback } from 'react';

export interface GeocodeService {
	current:
		| {
				geocode: google.maps.Geocoder['geocode'];
		  }
		| undefined;
}

type GeocodingContextType = {
	children?: React.ReactNode;
	getLocationByZip?: (
		request: {
			address: string;
		},
		callback: (results: google.maps.GeocoderResult[] | null) => void
	) => void;
	mapStatus?: string;
	reverseLookup?: (
		request: {
			location: google.maps.LatLngLiteral;
		},
		callback: (results: google.maps.GeocoderResult[] | null) => void
	) => void;
};

export const GeocodingContext = React.createContext<GeocodingContextType>({
	getLocationByZip: () => {},
	mapStatus: undefined,
	reverseLookup: () => {},
});

const geocodingService: GeocodeService = { current: undefined };

export const GeocodingProvider: FC<GeocodingContextType> = ({
	mapStatus,
	children,
}) => {
	const bindGoogleServices = useCallback(() => {
		geocodingService.current = new window.google.maps.Geocoder();
	}, []);

	useEffect(() => {
		// If geocodingService has not been initialized
		if (!geocodingService.current) {
			bindGoogleServices();
		}
	}, [bindGoogleServices]);

	const getLocationByZip = useCallback(
		(
			request: { address: string },
			callback: (results: google.maps.GeocoderResult[] | null) => void
		) => {
			geocodingService?.current?.geocode(request, callback);
		},
		[geocodingService]
	);

	const reverseLookup = useCallback(
		(
			request: { location: google.maps.LatLngLiteral },
			callback: (results: google.maps.GeocoderResult[] | null) => void
		) => {
			geocodingService?.current?.geocode(request, callback);
		},
		[geocodingService]
	);

	return (
		<GeocodingContext.Provider
			value={{ getLocationByZip, mapStatus, reverseLookup }}
		>
			{children}
		</GeocodingContext.Provider>
	);
};
