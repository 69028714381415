import {
	DrivenAccordion,
	DrivenIconCard,
	DrivenStack,
	IconSizes,
	IconTypes,
} from '@drivenbrands/driven-components';
import * as React from 'react';

import { SelectionButton } from '.';

type AccordionItem = {
	summary: string;
	details: React.ReactNode;
};

type IconCardProps = {
	actions?: React.ReactNode;
	accordionExpanded: string[];
	icon: IconTypes;
	isSelected: boolean;
	accordionContent: AccordionItem[];
	title: string;
	onAccordionToggle: (
		panelTriggered: string
	) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
	onClick: () => void;
};

export const fullWidthSx = {
	'&.plain > .MuiAccordionSummary-root': {
		justifyContent: 'space-between',
	},
};

export const IconCardWithAccordion = ({
	actions,
	accordionContent,
	accordionExpanded,
	icon,
	isSelected,
	title,
	onAccordionToggle,
	onClick,
}: IconCardProps) => (
	<DrivenIconCard
		actions={
			<DrivenStack spacing={1}>
				<SelectionButton fullWidth isSelected={isSelected} onClick={onClick} />
				<DrivenAccordion
					handleChange={onAccordionToggle}
					panelsOpen={accordionExpanded}
					plain
					records={accordionContent}
					sx={fullWidthSx}
				/>
				{actions}
			</DrivenStack>
		}
		cardActionProps={{ sx: { color: 'common.black' } }}
		cardHeaderProps={{
			sx: {
				color: 'common.black',
				mb: 1,

				'& i': {
					color: 'primary.main',
				},
			},

			titleTypographyProps: {
				variant: 'h6',
			},
		}}
		icon={icon}
		iconSize={IconSizes.LG}
		maxWidth='100%'
		title={title}
		sx={{
			p: 1,
		}}
	/>
);
