import { graphql } from 'gatsby';
import { DrivenBox } from '@drivenbrands/driven-components';
import CrossBrandMaaco from './cross-brand-maaco';
import CrossBrandTake5CarWash from './cross-brand-take5-car-wash';
import CrossBrandTake5OilChange from './cross-brand-take5-oil-change';
import ExclusiveOffers from './exclusive-offers';

type CustomSection = 'TAKE5_OC_CROSS-BRAND-PROMOTIONAL' |
	'TAKE5_CW_CROSS-BRAND-PROMOTIONAL' |
	'MAACO_CROSS-BRAND-PROMOTIONAL' |
	'EXCLUSIVE_OFFERS';

interface CustomSectionProps {
	images: any;
	typeOfCustomSection: CustomSection;
}

export const ContentfulCustomSection = (props: CustomSectionProps) => {
	const { images, typeOfCustomSection } = props;

	return (
		<DrivenBox px={2}>
			{typeOfCustomSection === 'TAKE5_OC_CROSS-BRAND-PROMOTIONAL' && <CrossBrandTake5OilChange images={images} />}
			{typeOfCustomSection === 'TAKE5_CW_CROSS-BRAND-PROMOTIONAL' && <CrossBrandTake5CarWash images={images} />}
			{typeOfCustomSection === 'MAACO_CROSS-BRAND-PROMOTIONAL' && <CrossBrandMaaco images={images} />}
			{typeOfCustomSection === 'EXCLUSIVE_OFFERS' && <ExclusiveOffers images={images} />}
		</DrivenBox>
	)
}

export const query = graphql`
	fragment CustomSectionFields on ContentfulCustomSection {
		__typename
		id
		typeOfCustomSection
		images {
			title
			publicUrl
			mimeType
			url
			gatsbyImageData(
				quality: 90
			)
		}
	}
`;
