import { Body, isError, QuoteResponse, RequestHeaders } from './';

const API_KEY = process.env['GATSBY_QUOTE_API_KEY'];
export const MAINSTREET_URL = process.env['GATSBY_QUOTE_MAINSTREET_API'];
export const SLALOM_URL = process.env['GATSBY_QUOTE_SLALOM_API'];

export type QuoteServiceProps = {
	url: string;
	body?: Body;
	method?: 'POST' | 'PUT' | 'GET';
	onError?: (error: boolean) => void;
	onSuccess?: (response: QuoteResponse) => void;
	keepAlive?: boolean;
};

export const useQuoteService = async ({
	url,
	body,
	method,
	onError,
	onSuccess,
	keepAlive = false,
}: QuoteServiceProps) => {
	return await fetch(`${url}`, {
		keepalive: keepAlive,
		method: method ?? 'POST',
		headers: {
			APIKEY: API_KEY,
			Accept: 'application/json',
			'Content-Type': 'application/json',
		} as RequestHeaders,
		body: JSON.stringify(body),
	})
		.then(async (response) => {
			// calls to */ms/* return Message and ErrorCd along with others. 
			// calls to */slalom/* return Success and other values for response data
			
			const { Message, ErrorCd, Success, ...responseData } = await response.json();
			const { ok, status } = response;
			const hasError = !ok || isError(status) || !Success;
			
			if (hasError) {
				// console.log('QUOTE SERVICE ERROR > ', Message)
				onError && onError(hasError);
			} else {
				onSuccess && onSuccess({ ...responseData, Success });
			}
		})
		.catch((error) => {
			// TODO: log errors 
			// console.log('QUOTE SUBMIT ERROR ', error)
			onError && onError(true);
		});
};
