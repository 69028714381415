import {
	DrivenBox,
	DrivenIcon,
	DrivenInfoBanner,
	DrivenLink,
	DrivenStack,
	DrivenTypography,
	IconSizes,
	IconTypes,
	IconTypes_FontAwesome,
	LinkIconPlacement,
} from '@drivenbrands/driven-components';

import { GatsbyLinkBehavior } from '../../../gatsby-link-behavior';

type BannerProps = {
	header: string;
	link?: string;
	linkText?: string;
	openNewTab?: boolean;
	supportText: string;
};

export const FormInfoBanner = ({
	header,
	link,
	linkText,
	openNewTab = false,
	supportText,
}: BannerProps) => {
	return (
		<DrivenInfoBanner
			bgColor='secondary'
			header={
				<DrivenBox alignItems='center' display='flex'>
					<DrivenIcon
						icon={IconTypes_FontAwesome.CIRCLE_EXCLAMATION}
						size={IconSizes.MD}
					/>
					<DrivenTypography variant='subtitle1' sx={{ marginLeft: 1 }}>
						{header}
					</DrivenTypography>
				</DrivenBox>
			}
		>
			<DrivenStack spacing={2}>
				<DrivenTypography variant='body1'>{supportText}</DrivenTypography>
				{link && linkText && (
					<DrivenBox>
						<DrivenLink
							href={link}
							icon={<DrivenIcon icon={IconTypes.ARROW_FORWARD} />}
							iconPlacement={LinkIconPlacement.RIGHT}
							LinkComponent={GatsbyLinkBehavior}
							sx={{ fontWeight: 700 }}
							target={openNewTab ? '_blank' : undefined}
						>
							{linkText}
						</DrivenLink>
					</DrivenBox>
				)}
			</DrivenStack>
		</DrivenInfoBanner>
	);
};
