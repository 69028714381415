import {
	ButtonIconPlacement,
	DrivenButton,
	DrivenButtonProps,
	IconTypes,
} from '@drivenbrands/driven-components';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { graphql } from 'gatsby';
import { captureCustomDataLayerEvent } from '../services';

import { GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';

export type ContentfulButtonProps = DrivenButtonProps & {
	text?: string;
	page?: {
		slug: string;
		name: string;
	};
	url?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	icon?: IconTypes;
	iconPlacement?: ButtonIconPlacement;
	variant?: 'text' | 'contained' | 'outlined';
	gtmEventName?: string;
	gtmAdditionalFields?: {
		internal: {
			content: string;
		};
	};
};

const ContentfulButton = ({
	text,
	page,
	variant,
	icon,
	iconPlacement,
	fullWidth,
	disabled,
	url,
	gtmEventName,
	gtmAdditionalFields,
}: ContentfulButtonProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { slug } = page || {};
	// FIXME: gtmAdditionalFields is optional and can be undefined. See note in navigation-links.tsx
	return (
		<DrivenButton
			href={url ?? slug}
			variant={variant}
			color='primary'
			icon={icon}
			iconPlacement={iconPlacement}
			fullWidth={fullWidth || isMobile}
			disabled={disabled}
			LinkComponent={GatsbyLink}
			onClick={() => {
				if (gtmEventName) {
					const additionalFields = gtmAdditionalFields
						? JSON.parse(gtmAdditionalFields.internal.content)
						: {};

					captureCustomDataLayerEvent(gtmEventName, { ...additionalFields });
				}
			}}
			style={{ display: 'inline-flex', marginTop: '2rem', borderRadius: 30 }}
		>
			{text}
		</DrivenButton>
	);
};

export default ContentfulButton;

export const query = graphql`
	fragment ButtonFields on ContentfulButton {
		__typename
		id
		page {
			slug
			name
		}
		url
		contentful_id
		disabled
		fullWidth
		# contentfulchildren Reserved name so rename to text.
		text: contentfulchildren
		icon
		iconPlacement
		variant
		gtmEventName
		gtmAdditionalFields {
			internal {
				content
			}
		}
	}
`;
