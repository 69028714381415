import {
	DrivenCheckbox,
	DrivenGrid,
	DrivenLink,
	DrivenStack,
	DrivenTextInput,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { StepsResponse } from 'src/services';

import { GatsbyLinkBehavior as GatsbyLink } from '../../gatsby-link-behavior';
import {
	BodyText,
	ButtonContext,
	CardContainer,
	darkGrey,
	ErrorIcon,
	formatPhoneNumber,
	FormStateContext,
	ProgressButtons,
} from '../utils';
import { InitiateFlowForm } from './initiate-flow-form';

const StyledDisclosure = styled(DrivenTypography)(
	({ theme }) => `
	& > .icon-align-right {
		display: inline-block;
	}
`
);

type InitiateFlowProps = {
	jumpToStep: (steps: Omit<StepsResponse, 'Success'>) => void;
};

export const CustomerInfoForm = React.memo(function CustomerInfoForm({
	jumpToStep,
}: InitiateFlowProps) {
	const {
		control,
		formState: { errors, isValid },
		register,
	} = useFormContext();

	const { handlePrevious } = useContext(ButtonContext);
	const { loading, selectedStore } = useContext(FormStateContext);

	const [smsChecked, setSmsChecked] = useState(false);
	const handleSMSSelection = (selection: string) => {
		// console.log("OTHER WINDOWS >> ", selection)
		setSmsChecked(!smsChecked);
	};

	const [ableToProceed, setAbleToProceed] = useState(false);

	useEffect(() => {
		if (isValid && selectedStore != undefined) {
			setAbleToProceed(true);
		} else {
			setAbleToProceed(false);
		}
	}, [selectedStore]);

	return (
		<DrivenStack spacing={4}>
			<CardContainer dense>
				<BodyText text='To provide you with the most accurate quote possible, please tell us about you and your vehicle.' />
			</CardContainer>
			<CardContainer>
				<DrivenGrid container direction='column'>
					<DrivenTypography component='h1' variant='h5'>
						Tell us about yourself
					</DrivenTypography>
					<DrivenGrid container item xs columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<DrivenGrid item xs={12} md={6}>
							<DrivenTextInput
								label='First Name'
								hasError={Boolean(errors['FirstName'])}
								errorHelperText={errors['FirstName']?.message as string}
								errorIcon={<ErrorIcon />}
								required
								{...register('FirstName')}
							/>
						</DrivenGrid>
						<DrivenGrid item xs={12} md={6}>
							<DrivenTextInput
								label='Last Name'
								hasError={Boolean(errors['LastName'])}
								errorHelperText={errors['LastName']?.message as string}
								errorIcon={<ErrorIcon />}
								required
								{...register('LastName')}
							/>
						</DrivenGrid>
					</DrivenGrid>
					<DrivenGrid container item xs columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<DrivenGrid item xs={12} md={6}>
							<DrivenTextInput
								label='Email'
								hasError={Boolean(errors['Email'])}
								errorHelperText={errors['Email']?.message as string}
								errorIcon={<ErrorIcon />}
								required
								{...register('Email')}
							/>
						</DrivenGrid>
						<DrivenGrid item xs={12} md={6}>
							<Controller
								control={control}
								name='Phone'
								render={({ field: { onChange, name, value, ref } }: any) => (
									<DrivenTextInput
										label='Phone Number'
										name={name}
										value={value ?? ''}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onChange(formatPhoneNumber(event.target.value))
										}
										hasError={Boolean(errors['Phone'])}
										errorHelperText={errors['Phone']?.message as string}
										errorIcon={<ErrorIcon />}
										ref={ref}
										required
									/>
								)}
							/>
						</DrivenGrid>
					</DrivenGrid>

					<DrivenGrid item xs>
						<DrivenCheckbox
							aria-label='Communication checkbox'
							aria-required={false}
							color='primary'
							checked={smsChecked}
							// onChange={onChange(console.log('checked'))}
							label='Sign me up for SMS text updates.'
							// onChange={handleCheckboxChange}
							onClick={() => handleSMSSelection('TEXT')}
							// {...{onchange: (() => {console.log('checked')}),...register('TextConsent')}}
							{...register('TextConsent')}
						/>
					</DrivenGrid>
					<DrivenGrid item xs>
						<StyledDisclosure variant='caption' color={darkGrey}>
							By hitting continue, I agree to the Auto Glass Now's{' '}
							<DrivenLink
								display='inline-block'
								href='/privacy-policy/'
								LinkComponent={GatsbyLink}
								rel='noreferrer'
								target='_blank'
								underline='always'
								variant='caption'
							>
								Privacy Policy
							</DrivenLink>{' '}
							and{' '}
							<DrivenLink
								display='inline-block'
								href='/terms-of-use/'
								LinkComponent={GatsbyLink}
								rel='noreferrer'
								target='_blank'
								underline='always'
								variant='caption'
							>
								Terms of Use
							</DrivenLink>
							.
						</StyledDisclosure>
					</DrivenGrid>
					<br />
					<br />
					{/* Zip and results */}
					<InitiateFlowForm jumpToStep={jumpToStep} />

					{/* // TODO: Disable submit unless location is selected */}
				</DrivenGrid>
			</CardContainer>

			<ProgressButtons
				disableSubmit={!ableToProceed}
				// handlePrevious={handlePrevious}
				hidePrevious={true}
				loading={loading}
			/>
		</DrivenStack>
	);
});

// Moved out of way to view

{
	/* TODO: Re-Add once SMS communication is functional */
}
{
	/* <DrivenGrid
	container
	direction='column'
	flexWrap='nowrap'
	item
	spacing={2}
	xs
>
	<DrivenGrid item xs>
		<DrivenCheckbox
			aria-label='Communication checkbox'
			aria-required={false}
			color='primary'
			label='I opt into text message updates regarding my service appointment.'
			{...register('TextConsent')}
		/>
	</DrivenGrid>
	<DrivenGrid item xs>
		<StyledDisclosure variant='caption' color={darkGrey}>
			*By opting in, I give my consent to receive text messages about
			my vehicle's service, promotional offers and product updates
			from Auto Glass Now at the Phone number submitted here. Message
			and data rates may apply. By opting in I also accept Auto Glass
			Now's{' '}
			<DrivenLink
				display='inline-block'
				href='/terms/'
				LinkComponent={GatsbyLink}
				rel='noreferrer'
				target='_blank'
				underline='always'
				variant='caption'
			>
				Terms of Use
			</DrivenLink>{' '}
			and{' '}
			<DrivenLink
				display='inline-block'
				href='/privacy-policy/'
				LinkComponent={GatsbyLink}
				rel='noreferrer'
				target='_blank'
				underline='always'
				variant='caption'
			>
				Privacy Policy
			</DrivenLink>
			.
		</StyledDisclosure>
	</DrivenGrid>
</DrivenGrid> */
}
