import {
	DrivenAccordion,
	DrivenBox,
	DrivenGrid,
	DrivenTextInput,
	DrivenTypography
} from '@drivenbrands/driven-components';
import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorIcon, FormStateContext } from '../../utils';

export const VINForm = React.memo(function VINForm() {
	const {
		formState: { errors },
		register,
	} = useFormContext();
	const { vinError, setVinError } = useContext(FormStateContext);

	const vinHelper = [
		{
			summary: 'Where can I find my VIN?',
			details: (
				<DrivenBox display='flex' flexDirection='column'>
					<DrivenBox display='flex' sx={{ marginBottom: 2 }}>
						<img
							src='/assets/images/vin-example.jpg'
							alt='Vin Helper Image'
							width='100%'
						/>
					</DrivenBox>
					<DrivenTypography pt={1} variant='body2'>
						The VIN number of your vehicle can be found on your vehicle's
						dashboard, on the inside of the windshield on the driver's side or
						it can be found on the door jamb inside, right where the driver's
						side door latch is located. You can also find it on documents such
						as: Insurance documents, vehicle ownership and service records. The
						VIN number is comprised of letters and numbers totalling to 16
						characters.
					</DrivenTypography>
				</DrivenBox>
			),
		},
	];

	const [expanded, setExpanded] = useState<string[]>([]);

	const handleChange =
		(panelTriggered: string) =>
		(event: React.SyntheticEvent, isExpanded: boolean) => {
			if (expanded.includes(panelTriggered)) {
				setExpanded(expanded.filter((panel) => panel !== panelTriggered));
			} else {
				setExpanded([...expanded, panelTriggered]);
			}
		};

	return (
		<DrivenGrid container direction='column' spacing={2}>
			<DrivenGrid item xs>
				<DrivenTextInput
					label='VIN'
					hasError={Boolean(vinError?.length) || Boolean(errors['VIN'])}
					errorHelperText={vinError || (errors['VIN']?.message as string)}
					errorIcon={<ErrorIcon />}
					// required
					{...register('VIN', {
						onChange: (event) => setVinError && setVinError(''),
					})}
				/>
			</DrivenGrid>
			<DrivenGrid item xs>
				{/* <Caption text='Providing VIN will give you the most accurate quote and save you time by eliminating additional questions regarding your vehicle.' /> */}
			</DrivenGrid>
			<DrivenGrid item xs>
				<DrivenAccordion
					handleChange={handleChange}
					panelsOpen={expanded}
					records={vinHelper}
					plain
				/>
			</DrivenGrid>
		</DrivenGrid>
	);
});
