import {
	DrivenBox,
	DrivenButton,
	DrivenInputWithButton,
	DrivenStack,
	DrivenTypography,
	HeadingLevel,
	SelectedLocation,
} from '@drivenbrands/driven-components';
import { containerClasses, useMediaQuery, useTheme } from '@mui/material';

import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { useContext } from 'react';
import { LocationContext } from '../contexts/use-location';
import { captureCustomDataLayerEvent } from '../services';
import { AgnCustomHero, Image } from './AgnCustomHero';
import { ContentfulButtonProps } from './button';
import { GatsbyLinkBehavior, GatsbyLinkBehavior as GatsbyLink } from './gatsby-link-behavior';
import MarkdownContent from './mdx/markdown-content';

type inputFieldProps = {
	id: string;
	name: string;
	placeholder: string;
	button: ContentfulButtonProps;
};
interface HeroProps {
	actions?: ContentfulButtonProps | inputFieldProps;
	darkMode?: boolean;
	backgroundColor?: string;
	media?: Image;
	body?: {
		raw: string;
	};
	header?: string;
	headingLevel?: HeadingLevel;
	isMediaAlignedRight: boolean;
	hasLocationSearch: boolean;
	headingInline: boolean;
	showBrandImage: string;
	direction: string;
	headerAlign: string;
	subheader: string;
	linkColor: string;
}

export default function Hero({
	actions,
	darkMode,
	backgroundColor,
	header,
	media,
	headingLevel = 'h1',
	body,
	hasLocationSearch,
	subheader,
	...props
}: HeroProps) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const isSmallMobile = useMediaQuery(theme.breakpoints.down(390));
	// const { secondButton } = props;
	// only render the custom hero styles on the homepage for both spanish and english.
	const { pathname } = useLocation();

	// // FIXME: This needs to be config from contentful somehow.
	const useCustomHero = (
		pathname === '/exclusiveoffers/' ||
		pathname.includes('/landing')
	);
	const isHomeHero  = pathname === '/';
	const isExclusiveOffersHero = pathname === '/exclusiveoffers/';

	const { setStoredSearchLocation, storedSearchLocation, setSearchLocation } =
		useContext(LocationContext);

	// TODO: determine why this rich text field, body, is not being properly parsed by gatsby-source-contentful
	const heroContent =
		body && JSON.parse(body?.raw)?.content[0].content[0].value;

	const inputActions = actions as inputFieldProps;
	const button = actions as ContentfulButtonProps;

	const regex = /®|&reg;/g;
	// replace ® with <sup>®</sup> to display the registered trademark symbol correctly

	// Also set innerHTML to force displaying of elements instead of converting to string.
	const updatedHeader = header?.replace(regex, '<sup>$&</sup>');

	if (isExclusiveOffersHero){
		return (
			<>
				{isTablet ? (
					<GatsbyLinkBehavior href="https://www.take5.com/car-wash/reduced-membership/">
						<img
							src="/assets/images/exclusive-offers-mobile.png"
							alt="Exclusive offers hero banner"
							style={{ width: '100%' }}
						/>
					</GatsbyLinkBehavior>
				) : (
					<GatsbyLinkBehavior href="https://www.take5.com/car-wash/reduced-membership/">
						<img
							src="/assets/images/exclusive-offers-desktop.png"
							alt="Exclusive offers hero banner"
							style={{ width: '100%' }}
						/>
					</GatsbyLinkBehavior>
				)}
			</>
		)
	}

	return (
		<DrivenBox
			sx={{
				marginBottom: { xs: 0, md: 0 },
				[`.${containerClasses.root}`]: {
					px: { xs: 0, md: 3 },
				},
			}}
		>
			<AgnCustomHero
				useCustomHero={useCustomHero}
				media={media}
				mediaProps={{
					anchorHalfWidth: true,
					marginBottom: theme.spacing(-4),
					marginRight: theme.spacing(-4),
					height: `calc(100%)`,
				}}
				darkMode={darkMode}
				backgroundColor={backgroundColor}
			>
				<DrivenStack
					direction='column'
					spacing={isTablet ? 0 : 2}
					sx={{
						width: { xs: '100%', md: '48%' },
						py: 2,
						margin: 'unset',
					}}
				>
					{updatedHeader && (
						<DrivenTypography
							variant='h2'
							component={headingLevel || 'h2'}
							sx={{
								mb: { xs: 2 },
								textTransform: { xs: 'none' },
							}}
							dangerouslySetInnerHTML={{ __html: updatedHeader }}
						/>
					)}
					{subheader && (
						<DrivenTypography variant='subtitle3' component='div' mb={2}>
							{subheader}
						</DrivenTypography>
					)}
					{heroContent && (
						<DrivenTypography
							variant='body2'
							sx={{
								mb: { xs: 0, md: 4 },
							}}
							component='div'
						>
							<MarkdownContent>{heroContent}</MarkdownContent>
						</DrivenTypography>
					)}
					{hasLocationSearch && (
						<DrivenBox sx={{ maxWidth: { sm: '100%', md: '600px' } }}>
							<DrivenInputWithButton
								apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
								ariaLabel={inputActions.button.text}
								buttonColor='primary'
								buttonText={inputActions.button.text || 'Search'}
								placeholder={inputActions.placeholder}
								withAutocomplete
								buttonVariant={inputActions.button.variant}
								spacing={0}
								label={inputActions.name}
								handleSubmit={(searchTerm: SelectedLocation) => {
									setStoredSearchLocation && setStoredSearchLocation('');
									setSearchLocation && setSearchLocation(searchTerm);
									// captureCustomDataLayerEvent('location_search'); // double fire on locations page.
								}}
								value={storedSearchLocation}
								autocompleteTypes={['(regions)']}
							/>
						</DrivenBox>
					)}
					{actions && !hasLocationSearch && (
						<DrivenBox
							spacing={3}
							sx={{
								display: 'flex',
								justifyContent: isHomeHero && isMobile ? 'center' : 'start',
								mt: useCustomHero && isSmallMobile ? '1rem' : `2rem !important`,
							}}
						>
							<DrivenButton
								icon={button.icon}
								aria-label={button.text}
								color='secondary'
								href={button.page?.slug ?? button.url}
								LinkComponent={GatsbyLink}
								variant={button.variant}
								fullWidth
								sx={{
									borderRadius: '24px',
									maxHeight: '48px',
									maxWidth: '254px',
								}}
								onClick={(event) => {
									// Add datalayer event on quote click
									// event.preventDefault();
									const location = button.page?.slug ?? button.url;

									// this must override any values from contentful?
									if (location === '/our-locations/') {
										captureCustomDataLayerEvent('find_a_location_click', {
											request_quote_cta_location: 'store_middle',
										});
									}

									if (location === '/request-quote') {
										captureCustomDataLayerEvent('request_quote_click', {
											request_quote_cta_location: 'store_middle',
										});
									}
									// TODO: navigate needs to use generateNewUrlWithLocale once quote and schedule flow has locale support
									// navigate('/request-quote', {
									//  state: { postalCode: store.store_postcode },
									// });
								}}
							>
								{button.text}
							</DrivenButton>
						</DrivenBox>
					)}
				</DrivenStack>
			</AgnCustomHero>
		</DrivenBox>
	);
}
// FIXME: Rename body on contentful to fix reserved naming.  JB:12/23
export const query = graphql`
	fragment HeroFields on ContentfulHero {
		__typename
		darkMode
		backgroundColor
		body: contentfulchildren {
			raw
		}
		header: headingBold
		subheader: headingLight
		IsMediaAlignedLeft
		media {
			id
			description
			# on detail pages this was the image query.
			# gatsbyImageData(width: 600, breakpoints: 3, quality: 100)
			gatsbyImageData(
				layout: CONSTRAINED
				width: 1900
				quality: 90
				breakpoints: [375, 560, 768, 1024, 1440]
				placeholder: DOMINANT_COLOR
			)
			publicUrl
			placeholderUrl
		}
		# secondButton {
		# 	...ButtonFields
		# }
		actions {
			...ButtonFields

			... on ContentfulInputField {
				__typename
				id
				name
				placeholder
				button {
					...ButtonFields
				}
			}
		}
	}
`;
