import {
	DrivenAccordion,
	DrivenInputWithButton,
	DrivenRadioButton,
	DrivenRadioGroup,
	DrivenStack
} from '@drivenbrands/driven-components';
import React, { useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
	BodyText,
	ButtonContext,
	CardContainer,
	ErrorIcon,
	FormStateContext,
	ProgressButtons,
	questionOptions,
	searchOnEnter
} from '../utils';

type QuestionsProps = {
	handleVinSearch: (vin: string) => void;
};

export const AdditionalQuestionsForm = React.memo(
	({ handleVinSearch }: QuestionsProps) => {
		const {
			control,
			formState: { errors, isValid },
		} = useFormContext();
		const { handlePrevious } = useContext(ButtonContext);
		const {
			additionalQuestions,
			loading,
			vinError,
			setVinError,
			vinSubmitted,
		} = useContext(FormStateContext);
		const [expanded, setExpanded] = useState<string[]>([]);

		const handleAccordionToggle =
			(panelTriggered: string) =>
				(event: React.SyntheticEvent, isExpanded: boolean) => {
					if (expanded.includes(panelTriggered)) {
						setExpanded(expanded.filter((panel) => panel !== panelTriggered));
					} else {
						setExpanded([...expanded, panelTriggered]);
					}
				};

		return (
			<DrivenStack spacing={4}>
				<CardContainer>
					<DrivenStack direction='column' flexWrap='nowrap' spacing={4}>
						<BodyText text='We need some additional information about your vehicle’s features.' />
						{!vinSubmitted && (
							<DrivenAccordion
								handleChange={handleAccordionToggle}
								panelsOpen={expanded}
								records={[
									{
										summary: 'Or save time and provide your VIN instead',
										details: (
											<DrivenInputWithButton
												ariaLabel='VIN'
												buttonText='Verify'
												errorIcon={<ErrorIcon />}
												errorHelperText={vinError}
												handleSubmit={handleVinSearch}
												hasError={Boolean(vinError?.length)}
												label=''
												placeholder='Enter 16-Digit VIN'
												onKeyDown={(e) => {
													setVinError && setVinError('');
													searchOnEnter(e, handleVinSearch);
												}}
												spacing={0}
											/>
										),
									},
								]}
								plain
							/>
						)}
						{additionalQuestions?.map((question, index) => {
							const formattedQuestion = question.replace(/(\s)|(\/)/g, '_');
							const questionText = questionOptions.find(
								(answer) => answer.value.match(formattedQuestion)?.length
							)?.option;
							return (
								questionText && (
									<DrivenStack key={formattedQuestion} spacing={2}>
										<BodyText text={`${index + 1}. ${questionText}`} />
										<Controller
											control={control}
											name={`${formattedQuestion}`}
											render={({
												field: { onChange, name, value, ref },
											}: any) => (
												<DrivenRadioGroup
													defaultValue={value ?? ''}
													label=''
													aria-label={questionText}
													error={Boolean(errors[`${name}`])}
													errorHelperText={errors[`${name}`]?.message as string}
													errorIcon={<ErrorIcon />}
													onChange={onChange}
													ref={ref}
													value={value ?? ''}
												>
													<DrivenStack direction='row' spacing={2}>
														<DrivenRadioButton label='Yes' value='yes' />
														<DrivenRadioButton label='No' value='no' />
													</DrivenStack>
												</DrivenRadioGroup>
											)}
										/>
									</DrivenStack>
								)
							);
						})}
					</DrivenStack>
				</CardContainer>
				<ProgressButtons
					disableSubmit={!isValid}
					handlePrevious={handlePrevious}
					loading={loading}
				/>
			</DrivenStack>
		);
	}
);
