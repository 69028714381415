import {
	CloseButton,
	DrivenAccordion,
	DrivenInputWithButton,
	DrivenLineItems,
	DrivenStack,
	DrivenTypography
} from '@drivenbrands/driven-components';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
	Caption,
	ErrorIcon,
	PromoValidationContext,
	searchOnEnter
} from '../../utils';

export const NoInsuranceForm = React.memo(function NoInsuranceForm() {
	const {
		promoCode,
		promoLoading,
		promoError,
		setPromoCode,
		validatePromoCode,
	} = useContext(PromoValidationContext);
	const [promoExpanded, setPromoExpanded] = useState<string[]>([]);

	const containsPromoCode = useMemo(
		() => Boolean(promoCode && promoCode?.length > 0),
		[promoCode]
	);

	const handleAccordionToggle = useCallback(
		(panelTriggered: string) =>
			(event: React.SyntheticEvent, isExpanded: boolean) => {
				if (promoExpanded.includes(panelTriggered)) {
					setPromoExpanded(
						promoExpanded.filter((panel) => panel !== panelTriggered)
					);
				} else {
					setPromoExpanded([...promoExpanded, panelTriggered]);
				}
			},
		[promoExpanded]
	);

	const validateCode = useCallback(
		(promo: string) => {
			validatePromoCode && validatePromoCode(promo);
		},
		[validatePromoCode]
	);

	return (
		<DrivenStack>
			{!containsPromoCode && (
				<DrivenAccordion
					handleChange={handleAccordionToggle}
					panelsOpen={promoExpanded}
					records={[
						{
							summary: 'Add Promo Code',
							details: (
								<DrivenInputWithButton
									ariaLabel='Enter promo code'
									buttonText='Apply'
									loading={promoLoading}
									errorIcon={<ErrorIcon />}
									hasError={promoError}
									errorHelperText={
										'We could not verify the promo code entered. Please try again.'
									}
									handleSubmit={validateCode}
									label=''
									placeholder='Enter Promo Code'
									onKeyDown={(e) => searchOnEnter(e, validateCode)}
									spacing={0}
								/>
							),
						},
					]}
					plain
				/>
			)}
			{containsPromoCode && (
				<DrivenStack spacing={2}>
					<DrivenLineItems
						items={[
							{
								title: (
									<DrivenTypography variant='body2'>
										{promoCode}*
									</DrivenTypography>
								),
								description: (
									<CloseButton
										clickCallback={() =>
											setPromoCode && setPromoCode(undefined)
										}
									/>
								),
								highlightColor: 'success',
							},
						]}
					/>
					<Caption text='*Discount applied to windshield replacement part only; labor and fees not included. Valid for retail transactions only. Cannot be combined with insurance.' />
				</DrivenStack>
			)}
		</DrivenStack>
	);
});
