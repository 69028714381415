import { Fragment } from 'react';
import { DrivenBox, DrivenContainer, DrivenLink, DrivenTypography } from '@drivenbrands/driven-components';
import { Theme, useTheme } from '@mui/material';
import { GatsbyLinkBehavior } from '../gatsby-link-behavior';
import {
	stateAbbreviationToStateName,
} from '../../utils';

interface StoreLocationBreadcrumbProps {
	path: string;
	store_city: string;
	store_state: string;
	storeStateAbbr: string;
}

const createCitySlug = (city: string): string =>
	city.toLowerCase().replace(/\s+/g, '-');

const unslugify = (slug: string): string =>
	slug.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());

const getBreadcrumbsStyles = (theme: Theme)  => {
	return {
		container: {
			py: { xs: 2, md: 3 },
			color: theme.palette.grey[700],
		},
		link: {
			fontWeight: 700,
			color: theme.palette.grey[500],
			textDecoration: 'underline',
		},
		separator: {
			px: 1,
			color: theme.palette.grey[500],
		},
		active: {
			color: theme.palette.common.black,
			fontWeight: 600,
		},
	}
};

const Divider = () => {
	const theme = useTheme();
	const breadcrumbsStyles = getBreadcrumbsStyles(theme);

	return (
		<DrivenTypography sx={breadcrumbsStyles.separator}> / </DrivenTypography>
	);
}

const LocationDetailBreadcrumbs = ({ path, store_city, store_state, storeStateAbbr }: StoreLocationBreadcrumbProps) => {
	const theme = useTheme();
	const locationCode = path.match(/\d+/)?.[0] ?? '';
	const breadcrumbsStyles = getBreadcrumbsStyles(theme);

	return (
		<DrivenBox
			display='flex'
			alignItems='center'
			flexWrap='wrap'
			sx={breadcrumbsStyles.container}
		>
			<DrivenLink
				href={`/our-locations/${storeStateAbbr}`}
				sx={breadcrumbsStyles.link}
				LinkComponent={GatsbyLinkBehavior}
			>
				{store_state}
			</DrivenLink>
			<Divider/>
			<DrivenLink
				href={`/our-locations/${storeStateAbbr}/${createCitySlug(store_city)}`}
				sx={breadcrumbsStyles.link}
				LinkComponent={GatsbyLinkBehavior}
			>
				{store_city}
			</DrivenLink>
			<Divider/>
			<DrivenTypography sx={breadcrumbsStyles.active}>
				{locationCode}
			</DrivenTypography>
		</DrivenBox>
	);
};

const LocationsBreadcrumbs = ({ pageSlug }: { pageSlug: string }) => {
	const theme = useTheme();
	const slugParts = pageSlug.split('/').filter(Boolean);
	const breadcrumbsStyles = getBreadcrumbsStyles(theme);

	return (
		<DrivenBox display='flex' alignItems='center' flexWrap='wrap' sx={{ py: { xs: 2, md: 3 } }}>
			<DrivenLink
				href='/'
				sx={breadcrumbsStyles.link}
				LinkComponent={GatsbyLinkBehavior}
			>
				Auto Glass Now
			</DrivenLink>
			<Divider/>
			{slugParts.length > 1 ? (
				<DrivenLink
					href='/our-locations'
					sx={breadcrumbsStyles.link}
					LinkComponent={GatsbyLinkBehavior}
				>
					Locations
				</DrivenLink>
			) : (
				<DrivenTypography sx={breadcrumbsStyles.active}>
					Locations
				</DrivenTypography>
			)}
			{slugParts.length >= 2 && (
				<>
					<Divider/>
					{slugParts.length > 2 ? (
						<DrivenLink
							href={`/our-locations/${slugParts[1]}`}
							sx={breadcrumbsStyles.link}
							LinkComponent={GatsbyLinkBehavior}
						>
							{stateAbbreviationToStateName(slugParts[1])}
						</DrivenLink>
					) : (
						<DrivenTypography sx={breadcrumbsStyles.active}>
							{stateAbbreviationToStateName(slugParts[1])}
						</DrivenTypography>
					)}
				</>
			)}
			{slugParts[2] && (
				<>
					<Divider/>
					<DrivenTypography sx={breadcrumbsStyles.active}>
						{unslugify(slugParts[2])}
					</DrivenTypography>
				</>
			)}
		</DrivenBox>
	);
};

const Breadcrumbs = ({ pageSlug }: { pageSlug: string }) => {
	const theme = useTheme();
	const breadcrumbsStyles = getBreadcrumbsStyles(theme);

	if (pageSlug === '/' || pageSlug === '/blog' || pageSlug === '/exclusiveoffers') {
		return null;
	}

	const pathSegments = pageSlug.split('/').filter(Boolean);

	return (
		<DrivenBox>
			<DrivenContainer maxWidth='lg'>
				<DrivenBox display='flex' alignItems='center' flexWrap='wrap'>
					<DrivenLink
						href='/'
						sx={breadcrumbsStyles.link}
						LinkComponent={GatsbyLinkBehavior}
					>
						Auto Glass Now
					</DrivenLink>
					<Divider/>
					{pathSegments.map((segment, index) => {
						const isLast = index === pathSegments.length - 1;
						const href = `/${pathSegments.slice(0, index + 1).join('/')}`;
						const displayText = segment.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

						return (
							<Fragment key={href}>
								{isLast ? (
									<DrivenTypography sx={breadcrumbsStyles.active}>
										{displayText}
									</DrivenTypography>
								) : (
									<DrivenLink
										href={href}
										sx={breadcrumbsStyles.link}
										LinkComponent={GatsbyLinkBehavior}
									>
										{displayText}
									</DrivenLink>
								)}
								{!isLast && <Divider/>}
							</Fragment>
						);
					})}
				</DrivenBox>
			</DrivenContainer>
		</DrivenBox>
	);
};

export { LocationDetailBreadcrumbs, LocationsBreadcrumbs, Breadcrumbs };
